import type { Channel } from 'pusher-js'
import Pusher from 'pusher-js'
import { axiosInstance } from '@api'
import { useAccessToken } from '@/auth/utils'
import { useAuthStore } from '@store/auth'
import { storeToRefs } from 'pinia'

Pusher.logToConsole = import.meta.env.VITE_PUSHER_DEBUG === 'true'

let _pusher: Pusher | null = null
let _channel: Channel | null = null
let _channelByUser: Channel | null = null

let _pusherByHash: Pusher | null = null
let _channelByHash: Channel | null = null

export enum PusherEventType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export function usePusherWorkspaceEvent<T>(event: string): Ref<{ type: PusherEventType; data: T } | undefined> {
  const channel = getOrCreateChannel()

  return usePusherEvent(channel, event)
}

export function usePusherByUserEvent<T>(event: string): Ref<{ type: PusherEventType; data: T } | undefined> {
  const channel = getOrCreateChannelByUser()

  return usePusherEvent(channel, event)
}

export function usePusherByHashEvent<T>(auctionHash: string, carrierHash: string, event: string): Ref<{ type: PusherEventType; data: T } | undefined> {
  const channel = getOrCreateChannelByHash(auctionHash, carrierHash)

  return usePusherEvent(channel, event)
}

export function usePusherEvent<T>(channel: Channel, event: string): Ref<{ type: PusherEventType; data: T } | undefined> {
  const resultRef = ref<{ type: PusherEventType; data: T }>()

  const callback = (data: { type: PusherEventType; data: T }) => {
    resultRef.value = data
  }

  channel.bind(event, callback)

  onUnmounted(() => {
    channel.unbind(event, callback)
  })

  return resultRef
}

export function clearPusher() {
  _channel?.unbind_all()
  _channel?.unsubscribe()
  _channel = null
  _channelByUser?.unbind_all()
  _channelByUser?.unsubscribe()
  _channelByUser = null
  _pusher?.unbind_all()
  _pusher?.disconnect()
  _pusher = null

  _channelByHash?.unbind_all()
  _channelByHash?.unsubscribe()
  _channelByHash = null
  _pusherByHash?.unbind_all()
  _pusherByHash?.disconnect()
  _pusherByHash = null
}

function getOrCreateChannel(): Channel {
  if (_channel)
    return _channel

  const { currentWorkspace } = storeToRefs(useAuthStore())

  _channel = getOrCreatePusher().subscribe(`private-data-ws-${currentWorkspace.value?.id}`)

  return _channel!
}

function getOrCreateChannelByUser(): Channel {
  if (_channelByUser)
    return _channelByUser

  const { currentUser } = storeToRefs(useAuthStore())

  _channelByUser = getOrCreatePusher().subscribe(`private-data-user-${currentUser.value?.id}`)

  return _channelByUser!
}

function getOrCreatePusher(): Pusher {
  if (_pusher)
    return _pusher

  _pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
    cluster: import.meta.env.VITE_PUSHER_CLUSTER,
    channelAuthorization: {
      endpoint: axiosInstance.defaults.baseURL + `/api/v1/push/authorize/`,
      transport: 'ajax',
      headersProvider: () => createPusherHeaders(),
    },
  })

  return _pusher!
}

function getOrCreateChannelByHash(auctionHash: string, carrierHash: string): Channel {
  if (_channelByHash)
    return _channelByHash

  _channelByHash = getOrCreatePusherByHash().subscribe(`private-data-hash-${auctionHash}@${carrierHash}`)

  return _channelByHash!
}

function getOrCreatePusherByHash(): Pusher {
  if (_pusherByHash)
    return _pusherByHash

  _pusherByHash = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
    cluster: import.meta.env.VITE_PUSHER_CLUSTER,
    channelAuthorization: {
      endpoint: axiosInstance.defaults.baseURL + `/api/v1/push/authorize-by-hash/`,
      transport: 'ajax',
    },
  })

  return _pusherByHash!
}

function createPusherHeaders(): Record<string, string> {
  return {
    Authorization: `Bearer ${useAccessToken.value}`,
  }
}
