/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcceptInvitation {
  key: string;
  /**
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  language?: LanguageEnum;
  marketingPurposes?: boolean;
}

/**
 * * `en` - English
 * * `sk` - Slovak
 * * `de` - German
 * * `fr` - French
 * * `it` - Italian
 * * `cs` - Czech
 */
export enum LanguageEnum {
  En = "en",
  Sk = "sk",
  De = "de",
  Fr = "fr",
  It = "it",
  Cs = "cs",
}

export interface Address {
  /** @maxLength 100 */
  city: string;
  contacts?: AddressContact[];
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country: CountryEnum;
  readonly createdBy: UserGeneralInfo;
  readonly id: number;
  /** @maxLength 50 */
  name?: string;
  /** @maxLength 20 */
  postCode?: string;
  /** @maxLength 100 */
  street?: string;
  readonly workspace: WorkspaceGeneralInfo;
}

export interface AddressContact {
  addressId?: number;
  email?: (string | null)[];
  /**
   * Contact Person Name
   * @maxLength 50
   */
  name?: string;
  phone?: (string | null)[];
}

export interface AddressesListParams {
  city?: string;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country?: CountryEnum;
  name?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  postCode?: string;
  /** A search term. */
  search?: string;
  street?: string;
}

export interface AppInfo {
  readonly build: string;
  readonly commit: string;
  readonly deployment: string;
  readonly release: string;
}

/** Serializer for Auction model. It's used for listing auctions for user and carrier. */
export interface Auction {
  /** @format date-time */
  readonly biddingFinishedAt: string | null;
  readonly bidsInfo: AuctionBidsInfo;
  /** @format date-time */
  readonly canceledAt: string | null;
  /** @format date-time */
  readonly createdAt: string;
  /**
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  readonly currency: CurrencyEnum;
  /** @format date-time */
  readonly deletedAt: string | null;
  readonly duration: string | null;
  /** @format date-time */
  readonly finishingAt: string;
  /** Hash key */
  readonly hash: string;
  readonly id: number;
  readonly invitedParticipants: number[];
  readonly isBiddingAllowed: boolean;
  readonly note: string;
  /** @format date-time */
  readonly publishedAt: string | null;
  /** @format date-time */
  readonly restoredAt: string | null;
  /**
   * * `DRAFT` - Draft
   * * `ACTIVE` - Active
   * * `INSTANT_ORDER` - Instant order
   * * `BIDDING_FINISHED` - Bidding finished
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `OTHER` - Other
   */
  readonly status: AuctionStatusEnum;
  /** @format int32 */
  readonly transportId: number;
  readonly transportRequest: TransportRequest;
  /** @format date-time */
  readonly updatedAt: string;
  readonly workspace: WorkspaceGeneralInfo;
}

export interface AuctionBid {
  readonly carrier: AuctionBidCarrier;
  readonly carrierNote: string;
  readonly id: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  readonly offeredPrice: string;
  readonly offeredPriceCurrency: string;
}

export interface AuctionBidCarrier {
  readonly name: string;
}

export interface AuctionBidsInfo {
  readonly confirmed: AuctionBid;
  readonly latest: AuctionBid;
  readonly lowest: AuctionBid;
  readonly placedCount: number;
  readonly rejectedByCarrier: AuctionBid;
  readonly winning: AuctionBid;
}

export interface AuctionByHash {
  readonly bidsInfo: BidsInfoByHash;
  readonly carrier: AuctionParticipantByHash;
  /**
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  readonly currency: CurrencyEnum;
  readonly differences: Record<string, any>;
  readonly duration: string | null;
  /** @format date-time */
  readonly finishingAt: string;
  /** Hash key */
  readonly hash: string;
  readonly id: number;
  readonly isBiddingAllowed: boolean;
  /** @format date-time */
  readonly publishedAt: string | null;
  /** @format double */
  readonly serviceFee: number;
  /**
   * * `DRAFT` - Draft
   * * `ACTIVE` - Active
   * * `INSTANT_ORDER` - Instant order
   * * `BIDDING_FINISHED` - Bidding finished
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `OTHER` - Other
   */
  readonly status: AuctionStatusEnum;
  readonly transport: TransportByHash | null;
  readonly transportRequest: TransportRequestByHash;
  readonly workspace: WorkspaceByHash;
}

export interface AuctionChangePaymentMethod {
  /**
   * * `INVOICE` - Invoice
   * * `CREDIT_CARD` - Credit card
   */
  paymentMethod: PaymentMethodEnum;
}

/**
 * * `INVOICE` - Invoice
 * * `CREDIT_CARD` - Credit card
 */
export enum PaymentMethodEnum {
  INVOICE = "INVOICE",
  CREDIT_CARD = "CREDIT_CARD",
}

export interface AuctionList {
  readonly bidsInfo: AuctionListBidsInfo;
  /** @format date-time */
  readonly createdAt: string;
  readonly duration: string;
  readonly hash: string;
  readonly id: number;
  readonly invitedParticipantsCount: number;
  /** @format date-time */
  readonly publishedAt: string;
  /**
   * * `DRAFT` - Draft
   * * `ACTIVE` - Active
   * * `INSTANT_ORDER` - Instant order
   * * `BIDDING_FINISHED` - Bidding finished
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `OTHER` - Other
   */
  readonly status: AuctionStatusEnum;
  readonly transportRequest: TransportRequestList;
}

export interface AuctionListBid {
  readonly carrier: AuctionListBidCarrier;
  readonly id: number;
  readonly isInstantOrder: boolean;
  readonly isRejected: boolean;
  readonly metadata: BidMetadata;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  readonly offeredPrice: string;
  readonly offeredPriceCurrency: string;
}

export interface AuctionListBidCarrier {
  readonly name: string;
}

export interface AuctionListBidsInfo {
  readonly confirmed: AuctionListBid;
  readonly latest: AuctionListBid;
  readonly lowest: AuctionListBid;
  readonly placedCount: number;
  readonly rejectedByCarrier: AuctionListBid;
  readonly winning: AuctionListBid;
}

export interface AuctionOverviewStats {
  countByMonth: CountByMonthStats[];
  currencies: CurrencyEnum[];
  /**
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency: CurrencyEnum;
}

export interface AuctionParticipant {
  readonly carrier: AuctionParticipantCarrier;
  readonly emailStatus: AuctionParticipantEmailStatusEnum;
  /** @format date-time */
  readonly emailStatusChangedAt: string;
  readonly id: number;
  readonly messagesCount: number;
  /**
   * * `ACTIVE` - Active
   * * `REMOVED` - Removed
   * * `REJECTED` - Rejected
   */
  readonly status: AuctionParticipantStatusEnum;
  /** @format date-time */
  readonly statusChangedAt: string;
}

export interface AuctionParticipantByHash {
  readonly carrier: AuctionParticipantCarrierByHash;
  readonly id: number;
  /**
   * * `ACTIVE` - Active
   * * `REMOVED` - Removed
   * * `REJECTED` - Rejected
   */
  readonly status: AuctionParticipantStatusEnum;
}

export interface AuctionParticipantCarrier {
  readonly contact: ContactGeneralInfo;
  readonly id: number;
  /**
   * Active
   * Designates whether this carrier should be treated as active. Unselect this instead of deleting carriers.
   */
  readonly isActive: boolean;
  readonly isDeleted: boolean;
  /**
   * Title
   * @maxLength 100
   */
  readonly name: string;
}

export interface AuctionParticipantCarrierByHash {
  readonly contact: ContactGeneralInfo;
  readonly id: number;
  /**
   * Active
   * Designates whether this carrier should be treated as active. Unselect this instead of deleting carriers.
   */
  readonly isActive: boolean;
  /**
   * Title
   * @maxLength 100
   */
  readonly name: string;
}

export enum AuctionParticipantEmailStatusEnum {
  Accepted = "accepted",
  Rejected = "rejected",
  Delivered = "delivered",
  Failed = "failed",
  Opened = "opened",
  Clicked = "clicked",
  Unsubscribed = "unsubscribed",
  Complained = "complained",
  Stored = "stored",
  ListMemberUploaded = "list_member_uploaded",
  ListMemberUploadError = "list_member_upload_error",
  ListUploaded = "list_uploaded",
  Unknown = "unknown",
}

/**
 * * `ACTIVE` - Active
 * * `REMOVED` - Removed
 * * `REJECTED` - Rejected
 */
export enum AuctionParticipantStatusEnum {
  ACTIVE = "ACTIVE",
  REMOVED = "REMOVED",
  REJECTED = "REJECTED",
}

export interface AuctionSetParticipantStatus {
  /**
   * * `ACTIVE` - Active
   * * `REMOVED` - Removed
   * * `REJECTED` - Rejected
   */
  status: AuctionParticipantStatusEnum;
}

export interface AuctionStatsOverviewRetrieveParams {
  /** Auction ID */
  auctionId?: number;
  /**
   * Auction status
   *
   * * `DRAFT` - Draft
   * * `ACTIVE` - Active
   * * `INSTANT_ORDER` - Instant order
   * * `BIDDING_FINISHED` - Bidding finished
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `OTHER` - Other
   */
  auctionStatus?: AuctionStatusEnum;
  /** Winning carrier */
  carrierName?: string;
  costCenter?: string;
  /** @format date-time */
  createdAtAfter?: string;
  /** @format date-time */
  createdAtBefore?: string;
  createdBy?: number;
  /**
   * Currency
   *
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency?: CurrencyEnum;
  deliveryCity?: string;
  deliveryCountry?: string;
  /** @format date-time */
  deliveryDateAfter?: string;
  /** @format date-time */
  deliveryDateBefore?: string;
  deliveryName?: string;
  deliveryPostCode?: string;
  deliveryStreet?: string;
  driverName?: string;
  /**
   * Load type
   *
   * * `FTL` - Full Truck Load
   * * `LTL` - Less than Truck Load
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   * * `PARCEL` - Parcel
   * * `NOT_SPECIFIED` - Not specified
   */
  loadType?: CargoLoadTypeEnum[];
  maxWeightKg?: number;
  minWeightKg?: number;
  /**
   * Ordering
   *
   * * `created_at` - Created at
   * * `-created_at` - Created at (descending)
   */
  order?: OrderEnum[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  pickupCity?: string;
  pickupCountry?: string;
  /** @format date-time */
  pickupDateAfter?: string;
  /** @format date-time */
  pickupDateBefore?: string;
  pickupName?: string;
  pickupPostCode?: string;
  pickupStreet?: string;
  plateNumber?: string;
  /** @format date-time */
  publishedAtAfter?: string;
  /** @format date-time */
  publishedAtBefore?: string;
  reference?: string;
  /**
   * Request type
   *
   * * `FROZEN_GOODS` - Frozen goods
   * * `OVERSIZED_LOAD` - Oversized load
   * * `BULK_MATERIAL` - Bulk material
   * * `LIQUIDS` - Liquids
   * * `VEHICLES` - Vehicles
   * * `ADR` - ADR
   * * `OTHER` - Other
   * * `NOT_SPECIFIED` - Not specified
   */
  requestType?: RequestTypeEnum;
  responsibleUser?: number;
  /**
   * Route type
   *
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType?: RouteTypeEnum;
  /** A search term. */
  search?: string;
  /**
   * Transport type
   *
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  transportType?: TransportTypeEnum;
  /**
   * Transportation mode
   *
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode?: TransportationModeEnum;
}

export enum AuctionStatsOverviewRetrieveParams1OrderEnum {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum AuctionStatsOverviewRetrieveParams1RequestTypeEnum {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

/**
 * * `DRAFT` - Draft
 * * `ACTIVE` - Active
 * * `INSTANT_ORDER` - Instant order
 * * `BIDDING_FINISHED` - Bidding finished
 * * `CONFIRMED` - Confirmed
 * * `CANCELED` - Canceled
 * * `OTHER` - Other
 */
export enum AuctionStatusEnum {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INSTANT_ORDER = "INSTANT_ORDER",
  BIDDING_FINISHED = "BIDDING_FINISHED",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  OTHER = "OTHER",
}

export interface AuctionTakeInstantOrder {
  /**
   * * `INVOICE` - Invoice
   * * `CREDIT_CARD` - Credit card
   */
  paymentMethod: PaymentMethodEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  price: string;
}

export interface AuctionUpdate {
  duration?: string;
  invitedParticipants?: number[];
}

export interface AuctionsBidsListParams {
  auctionPk: number;
  /**
   * Ordering
   *
   * * `created_at` - Created at
   * * `-created_at` - Created at (descending)
   * * `price` - Price
   * * `-price` - Price (descending)
   */
  order?: OrderEnum2[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
}

export enum AuctionsBidsListParams1OrderEnum {
  ValueCreatedAt = "-created_at",
  ValuePrice = "-price",
  CreatedAt = "created_at",
  Price = "price",
}

export interface AuctionsDocumentsListParams {
  auctionPk: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface AuctionsExportRetrieveParams {
  /** Auction ID */
  auctionId?: number;
  /**
   * Auction status
   *
   * * `DRAFT` - Draft
   * * `ACTIVE` - Active
   * * `INSTANT_ORDER` - Instant order
   * * `BIDDING_FINISHED` - Bidding finished
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `OTHER` - Other
   */
  auctionStatus?: AuctionStatusEnum;
  /** Winning carrier */
  carrierName?: string;
  costCenter?: string;
  /** @format date-time */
  createdAtAfter?: string;
  /** @format date-time */
  createdAtBefore?: string;
  createdBy?: number;
  /**
   * Currency
   *
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency?: CurrencyEnum;
  deliveryCity?: string;
  deliveryCountry?: string;
  /** @format date-time */
  deliveryDateAfter?: string;
  /** @format date-time */
  deliveryDateBefore?: string;
  deliveryName?: string;
  deliveryPostCode?: string;
  deliveryStreet?: string;
  driverName?: string;
  /**
   * Load type
   *
   * * `FTL` - Full Truck Load
   * * `LTL` - Less than Truck Load
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   * * `PARCEL` - Parcel
   * * `NOT_SPECIFIED` - Not specified
   */
  loadType?: CargoLoadTypeEnum[];
  maxWeightKg?: number;
  minWeightKg?: number;
  /**
   * Ordering
   *
   * * `created_at` - Created at
   * * `-created_at` - Created at (descending)
   */
  order?: OrderEnum3[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  pickupCity?: string;
  pickupCountry?: string;
  /** @format date-time */
  pickupDateAfter?: string;
  /** @format date-time */
  pickupDateBefore?: string;
  pickupName?: string;
  pickupPostCode?: string;
  pickupStreet?: string;
  plateNumber?: string;
  /** @format date-time */
  publishedAtAfter?: string;
  /** @format date-time */
  publishedAtBefore?: string;
  reference?: string;
  /**
   * Request type
   *
   * * `FROZEN_GOODS` - Frozen goods
   * * `OVERSIZED_LOAD` - Oversized load
   * * `BULK_MATERIAL` - Bulk material
   * * `LIQUIDS` - Liquids
   * * `VEHICLES` - Vehicles
   * * `ADR` - ADR
   * * `OTHER` - Other
   * * `NOT_SPECIFIED` - Not specified
   */
  requestType?: RequestTypeEnum2;
  responsibleUser?: number;
  /**
   * Route type
   *
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType?: RouteTypeEnum;
  /** A search term. */
  search?: string;
  /**
   * Transport type
   *
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  transportType?: TransportTypeEnum;
  /**
   * Transportation mode
   *
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode?: TransportationModeEnum;
}

export enum AuctionsExportRetrieveParams1OrderEnum {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum AuctionsExportRetrieveParams1RequestTypeEnum {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

export interface AuctionsListParams {
  /** Auction ID */
  auctionId?: number;
  /**
   * Auction status
   *
   * * `DRAFT` - Draft
   * * `ACTIVE` - Active
   * * `INSTANT_ORDER` - Instant order
   * * `BIDDING_FINISHED` - Bidding finished
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `OTHER` - Other
   */
  auctionStatus?: AuctionStatusEnum;
  /** Winning carrier */
  carrierName?: string;
  costCenter?: string;
  /** @format date-time */
  createdAtAfter?: string;
  /** @format date-time */
  createdAtBefore?: string;
  createdBy?: number;
  /**
   * Currency
   *
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency?: CurrencyEnum;
  deliveryCity?: string;
  deliveryCountry?: string;
  /** @format date-time */
  deliveryDateAfter?: string;
  /** @format date-time */
  deliveryDateBefore?: string;
  deliveryName?: string;
  deliveryPostCode?: string;
  deliveryStreet?: string;
  driverName?: string;
  /**
   * Load type
   *
   * * `FTL` - Full Truck Load
   * * `LTL` - Less than Truck Load
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   * * `PARCEL` - Parcel
   * * `NOT_SPECIFIED` - Not specified
   */
  loadType?: CargoLoadTypeEnum[];
  maxWeightKg?: number;
  minWeightKg?: number;
  /**
   * Ordering
   *
   * * `created_at` - Created at
   * * `-created_at` - Created at (descending)
   */
  order?: OrderEnum1[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  pickupCity?: string;
  pickupCountry?: string;
  /** @format date-time */
  pickupDateAfter?: string;
  /** @format date-time */
  pickupDateBefore?: string;
  pickupName?: string;
  pickupPostCode?: string;
  pickupStreet?: string;
  plateNumber?: string;
  /** @format date-time */
  publishedAtAfter?: string;
  /** @format date-time */
  publishedAtBefore?: string;
  reference?: string;
  /**
   * Request type
   *
   * * `FROZEN_GOODS` - Frozen goods
   * * `OVERSIZED_LOAD` - Oversized load
   * * `BULK_MATERIAL` - Bulk material
   * * `LIQUIDS` - Liquids
   * * `VEHICLES` - Vehicles
   * * `ADR` - ADR
   * * `OTHER` - Other
   * * `NOT_SPECIFIED` - Not specified
   */
  requestType?: RequestTypeEnum1;
  responsibleUser?: number;
  /**
   * Route type
   *
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType?: RouteTypeEnum;
  /** A search term. */
  search?: string;
  /**
   * Transport type
   *
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  transportType?: TransportTypeEnum;
  /**
   * Transportation mode
   *
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode?: TransportationModeEnum;
}

export enum AuctionsListParams1OrderEnum {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum AuctionsListParams1RequestTypeEnum {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

export interface AuctionsMessagesListParams {
  auctionPk: number;
  carrier?: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface AuctionsParticipantsListParams {
  auctionPk: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
}

export interface AutocompleteRequest {
  field: string;
  term: string;
}

export interface AutocompleteResponse {
  readonly values: string[];
}

/**
 * Base serializer for Bid model. Should not expose any sensitive data.
 * Could be used for minimum information about bid.
 */
export interface Bid {
  readonly carrier: number;
  readonly carrierNote: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  readonly carrierStatus: BidStatusEnum;
  /** @format date-time */
  readonly carrierStatusChangedAt: string;
  /** @format date-time */
  readonly createdAt: string;
  readonly id: number;
  readonly isConfirmedByShipper: boolean;
  isInstantOrder?: boolean;
  readonly isRejected: boolean;
  readonly isWinning: boolean;
  metadata?: BidMetadata | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  offeredPrice?: string;
  readonly offeredPriceCurrency: string;
  shipperNote?: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  shipperStatus?: BidStatusEnum;
  /** @format date-time */
  readonly shipperStatusChangedAt: string;
  /** @format date-time */
  readonly winsAt: string | null;
}

/**
 * Base serializer for Bid model. Should not expose any sensitive data.
 * Could be used for minimum information about bid.
 */
export interface BidByHash {
  readonly carrier: number;
  carrierNote?: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  carrierStatus?: BidStatusEnum;
  /** You might already have a comparison value for a bid. It could be calculated in queryset. */
  readonly comparison: BidComparisonEnum;
  /** @format date-time */
  readonly createdAt: string;
  /** Hash key */
  readonly hash: string;
  readonly id: number;
  readonly isConfirmedByShipper: boolean;
  readonly isRejected: boolean;
  readonly isWinning: boolean;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  offeredPrice: string;
  readonly offeredPriceCurrency: string;
  readonly shipperNote: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  readonly shipperStatus: BidStatusEnum;
}

/**
 * * `CONFIRMED` - Confirmed
 * * `REJECTED` - Rejected
 * * `NOT_DECIDED` - Not decided
 */
export enum BidStatusEnum {
  CONFIRMED = "CONFIRMED",
  REJECTED = "REJECTED",
  NOT_DECIDED = "NOT_DECIDED",
}

/** You might already have a comparison value for a bid. It could be calculated in queryset. */
export enum BidComparisonEnum {
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  AVERAGE = "AVERAGE",
  BAD = "BAD",
  VERY_BAD = "VERY_BAD",
}

export interface BidMetadata {
  /** @format date-time */
  readonly paidAt: string;
  /**
   * * `INVOICE` - Invoice
   * * `CREDIT_CARD` - Credit card
   */
  readonly shipperPaymentMethod: PaymentMethodEnum;
}

export interface BidsInfoByHash {
  readonly confirmed: BidByHash;
  readonly latest: BidByHash;
  readonly placedCount: number;
  readonly rejectedByCarrier: BidByHash;
  readonly winning: BidByHash;
}

export interface BillingDetails {
  city?: string | null;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country: CountryEnum;
  readonly id: number;
  readonly isApproved: boolean;
  /** @maxLength 100 */
  name: string;
  /** @maxLength 20 */
  postCode?: string;
  registrationNumber?: string | null;
  /**
   * * `WAITING_FOR_APPROVAL` - Waiting for approval
   * * `WAITING_FOR_MANUAL_APPROVAL` - Waiting for manual approval
   * * `APPROVED_MANUALLY` - Approved manually
   * * `REJECTED_MANUALLY` - Rejected manually
   * * `APPROVED_AUTOMATICALLY` - Approved automatically
   * * `REJECTED_AUTOMATICALLY` - Rejected automatically
   */
  readonly status: BillingDetailsStatusEnum;
  /** @maxLength 100 */
  street?: string;
  taxNumber?: string | null;
  vatNumber?: string | null;
}

export interface BillingDetailsLimited {
  readonly id: number;
  readonly isApproved: boolean;
  registrationNumber?: string | null;
  taxNumber?: string | null;
  vatNumber?: string | null;
}

/**
 * * `WAITING_FOR_APPROVAL` - Waiting for approval
 * * `WAITING_FOR_MANUAL_APPROVAL` - Waiting for manual approval
 * * `APPROVED_MANUALLY` - Approved manually
 * * `REJECTED_MANUALLY` - Rejected manually
 * * `APPROVED_AUTOMATICALLY` - Approved automatically
 * * `REJECTED_AUTOMATICALLY` - Rejected automatically
 */
export enum BillingDetailsStatusEnum {
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  WAITING_FOR_MANUAL_APPROVAL = "WAITING_FOR_MANUAL_APPROVAL",
  APPROVED_MANUALLY = "APPROVED_MANUALLY",
  REJECTED_MANUALLY = "REJECTED_MANUALLY",
  APPROVED_AUTOMATICALLY = "APPROVED_AUTOMATICALLY",
  REJECTED_AUTOMATICALLY = "REJECTED_AUTOMATICALLY",
}

export interface BulkInvitation {
  emails: string[];
  role: number;
  workspaces: number[];
}

export interface ByHashCarriersAuctionsBidsListParams {
  auctionHash: string;
  carrierHash: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface ByHashCarriersAuctionsDocumentsListParams {
  auctionHash: string;
  carrierHash: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface ByHashCarriersAuctionsMessagesListParams {
  auctionHash: string;
  carrierHash: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface ByHashCarriersBillingDetailsListParams {
  carrierHash: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export type CaptchaVerifyCreatePayload = string;

export interface Cargo {
  description?: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  height?: string | null;
  readonly id: number;
  items: CargoItem[];
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  length?: string | null;
  /**
   * * `m` - m
   * * `cm` - cm
   * * `mm` - mm
   */
  lengthUnit?: LengthUnitEnum;
  /**
   * * `FTL` - Full Truck Load
   * * `LTL` - Less than Truck Load
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   * * `PARCEL` - Parcel
   * * `NOT_SPECIFIED` - Not specified
   */
  loadType?: CargoLoadTypeEnum;
  note?: string;
  specialRequirements?: CargoSpecialRequirementsEnum[];
  /**
   * * `FROZEN_GOODS` - Frozen goods
   * * `OVERSIZED_LOAD` - Oversized load
   * * `BULK_MATERIAL` - Bulk material
   * * `LIQUIDS` - Liquids
   * * `VEHICLES` - Vehicles
   * * `ADR` - ADR
   * * `OTHER` - Other
   * * `NOT_SPECIFIED` - Not specified
   */
  type?: CargoTypeEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  value?: string | null;
  readonly valueCurrency: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
   */
  weight?: string | null;
  /**
   * * `kg` - kg
   * * `t` - t
   */
  weightUnit?: WeightUnitEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  width?: string | null;
}

export interface CargoItem {
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  height?: string | null;
  id?: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  length?: string | null;
  /**
   * * `m` - m
   * * `cm` - cm
   * * `mm` - mm
   */
  lengthUnit?: LengthUnitEnum;
  /**
   * @min 0
   * @max 10000
   */
  quantity: number;
  /**
   * * `1 + 1` - 1 + 1
   * * `1 + 2` - 1 + 2
   * * `1 + 3` - 1 + 3
   * * `1 + 4` - 1 + 4
   * * `1 + 5` - 1 + 5
   * * `1 + 6` - 1 + 6
   * * `1 + 7` - 1 + 7
   * * `1 + 8` - 1 + 8
   * * `1 + 9` - 1 + 9
   * * `1 + 10` - 1 + 10
   */
  stackCount?: CargoItemStackCountEnum;
  stackable?: boolean;
  unit: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
   */
  weight?: string | null;
  /**
   * * `kg` - kg
   * * `t` - t
   */
  weightUnit?: WeightUnitEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  width?: string | null;
}

/**
 * * `m` - m
 * * `cm` - cm
 * * `mm` - mm
 */
export enum LengthUnitEnum {
  M = "m",
  Cm = "cm",
  Mm = "mm",
}

/**
 * * `1 + 1` - 1 + 1
 * * `1 + 2` - 1 + 2
 * * `1 + 3` - 1 + 3
 * * `1 + 4` - 1 + 4
 * * `1 + 5` - 1 + 5
 * * `1 + 6` - 1 + 6
 * * `1 + 7` - 1 + 7
 * * `1 + 8` - 1 + 8
 * * `1 + 9` - 1 + 9
 * * `1 + 10` - 1 + 10
 */
export enum CargoItemStackCountEnum {
  Value11 = "1 + 1",
  Value12 = "1 + 2",
  Value13 = "1 + 3",
  Value14 = "1 + 4",
  Value15 = "1 + 5",
  Value16 = "1 + 6",
  Value17 = "1 + 7",
  Value18 = "1 + 8",
  Value19 = "1 + 9",
  Value110 = "1 + 10",
  Value = "",
}

export interface CargoItemUnit {
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  height?: string | null;
  readonly id: number;
  isActive?: boolean;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  length?: string | null;
  /**
   * * `m` - m
   * * `cm` - cm
   * * `mm` - mm
   */
  lengthUnit?: LengthUnitEnum;
  /** @maxLength 150 */
  title: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  width?: string | null;
}

export interface CargoItemUnitsListParams {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

/**
 * * `kg` - kg
 * * `t` - t
 */
export enum WeightUnitEnum {
  Kg = "kg",
  T = "t",
}

/**
 * * `FTL` - Full Truck Load
 * * `LTL` - Less than Truck Load
 * * `FCL` - Full Container Load
 * * `LCL` - Less than Container Load
 * * `PARCEL` - Parcel
 * * `NOT_SPECIFIED` - Not specified
 */
export enum CargoLoadTypeEnum {
  FTL = "FTL",
  LTL = "LTL",
  FCL = "FCL",
  LCL = "LCL",
  PARCEL = "PARCEL",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  Value = "",
}

/**
 * * `HANDLING_FROM_TOP` - Handling from top
 * * `HANDLING_FROM_BACK` - Handling from back
 * * `HANDLING_FROM_SIDE` - Handling from side
 * * `TAIL_LIFT` - Tail lift
 * * `SLIDING_DOOR` - Sliding door
 * * `SAFETY_VEST_FOR_DRIVER` - Safety vest for driver
 * * `HYDRAULIC_ARM` - Hydraulic arm
 * * `CLAMPING_STRAPS` - Clamping straps
 * * `SAFETY_SHOES_FOR_DRIVER` - Safety shoes for driver
 * * `HELMET_FOR_DRIVER` - Helmet for driver
 */
export enum CargoSpecialRequirementsEnum {
  HANDLING_FROM_TOP = "HANDLING_FROM_TOP",
  HANDLING_FROM_BACK = "HANDLING_FROM_BACK",
  HANDLING_FROM_SIDE = "HANDLING_FROM_SIDE",
  TAIL_LIFT = "TAIL_LIFT",
  SLIDING_DOOR = "SLIDING_DOOR",
  SAFETY_VEST_FOR_DRIVER = "SAFETY_VEST_FOR_DRIVER",
  HYDRAULIC_ARM = "HYDRAULIC_ARM",
  CLAMPING_STRAPS = "CLAMPING_STRAPS",
  SAFETY_SHOES_FOR_DRIVER = "SAFETY_SHOES_FOR_DRIVER",
  HELMET_FOR_DRIVER = "HELMET_FOR_DRIVER",
}

/**
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum CargoTypeEnum {
  FROZEN_GOODS = "FROZEN_GOODS",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  BULK_MATERIAL = "BULK_MATERIAL",
  LIQUIDS = "LIQUIDS",
  VEHICLES = "VEHICLES",
  ADR = "ADR",
  OTHER = "OTHER",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  Value = "",
}

export interface Carrier {
  address?: CompanyAddress | null;
  billingDetails: CarrierBillingDetailsLimited;
  readonly billingDetailsLatest: CarrierBillingDetails;
  readonly consentStatuses: ConsentInfo[];
  contact?: Contact | null;
  groups?: CarrierGroup[];
  readonly id: number;
  /** @maxLength 50 */
  internalId?: string;
  /**
   * Active
   * Designates whether this carrier should be treated as active. Unselect this instead of deleting carriers.
   */
  isActive?: boolean;
  readonly isDeleted: boolean;
  /**
   * Title
   * @maxLength 100
   */
  name: string;
  note?: string;
  /**
   * Language
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  preferredLanguage?: LanguageEnum;
  transportationModes?: TransportationModeEnum[];
  /**
   * @format uri
   * @maxLength 400
   */
  website?: string;
  readonly workspace: WorkspaceGeneralInfo;
}

export interface CarrierBillingDetails {
  city?: string | null;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country: CountryEnum;
  readonly id: number;
  readonly isApproved: boolean;
  /** @maxLength 100 */
  name: string;
  /** @maxLength 20 */
  postCode?: string;
  registrationNumber?: string | null;
  /**
   * * `WAITING_FOR_APPROVAL` - Waiting for approval
   * * `WAITING_FOR_MANUAL_APPROVAL` - Waiting for manual approval
   * * `APPROVED_MANUALLY` - Approved manually
   * * `REJECTED_MANUALLY` - Rejected manually
   * * `APPROVED_AUTOMATICALLY` - Approved automatically
   * * `REJECTED_AUTOMATICALLY` - Rejected automatically
   */
  readonly status: BillingDetailsStatusEnum;
  /** @maxLength 100 */
  street?: string;
  taxNumber?: string | null;
  vatNumber?: string | null;
}

export interface CarrierBillingDetailsByHashInput {
  city: string;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country: CountryEnum;
  /** @format email */
  email: string;
  readonly id: number;
  readonly isApproved: boolean;
  name: string;
  postCode: string;
  registrationNumber?: string | null;
  /**
   * * `WAITING_FOR_APPROVAL` - Waiting for approval
   * * `WAITING_FOR_MANUAL_APPROVAL` - Waiting for manual approval
   * * `APPROVED_MANUALLY` - Approved manually
   * * `REJECTED_MANUALLY` - Rejected manually
   * * `APPROVED_AUTOMATICALLY` - Approved automatically
   * * `REJECTED_AUTOMATICALLY` - Rejected automatically
   */
  readonly status: BillingDetailsStatusEnum;
  street: string;
  taxNumber?: string | null;
  vatNumber?: string | null;
}

export interface CarrierBillingDetailsByHashOutput {
  readonly city: string;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  readonly country: CountryEnum;
  /** @format email */
  readonly email: string;
  readonly id: number;
  readonly isApproved: boolean;
  readonly name: string;
  readonly postCode: string;
  readonly registrationNumber: string | null;
  /**
   * * `WAITING_FOR_APPROVAL` - Waiting for approval
   * * `WAITING_FOR_MANUAL_APPROVAL` - Waiting for manual approval
   * * `APPROVED_MANUALLY` - Approved manually
   * * `REJECTED_MANUALLY` - Rejected manually
   * * `APPROVED_AUTOMATICALLY` - Approved automatically
   * * `REJECTED_AUTOMATICALLY` - Rejected automatically
   */
  readonly status: BillingDetailsStatusEnum;
  readonly street: string;
  readonly taxNumber: string | null;
  readonly vatNumber: string | null;
}

export interface CarrierBillingDetailsLimited {
  readonly id: number;
  readonly isApproved: boolean;
  registrationNumber?: string | null;
  taxNumber?: string | null;
  vatNumber?: string | null;
}

export interface CarrierByHash {
  readonly billingDetails: CarrierByHashBillingDetails;
  readonly billingDetailsCandidate: CarrierByHashBillingDetails;
  /** @maxLength 50 */
  internalId?: string;
  /**
   * Language
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  preferredLanguage?: LanguageEnum;
  readonly termsData: CarrierByHashTermsData;
}

export interface CarrierByHashBillingDetails {
  city?: string | null;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country: CountryEnum;
  /**
   * @format email
   * @maxLength 254
   */
  email: string;
  readonly id: number;
  readonly isApproved: boolean;
  /** @maxLength 100 */
  name: string;
  /** @maxLength 20 */
  postCode?: string;
  registrationNumber?: string | null;
  /**
   * * `WAITING_FOR_APPROVAL` - Waiting for approval
   * * `WAITING_FOR_MANUAL_APPROVAL` - Waiting for manual approval
   * * `APPROVED_MANUALLY` - Approved manually
   * * `REJECTED_MANUALLY` - Rejected manually
   * * `APPROVED_AUTOMATICALLY` - Approved automatically
   * * `REJECTED_AUTOMATICALLY` - Rejected automatically
   */
  readonly status: BillingDetailsStatusEnum;
  /** @maxLength 100 */
  street?: string;
  taxNumber?: string | null;
  vatNumber?: string | null;
}

export interface CarrierByHashTermsData {
  readonly approved: boolean;
  readonly requiredApproved: boolean;
  readonly token: string;
}

export interface CarrierDocumentCreate {
  /**
   * Document
   * file
   * @format uri
   */
  file: string;
  /**
   * Name
   * @maxLength 200
   */
  title: string;
}

export interface CarrierGeneralInfo {
  readonly consentStatuses: ConsentInfo[];
  contact?: ContactGeneralInfo;
  id?: number | null;
  /**
   * Active
   * Designates whether this carrier should be treated as active. Unselect this instead of deleting carriers.
   */
  isActive?: boolean;
  /**
   * Title
   * @maxLength 100
   */
  name: string;
  transportationModes?: TransportationModeEnum[];
}

export interface CarrierGroup {
  readonly carriers: CarrierGeneralInfo[];
  carriersIds: number[];
  /** @format date-time */
  readonly createdAt: string;
  readonly createdBy: UserGeneralInfo;
  readonly id: number;
  /** @maxLength 255 */
  name: string;
  note?: string;
  /** @format date-time */
  readonly updatedAt: string;
  readonly workspace: WorkspaceGeneralInfo;
}

export interface CarrierGroupGeneralInfo {
  readonly carriers: CarrierGeneralInfo[];
  id?: number | null;
  /** @maxLength 255 */
  name: string;
}

export interface CarrierGroupsAllListParams {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
}

export interface CarrierGroupsListParams {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface CarriersAllListParams {
  city?: string;
  contactEmail?: string;
  country?: string;
  name?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  postCode?: string;
  /** A search term. */
  search?: string;
  street?: string;
}

export interface CarriersListParams {
  city?: string;
  contactEmail?: string;
  country?: string;
  includeDeleted?: boolean;
  includeGlobal?: boolean;
  name?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  postCode?: string;
  /** A search term. */
  search?: string;
  street?: string;
}

export interface CarriersStats {
  readonly name: string;
  /** @format double */
  readonly totalDistance: number;
  readonly transportCount: number;
}

export interface CheckoutRequestData {
  auctionId: number;
}

export interface CheckoutResponseData {
  error?: string | null;
  sessionId: string | null;
}

export interface CofaceCompanyInfoResponse {
  city?: string | null;
  cofaceCompanyName?: string | null;
  country?: string | null;
  nationalId?: string | null;
  postcode?: string | null;
  regId?: string | null;
  street?: string | null;
  taxId?: string | null;
  vatId?: string | null;
}

export interface CompanyAddress {
  city?: string | null;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country?: CountryEnum;
  readonly createdBy: UserGeneralInfo;
  readonly id: number;
  /** @maxLength 20 */
  postCode?: string;
  /** @maxLength 100 */
  street?: string;
}

export interface CompanyInfoPayload {
  companyName?: string | null;
  country: string | null;
  insuranceStatusCheck?: boolean;
  vatId?: string | null;
}

export interface CompanyStats {
  auctions: Stats;
  transports: Stats;
  users: UserStats;
}

export interface ConsentByTokenInput {
  accepted?: boolean;
  /**
   * * `EMAIL` - Email
   * * `PRICER_POPUP` - Pricer popup
   * * `TEST_EVENT_CARRIER` - Test event carrier
   */
  consentType: ConsentByTokenInputConsentTypeEnum;
  objectRef: string;
  rejected?: boolean;
}

/**
 * * `EMAIL` - Email
 * * `PRICER_POPUP` - Pricer popup
 * * `TEST_EVENT_CARRIER` - Test event carrier
 */
export enum ConsentByTokenInputConsentTypeEnum {
  EMAIL = "EMAIL",
  PRICER_POPUP = "PRICER_POPUP",
  TEST_EVENT_CARRIER = "TEST_EVENT_CARRIER",
}

export interface ConsentByTokenSetOutput {
  carrierHash?: string;
  consents: ConsentOutput[];
  displayAcceptPopup?: boolean;
  /** @format email */
  email: string;
  targetObjectRef?: string;
}

export interface ConsentInfo {
  /**
   * * `EMAIL` - Email
   * * `PRICER_POPUP` - Pricer popup
   * * `TEST_EVENT_CARRIER` - Test event carrier
   */
  consentType: ConsentInfoConsentTypeEnum;
  isGained: boolean;
  isRequired: boolean;
  /**
   * * `ACCEPTED_GLOBALLY` - Accepted globally
   * * `REJECTED_GLOBALLY` - Rejected globally
   * * `ACCEPTED_FOR_WORKSPACE` - Accepted for workspace
   * * `REJECTED_FOR_WORKSPACE` - Rejected for workspace
   * * `ACCEPTED_AUTOMATICALLY_FROM_SETTINGS` - Accepted automatically based on settings
   * * `TOO_MANY_REQUESTS` - Too many requests
   * * `REQUESTED` - Requested
   * * `NOT_REQUESTED` - No request sent yet
   * * `OTHER` - Other
   */
  reason: ConsentInfoReasonEnum;
}

/**
 * * `EMAIL` - Email
 * * `PRICER_POPUP` - Pricer popup
 * * `TEST_EVENT_CARRIER` - Test event carrier
 */
export enum ConsentInfoConsentTypeEnum {
  EMAIL = "EMAIL",
  PRICER_POPUP = "PRICER_POPUP",
  TEST_EVENT_CARRIER = "TEST_EVENT_CARRIER",
}

/**
 * * `ACCEPTED_GLOBALLY` - Accepted globally
 * * `REJECTED_GLOBALLY` - Rejected globally
 * * `ACCEPTED_FOR_WORKSPACE` - Accepted for workspace
 * * `REJECTED_FOR_WORKSPACE` - Rejected for workspace
 * * `ACCEPTED_AUTOMATICALLY_FROM_SETTINGS` - Accepted automatically based on settings
 * * `TOO_MANY_REQUESTS` - Too many requests
 * * `REQUESTED` - Requested
 * * `NOT_REQUESTED` - No request sent yet
 * * `OTHER` - Other
 */
export enum ConsentInfoReasonEnum {
  ACCEPTED_GLOBALLY = "ACCEPTED_GLOBALLY",
  REJECTED_GLOBALLY = "REJECTED_GLOBALLY",
  ACCEPTED_FOR_WORKSPACE = "ACCEPTED_FOR_WORKSPACE",
  REJECTED_FOR_WORKSPACE = "REJECTED_FOR_WORKSPACE",
  ACCEPTED_AUTOMATICALLY_FROM_SETTINGS = "ACCEPTED_AUTOMATICALLY_FROM_SETTINGS",
  TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
  REQUESTED = "REQUESTED",
  NOT_REQUESTED = "NOT_REQUESTED",
  OTHER = "OTHER",
}

export interface ConsentOutput {
  consentStatuses: ConsentInfo[];
  objectRef: string;
  workspace: WorkspaceGeneralInfo;
}

export enum ConsentTypesEnum {
  EMAIL = "EMAIL",
  PRICER_POPUP = "PRICER_POPUP",
  TEST_EVENT_CARRIER = "TEST_EVENT_CARRIER",
}

export interface ConsentsSettings {
  /**
   * * `EMAIL` - Email
   * * `PRICER_POPUP` - Pricer popup
   * * `TEST_EVENT_CARRIER` - Test event carrier
   */
  consentType: ConsentsSettingsConsentTypeEnum;
  isAccepted: boolean;
  /** * `EMAIL_SUBSCRIPTION_AUTOMATIC` - Auto email subscription */
  settingsType: ConsentsSettingsSettingsTypeEnum;
}

/**
 * * `EMAIL` - Email
 * * `PRICER_POPUP` - Pricer popup
 * * `TEST_EVENT_CARRIER` - Test event carrier
 */
export enum ConsentsSettingsConsentTypeEnum {
  EMAIL = "EMAIL",
  PRICER_POPUP = "PRICER_POPUP",
  TEST_EVENT_CARRIER = "TEST_EVENT_CARRIER",
}

/** * `EMAIL_SUBSCRIPTION_AUTOMATIC` - Auto email subscription */
export enum ConsentsSettingsSettingsTypeEnum {
  EMAIL_SUBSCRIPTION_AUTOMATIC = "EMAIL_SUBSCRIPTION_AUTOMATIC",
}

export interface Contact {
  readonly consentStatuses: ConsentInfo[];
  /**
   * Contact email
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @maxLength 50 */
  fullName?: string;
  /** @maxLength 128 */
  phoneNumber?: string;
}

export interface ContactGeneralInfo {
  readonly consentStatuses: ConsentInfo[];
  /**
   * Contact email
   * @format email
   * @maxLength 254
   */
  email: string;
}

export interface CountByMonthStats {
  count: number;
  /** @format date-time */
  month: string;
}

/**
 * * `AF` - Afghanistan
 * * `AX` - Åland Islands
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Bonaire, Sint Eustatius and Saba
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos (Keeling) Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CG` - Congo
 * * `CD` - Congo (the Democratic Republic of the)
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `CI` - Côte d'Ivoire
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curaçao
 * * `CY` - Cyprus
 * * `CZ` - Czechia
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands (Malvinas)
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `KP` - North Korea
 * * `MK` - North Macedonia
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `RE` - Réunion
 * * `RO` - Romania
 * * `RU` - Russia
 * * `RW` - Rwanda
 * * `BL` - Saint Barthélemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - South Georgia and the South Sandwich Islands
 * * `KR` - South Korea
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syria
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Türkiye
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - United States Minor Outlying Islands
 * * `US` - United States of America
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (British)
 * * `VI` - Virgin Islands (U.S.)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 */
export enum CountryEnum {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

/**
 * Currency
 *
 * * `EUR` - EUR
 * * `USD` - USD
 * * `CZK` - CZK
 * * `HUF` - HUF
 * * `PLN` - PLN
 * * `RON` - RON
 * * `GBP` - GBP
 * * `BGN` - BGN
 * * `NOK` - NOK
 * * `DKK` - DKK
 * * `SEK` - SEK
 * * `ISK` - ISK
 * * `CHF` - CHF
 */
export enum CurrencyEnum {
  BGN = "BGN",
  CHF = "CHF",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HUF = "HUF",
  ISK = "ISK",
  NOK = "NOK",
  PLN = "PLN",
  RON = "RON",
  SEK = "SEK",
  USD = "USD",
}

export interface Document {
  /**
   * * `ALL` - Shared with all
   * * `WINNER` - Shared with winner
   * * `PRIVATE` - Private (only shipper)
   */
  accessLevel?: DocumentAccessLevelEnum;
  readonly assignedObjectContentType: string;
  readonly assignedObjectId: number;
  /** @format date-time */
  readonly createdAt: string;
  readonly createdByContentType: string;
  readonly createdById: number;
  readonly createdByName: string;
  /**
   * Document
   * file
   * @format uri
   */
  file: string;
  /** bytes */
  readonly fileSize: number | null;
  id?: number | null;
  readonly sources: DocumentSource[];
  /**
   * Name
   * @maxLength 200
   */
  title: string;
}

/**
 * * `ALL` - Shared with all
 * * `WINNER` - Shared with winner
 * * `PRIVATE` - Private (only shipper)
 */
export enum DocumentAccessLevelEnum {
  ALL = "ALL",
  WINNER = "WINNER",
  PRIVATE = "PRIVATE",
  Value = "",
}

export interface DocumentCreate {
  /**
   * * `ALL` - Shared with all
   * * `WINNER` - Shared with winner
   * * `PRIVATE` - Private (only shipper)
   */
  accessLevel?: DocumentAccessLevelEnum;
  assignedObjectContentType: string;
  /**
   * @min 0
   * @max 2147483647
   */
  assignedObjectId: number;
  /**
   * Document
   * file
   * @format uri
   */
  file: string;
  /**
   * Name
   * @maxLength 200
   */
  title: string;
}

export interface DocumentSource {
  readonly contentType: string;
  readonly displayId: number;
  readonly id: number;
}

export interface DocumentUpdate {
  /**
   * * `ALL` - Shared with all
   * * `WINNER` - Shared with winner
   * * `PRIVATE` - Private (only shipper)
   */
  accessLevel?: DocumentAccessLevelEnum;
  title?: string;
}

export interface DocumentsListParams {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface FeatureFlagStatus {
  isActive: boolean;
}

export interface FeatureFlagsStatus {
  readonly flags: Record<string, FeatureFlagStatus>;
  readonly samples: Record<string, FeatureSampleStatus>;
  readonly switches: Record<string, FeatureSwitchStatus>;
}

export interface FeatureSampleStatus {
  isActive: boolean;
}

export interface FeatureSwitch {
  /** * `calculating-pricer-price-switch` - CALCULATING_PRICER_PRICE */
  readonly name: FeatureSwitchNameEnum;
}

/** * `calculating-pricer-price-switch` - CALCULATING_PRICER_PRICE */
export enum FeatureSwitchNameEnum {
  CalculatingPricerPriceSwitch = "calculating-pricer-price-switch",
}

export interface FeatureSwitchStatus {
  isActive: boolean;
}

export enum FormatEnum {
  Json = "json",
  Yaml = "yaml",
}

export interface GenericSender {
  id: number;
  name: string;
  /**
   * * `carrier` - Carrier
   * * `user` - User
   * * `unknown` - Unknown
   */
  type: GenericSenderTypeEnum;
}

/**
 * * `carrier` - Carrier
 * * `user` - User
 * * `unknown` - Unknown
 */
export enum GenericSenderTypeEnum {
  Carrier = "carrier",
  User = "user",
  Unknown = "unknown",
}

export interface Invitation {
  /**
   * E-mail address
   * @format email
   * @maxLength 254
   */
  email: string;
  role?: number | null;
}

export type InvitationsRemoveCreatePayload = string;

/** Serializer for JWT authentication. */
export interface JWT {
  access: string;
  refresh: string;
  /** User model w/o password */
  user: UserDetails;
}

export enum LangEnum {
  Cs = "cs",
  De = "de",
  En = "en",
  Fr = "fr",
  It = "it",
  Sk = "sk",
}

export interface LocationAddress {
  /** @maxLength 100 */
  city: string;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country: CountryEnum;
  readonly id: number;
  readonly location: PointField | null;
  /** @maxLength 20 */
  postCode?: string;
  /** @maxLength 100 */
  street?: string;
}

export interface LocationData {
  addresses: LocationAddress[];
  /** @format double */
  distance?: number;
  readonly points: PointField[];
  polyline?: string;
  /** @format uri */
  readonly staticMap: string;
}

export interface Login {
  /** @format email */
  email?: string;
  password: string;
  username?: string;
}

export interface MarkAsReadMessage {
  carrier: number;
}

export interface Member {
  readonly role: Role;
  readonly workspace: WorkspaceGeneralInfo;
}

export interface Message {
  carrier?: number;
  content?: string;
  /** @format date-time */
  readonly createdAt: string;
  readonly data: MessageData;
  /**
   * * `NEW_MESSAGE` - New message
   * * `BIDDING_FINISHED` - Bidding finished
   * * `NEW_BID` - New bid
   * * `BID_SELECTED` - Bid selected
   * * `DOCUMENT_UPLOADED` - Document uploaded
   * * `TRANSPORT_CONFIRMED` - Transport confirmed
   * * `REQUEST_REJECTED` - Request rejected
   * * `TRANSPORT_REJECTED` - Transport rejected
   * * `REQUEST_DURATION_EXTENDED` - Request duration extended
   * * `EMAIL_RESENT` -  Email resent
   */
  readonly event: MessageEventEnum;
  readonly id: number;
  /** @format date-time */
  readonly seenAt: string;
  readonly sender: GenericSender;
  /**
   * * `human` - Human
   * * `system` - System
   */
  readonly type: MessageTypeEnum;
}

export interface MessageData {
  bidCurrency?: string;
  /** @format double */
  bidPrice?: number;
  /** @format double */
  distance?: number;
  fileId?: number;
  fileName?: string;
  fileSize?: number;
}

/**
 * * `NEW_MESSAGE` - New message
 * * `BIDDING_FINISHED` - Bidding finished
 * * `NEW_BID` - New bid
 * * `BID_SELECTED` - Bid selected
 * * `DOCUMENT_UPLOADED` - Document uploaded
 * * `TRANSPORT_CONFIRMED` - Transport confirmed
 * * `REQUEST_REJECTED` - Request rejected
 * * `TRANSPORT_REJECTED` - Transport rejected
 * * `REQUEST_DURATION_EXTENDED` - Request duration extended
 * * `EMAIL_RESENT` -  Email resent
 */
export enum MessageEventEnum {
  NEW_MESSAGE = "NEW_MESSAGE",
  BIDDING_FINISHED = "BIDDING_FINISHED",
  NEW_BID = "NEW_BID",
  BID_SELECTED = "BID_SELECTED",
  DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
  TRANSPORT_CONFIRMED = "TRANSPORT_CONFIRMED",
  REQUEST_REJECTED = "REQUEST_REJECTED",
  TRANSPORT_REJECTED = "TRANSPORT_REJECTED",
  REQUEST_DURATION_EXTENDED = "REQUEST_DURATION_EXTENDED",
  EMAIL_RESENT = "EMAIL_RESENT",
}

/**
 * * `human` - Human
 * * `system` - System
 */
export enum MessageTypeEnum {
  Human = "human",
  System = "system",
}

export interface MessagesListParams {
  carrier?: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface ModifyInvitationByUser {
  workspaceId: number;
}

export interface Notification {
  actor?: number | null;
  /** @format date-time */
  readonly created: string;
  description: string;
  details?: string;
  readonly displayId: string;
  /**
   * * `CONSENT_REQUEST` - We require your consent to send you emails about our services
   * * `TERMS_REQUIRED_INFORM` - Please Accept Our Terms and Conditions
   * * `CARRIER_CREATED` - You were added to our carriers mailing list
   * * `CARRIER_REMOVED_FROM_ONGOING_AUCTION` - RFQ  %(request_id)s | You were removed
   * * `CARRIER_NOTE_MODIFIED` - RFQ %(request_id)s | Note changed by the shipper
   * * `CARRIER_UNREAD_MESSAGES` - RFQ  %(request_id)s | Unread Messages from %(shipper)s
   * * `AUCTION_PUBLISHED` - RFQ %(request_id)s | Transport requested
   * * `AUCTION_CONTRACTED_PUBLISHED` - RFQ %(request_id)s | Transport requested
   * * `AUCTION_INSTANT_ORDER_CONFIRMED` - RFQ %(request_id)s | Transport order from Bidding InstaOrder - payment confirmed
   * * `AUCTION_INSTANT_ORDER_CREATED` - RFQ %(request_id)s | Transport order from Bidding InstaOrder - acceptation needed
   * * `AUCTION_INSTANT_ORDER_REVERTED` - RFQ %(request_id)s | InstaOrder Transport Canceled
   * * `AUCTION_DURATION_EXTENDED` - RFQ %(request_id)s | Request duration extended
   * * `AUCTION_CANCELED` - RFQ %(request_id)s | Request canceled
   * * `AUCTION_UPDATED_BEFORE_BIDDING` - RFQ %(request_id)s | Request modified
   * * `BID_CONFIRMED_BY_SHIPPER` - Congratulations, you have won this request! | RFQ %(request_id)s
   * * `CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER` - Your client has confirmed your price offer | RFQ %(request_id)s
   * * `BID_NOT_CHOSEN` - RFQ %(request_id)s | You were not selected
   * * `TRANSPORT_ADDITIONAL_CHANGES` - RFQ %(request_id)s | Transport modified
   * * `TRANSPORT_CANCELED` - RFQ %(request_id)s | Transport canceled
   * * `TRANSPORT_MODIFIED` - RFQ %(request_id)s | Transport modified
   * * `BILLING_DETAILS_APPROVED` - Billing details approved
   * * `BILLING_DETAILS_REJECTED` - Billing details rejected
   * * `DOCUMENT_UPLOADED` - RFQ %(request_id)s | Document added
   * * `NEW_AUCTION_BID` - New bid from %(carrier)s
   * * `CONTRACTED_PRICE_PROVIDED` - Contracted price provided by %(carrier)s
   * * `CARRIER_CONFIRMED_BID` - Carrier %(carrier)s confirmed Request %(formatted_request_id)s
   * * `CARRIER_SUBSCRIBED_TO_EMAILS` - Carrier %(target)s subscribed
   * * `CARRIER_UNSUBSCRIBED_FROM_EMAILS` - Carrier %(target)s unsubscribed
   * * `CARRIER_REJECTED_BID` - Carrier %(carrier)s rejected request
   * * `CARRIER_REJECTED_CONTRACTED_PRICE` - Carrier %(carrier)s rejected contracted transport
   * * `CARRIER_REJECTED_AUCTION` - Carrier %(carrier)s rejected participation
   * * `CARRIER_REJECTED_CONTRACTED_TRANSPORT` - Carrier %(carrier)s rejected contracted transport
   * * `TRANSPORT_DETAILS_UPDATED` - Transport data has been changed
   * * `RESPONSIBLE_PERSON_CHANGED` - You were assigned a new Request %(formatted_request_id)s
   * * `TRANSPORT_RESPONSIBLE_PERSON_CHANGED` - You were assigned a new Transport %(formatted_request_id)s
   * * `CARRIER_UPLOADED_DOCUMENT` - Document added for Request %(formatted_request_id)s
   * * `CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT` - Document added for Transport %(formatted_request_id)s
   */
  event: NotificationEventEnum;
  readonly id: number;
  /** Read */
  isRead?: boolean;
  /** @format date-time */
  readonly modified: string;
  objectContentType: number;
  /**
   * @min 0
   * @max 2147483647
   */
  objectId?: number | null;
  recipient: number;
  shortDescription: string;
  targetContentType: number;
  /**
   * @min 0
   * @max 2147483647
   */
  targetId?: number | null;
  readonly workspace: WorkspaceGeneralInfo;
}

export interface NotificationEvent {
  readonly description: string;
  isEnabled: boolean;
  /**
   * * `CONSENT_REQUEST` - CONSENT_REQUEST
   * * `TERMS_REQUIRED_INFORM` - TERMS_REQUIRED_INFORM
   * * `CARRIER_CREATED` - CARRIER_CREATED
   * * `CARRIER_REMOVED_FROM_ONGOING_AUCTION` - CARRIER_REMOVED_FROM_ONGOING_AUCTION
   * * `CARRIER_NOTE_MODIFIED` - CARRIER_NOTE_MODIFIED
   * * `CARRIER_UNREAD_MESSAGES` - CARRIER_UNREAD_MESSAGES
   * * `AUCTION_PUBLISHED` - AUCTION_PUBLISHED
   * * `AUCTION_CONTRACTED_PUBLISHED` - AUCTION_CONTRACTED_PUBLISHED
   * * `AUCTION_INSTANT_ORDER_CONFIRMED` - AUCTION_INSTANT_ORDER_CONFIRMED
   * * `AUCTION_INSTANT_ORDER_CREATED` - AUCTION_INSTANT_ORDER_CREATED
   * * `AUCTION_INSTANT_ORDER_REVERTED` - AUCTION_INSTANT_ORDER_REVERTED
   * * `AUCTION_DURATION_EXTENDED` - AUCTION_DURATION_EXTENDED
   * * `AUCTION_CANCELED` - AUCTION_CANCELED
   * * `AUCTION_UPDATED_BEFORE_BIDDING` - AUCTION_UPDATED_BEFORE_BIDDING
   * * `BID_CONFIRMED_BY_SHIPPER` - BID_CONFIRMED_BY_SHIPPER
   * * `CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER` - CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER
   * * `BID_NOT_CHOSEN` - BID_NOT_CHOSEN
   * * `TRANSPORT_ADDITIONAL_CHANGES` - TRANSPORT_ADDITIONAL_CHANGES
   * * `TRANSPORT_CANCELED` - TRANSPORT_CANCELED
   * * `TRANSPORT_MODIFIED` - TRANSPORT_MODIFIED
   * * `BILLING_DETAILS_APPROVED` - BILLING_DETAILS_APPROVED
   * * `BILLING_DETAILS_REJECTED` - BILLING_DETAILS_REJECTED
   * * `DOCUMENT_UPLOADED` - DOCUMENT_UPLOADED
   * * `NEW_AUCTION_BID` - NEW_AUCTION_BID
   * * `CONTRACTED_PRICE_PROVIDED` - CONTRACTED_PRICE_PROVIDED
   * * `CARRIER_CONFIRMED_BID` - CARRIER_CONFIRMED_BID
   * * `CARRIER_SUBSCRIBED_TO_EMAILS` - CARRIER_SUBSCRIBED_TO_EMAILS
   * * `CARRIER_UNSUBSCRIBED_FROM_EMAILS` - CARRIER_UNSUBSCRIBED_FROM_EMAILS
   * * `CARRIER_REJECTED_BID` - CARRIER_REJECTED_BID
   * * `CARRIER_REJECTED_CONTRACTED_PRICE` - CARRIER_REJECTED_CONTRACTED_PRICE
   * * `CARRIER_REJECTED_AUCTION` - CARRIER_REJECTED_AUCTION
   * * `CARRIER_REJECTED_CONTRACTED_TRANSPORT` - CARRIER_REJECTED_CONTRACTED_TRANSPORT
   * * `TRANSPORT_DETAILS_UPDATED` - TRANSPORT_DETAILS_UPDATED
   * * `RESPONSIBLE_PERSON_CHANGED` - RESPONSIBLE_PERSON_CHANGED
   * * `TRANSPORT_RESPONSIBLE_PERSON_CHANGED` - TRANSPORT_RESPONSIBLE_PERSON_CHANGED
   * * `CARRIER_UPLOADED_DOCUMENT` - CARRIER_UPLOADED_DOCUMENT
   * * `CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT` - CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT
   */
  readonly name: NotificationEventNameEnum;
}

/**
 * * `CONSENT_REQUEST` - We require your consent to send you emails about our services
 * * `TERMS_REQUIRED_INFORM` - Please Accept Our Terms and Conditions
 * * `CARRIER_CREATED` - You were added to our carriers mailing list
 * * `CARRIER_REMOVED_FROM_ONGOING_AUCTION` - RFQ  %(request_id)s | You were removed
 * * `CARRIER_NOTE_MODIFIED` - RFQ %(request_id)s | Note changed by the shipper
 * * `CARRIER_UNREAD_MESSAGES` - RFQ  %(request_id)s | Unread Messages from %(shipper)s
 * * `AUCTION_PUBLISHED` - RFQ %(request_id)s | Transport requested
 * * `AUCTION_CONTRACTED_PUBLISHED` - RFQ %(request_id)s | Transport requested
 * * `AUCTION_INSTANT_ORDER_CONFIRMED` - RFQ %(request_id)s | Transport order from Bidding InstaOrder - payment confirmed
 * * `AUCTION_INSTANT_ORDER_CREATED` - RFQ %(request_id)s | Transport order from Bidding InstaOrder - acceptation needed
 * * `AUCTION_INSTANT_ORDER_REVERTED` - RFQ %(request_id)s | InstaOrder Transport Canceled
 * * `AUCTION_DURATION_EXTENDED` - RFQ %(request_id)s | Request duration extended
 * * `AUCTION_CANCELED` - RFQ %(request_id)s | Request canceled
 * * `AUCTION_UPDATED_BEFORE_BIDDING` - RFQ %(request_id)s | Request modified
 * * `BID_CONFIRMED_BY_SHIPPER` - Congratulations, you have won this request! | RFQ %(request_id)s
 * * `CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER` - Your client has confirmed your price offer | RFQ %(request_id)s
 * * `BID_NOT_CHOSEN` - RFQ %(request_id)s | You were not selected
 * * `TRANSPORT_ADDITIONAL_CHANGES` - RFQ %(request_id)s | Transport modified
 * * `TRANSPORT_CANCELED` - RFQ %(request_id)s | Transport canceled
 * * `TRANSPORT_MODIFIED` - RFQ %(request_id)s | Transport modified
 * * `BILLING_DETAILS_APPROVED` - Billing details approved
 * * `BILLING_DETAILS_REJECTED` - Billing details rejected
 * * `DOCUMENT_UPLOADED` - RFQ %(request_id)s | Document added
 * * `NEW_AUCTION_BID` - New bid from %(carrier)s
 * * `CONTRACTED_PRICE_PROVIDED` - Contracted price provided by %(carrier)s
 * * `CARRIER_CONFIRMED_BID` - Carrier %(carrier)s confirmed Request %(formatted_request_id)s
 * * `CARRIER_SUBSCRIBED_TO_EMAILS` - Carrier %(target)s subscribed
 * * `CARRIER_UNSUBSCRIBED_FROM_EMAILS` - Carrier %(target)s unsubscribed
 * * `CARRIER_REJECTED_BID` - Carrier %(carrier)s rejected request
 * * `CARRIER_REJECTED_CONTRACTED_PRICE` - Carrier %(carrier)s rejected contracted transport
 * * `CARRIER_REJECTED_AUCTION` - Carrier %(carrier)s rejected participation
 * * `CARRIER_REJECTED_CONTRACTED_TRANSPORT` - Carrier %(carrier)s rejected contracted transport
 * * `TRANSPORT_DETAILS_UPDATED` - Transport data has been changed
 * * `RESPONSIBLE_PERSON_CHANGED` - You were assigned a new Request %(formatted_request_id)s
 * * `TRANSPORT_RESPONSIBLE_PERSON_CHANGED` - You were assigned a new Transport %(formatted_request_id)s
 * * `CARRIER_UPLOADED_DOCUMENT` - Document added for Request %(formatted_request_id)s
 * * `CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT` - Document added for Transport %(formatted_request_id)s
 */
export enum NotificationEventEnum {
  CONSENT_REQUEST = "CONSENT_REQUEST",
  TERMS_REQUIRED_INFORM = "TERMS_REQUIRED_INFORM",
  CARRIER_CREATED = "CARRIER_CREATED",
  CARRIER_REMOVED_FROM_ONGOING_AUCTION = "CARRIER_REMOVED_FROM_ONGOING_AUCTION",
  CARRIER_NOTE_MODIFIED = "CARRIER_NOTE_MODIFIED",
  CARRIER_UNREAD_MESSAGES = "CARRIER_UNREAD_MESSAGES",
  AUCTION_PUBLISHED = "AUCTION_PUBLISHED",
  AUCTION_CONTRACTED_PUBLISHED = "AUCTION_CONTRACTED_PUBLISHED",
  AUCTION_INSTANT_ORDER_CONFIRMED = "AUCTION_INSTANT_ORDER_CONFIRMED",
  AUCTION_INSTANT_ORDER_CREATED = "AUCTION_INSTANT_ORDER_CREATED",
  AUCTION_INSTANT_ORDER_REVERTED = "AUCTION_INSTANT_ORDER_REVERTED",
  AUCTION_DURATION_EXTENDED = "AUCTION_DURATION_EXTENDED",
  AUCTION_CANCELED = "AUCTION_CANCELED",
  AUCTION_UPDATED_BEFORE_BIDDING = "AUCTION_UPDATED_BEFORE_BIDDING",
  BID_CONFIRMED_BY_SHIPPER = "BID_CONFIRMED_BY_SHIPPER",
  CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER = "CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER",
  BID_NOT_CHOSEN = "BID_NOT_CHOSEN",
  TRANSPORT_ADDITIONAL_CHANGES = "TRANSPORT_ADDITIONAL_CHANGES",
  TRANSPORT_CANCELED = "TRANSPORT_CANCELED",
  TRANSPORT_MODIFIED = "TRANSPORT_MODIFIED",
  BILLING_DETAILS_APPROVED = "BILLING_DETAILS_APPROVED",
  BILLING_DETAILS_REJECTED = "BILLING_DETAILS_REJECTED",
  DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
  NEW_AUCTION_BID = "NEW_AUCTION_BID",
  CONTRACTED_PRICE_PROVIDED = "CONTRACTED_PRICE_PROVIDED",
  CARRIER_CONFIRMED_BID = "CARRIER_CONFIRMED_BID",
  CARRIER_SUBSCRIBED_TO_EMAILS = "CARRIER_SUBSCRIBED_TO_EMAILS",
  CARRIER_UNSUBSCRIBED_FROM_EMAILS = "CARRIER_UNSUBSCRIBED_FROM_EMAILS",
  CARRIER_REJECTED_BID = "CARRIER_REJECTED_BID",
  CARRIER_REJECTED_CONTRACTED_PRICE = "CARRIER_REJECTED_CONTRACTED_PRICE",
  CARRIER_REJECTED_AUCTION = "CARRIER_REJECTED_AUCTION",
  CARRIER_REJECTED_CONTRACTED_TRANSPORT = "CARRIER_REJECTED_CONTRACTED_TRANSPORT",
  TRANSPORT_DETAILS_UPDATED = "TRANSPORT_DETAILS_UPDATED",
  RESPONSIBLE_PERSON_CHANGED = "RESPONSIBLE_PERSON_CHANGED",
  TRANSPORT_RESPONSIBLE_PERSON_CHANGED = "TRANSPORT_RESPONSIBLE_PERSON_CHANGED",
  CARRIER_UPLOADED_DOCUMENT = "CARRIER_UPLOADED_DOCUMENT",
  CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT = "CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT",
}

/**
 * * `CONSENT_REQUEST` - CONSENT_REQUEST
 * * `TERMS_REQUIRED_INFORM` - TERMS_REQUIRED_INFORM
 * * `CARRIER_CREATED` - CARRIER_CREATED
 * * `CARRIER_REMOVED_FROM_ONGOING_AUCTION` - CARRIER_REMOVED_FROM_ONGOING_AUCTION
 * * `CARRIER_NOTE_MODIFIED` - CARRIER_NOTE_MODIFIED
 * * `CARRIER_UNREAD_MESSAGES` - CARRIER_UNREAD_MESSAGES
 * * `AUCTION_PUBLISHED` - AUCTION_PUBLISHED
 * * `AUCTION_CONTRACTED_PUBLISHED` - AUCTION_CONTRACTED_PUBLISHED
 * * `AUCTION_INSTANT_ORDER_CONFIRMED` - AUCTION_INSTANT_ORDER_CONFIRMED
 * * `AUCTION_INSTANT_ORDER_CREATED` - AUCTION_INSTANT_ORDER_CREATED
 * * `AUCTION_INSTANT_ORDER_REVERTED` - AUCTION_INSTANT_ORDER_REVERTED
 * * `AUCTION_DURATION_EXTENDED` - AUCTION_DURATION_EXTENDED
 * * `AUCTION_CANCELED` - AUCTION_CANCELED
 * * `AUCTION_UPDATED_BEFORE_BIDDING` - AUCTION_UPDATED_BEFORE_BIDDING
 * * `BID_CONFIRMED_BY_SHIPPER` - BID_CONFIRMED_BY_SHIPPER
 * * `CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER` - CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER
 * * `BID_NOT_CHOSEN` - BID_NOT_CHOSEN
 * * `TRANSPORT_ADDITIONAL_CHANGES` - TRANSPORT_ADDITIONAL_CHANGES
 * * `TRANSPORT_CANCELED` - TRANSPORT_CANCELED
 * * `TRANSPORT_MODIFIED` - TRANSPORT_MODIFIED
 * * `BILLING_DETAILS_APPROVED` - BILLING_DETAILS_APPROVED
 * * `BILLING_DETAILS_REJECTED` - BILLING_DETAILS_REJECTED
 * * `DOCUMENT_UPLOADED` - DOCUMENT_UPLOADED
 * * `NEW_AUCTION_BID` - NEW_AUCTION_BID
 * * `CONTRACTED_PRICE_PROVIDED` - CONTRACTED_PRICE_PROVIDED
 * * `CARRIER_CONFIRMED_BID` - CARRIER_CONFIRMED_BID
 * * `CARRIER_SUBSCRIBED_TO_EMAILS` - CARRIER_SUBSCRIBED_TO_EMAILS
 * * `CARRIER_UNSUBSCRIBED_FROM_EMAILS` - CARRIER_UNSUBSCRIBED_FROM_EMAILS
 * * `CARRIER_REJECTED_BID` - CARRIER_REJECTED_BID
 * * `CARRIER_REJECTED_CONTRACTED_PRICE` - CARRIER_REJECTED_CONTRACTED_PRICE
 * * `CARRIER_REJECTED_AUCTION` - CARRIER_REJECTED_AUCTION
 * * `CARRIER_REJECTED_CONTRACTED_TRANSPORT` - CARRIER_REJECTED_CONTRACTED_TRANSPORT
 * * `TRANSPORT_DETAILS_UPDATED` - TRANSPORT_DETAILS_UPDATED
 * * `RESPONSIBLE_PERSON_CHANGED` - RESPONSIBLE_PERSON_CHANGED
 * * `TRANSPORT_RESPONSIBLE_PERSON_CHANGED` - TRANSPORT_RESPONSIBLE_PERSON_CHANGED
 * * `CARRIER_UPLOADED_DOCUMENT` - CARRIER_UPLOADED_DOCUMENT
 * * `CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT` - CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT
 */
export enum NotificationEventNameEnum {
  CONSENT_REQUEST = "CONSENT_REQUEST",
  TERMS_REQUIRED_INFORM = "TERMS_REQUIRED_INFORM",
  CARRIER_CREATED = "CARRIER_CREATED",
  CARRIER_REMOVED_FROM_ONGOING_AUCTION = "CARRIER_REMOVED_FROM_ONGOING_AUCTION",
  CARRIER_NOTE_MODIFIED = "CARRIER_NOTE_MODIFIED",
  CARRIER_UNREAD_MESSAGES = "CARRIER_UNREAD_MESSAGES",
  AUCTION_PUBLISHED = "AUCTION_PUBLISHED",
  AUCTION_CONTRACTED_PUBLISHED = "AUCTION_CONTRACTED_PUBLISHED",
  AUCTION_INSTANT_ORDER_CONFIRMED = "AUCTION_INSTANT_ORDER_CONFIRMED",
  AUCTION_INSTANT_ORDER_CREATED = "AUCTION_INSTANT_ORDER_CREATED",
  AUCTION_INSTANT_ORDER_REVERTED = "AUCTION_INSTANT_ORDER_REVERTED",
  AUCTION_DURATION_EXTENDED = "AUCTION_DURATION_EXTENDED",
  AUCTION_CANCELED = "AUCTION_CANCELED",
  AUCTION_UPDATED_BEFORE_BIDDING = "AUCTION_UPDATED_BEFORE_BIDDING",
  BID_CONFIRMED_BY_SHIPPER = "BID_CONFIRMED_BY_SHIPPER",
  CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER = "CONTRACTED_PRICE_CONFIRMED_BY_SHIPPER",
  BID_NOT_CHOSEN = "BID_NOT_CHOSEN",
  TRANSPORT_ADDITIONAL_CHANGES = "TRANSPORT_ADDITIONAL_CHANGES",
  TRANSPORT_CANCELED = "TRANSPORT_CANCELED",
  TRANSPORT_MODIFIED = "TRANSPORT_MODIFIED",
  BILLING_DETAILS_APPROVED = "BILLING_DETAILS_APPROVED",
  BILLING_DETAILS_REJECTED = "BILLING_DETAILS_REJECTED",
  DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
  NEW_AUCTION_BID = "NEW_AUCTION_BID",
  CONTRACTED_PRICE_PROVIDED = "CONTRACTED_PRICE_PROVIDED",
  CARRIER_CONFIRMED_BID = "CARRIER_CONFIRMED_BID",
  CARRIER_SUBSCRIBED_TO_EMAILS = "CARRIER_SUBSCRIBED_TO_EMAILS",
  CARRIER_UNSUBSCRIBED_FROM_EMAILS = "CARRIER_UNSUBSCRIBED_FROM_EMAILS",
  CARRIER_REJECTED_BID = "CARRIER_REJECTED_BID",
  CARRIER_REJECTED_CONTRACTED_PRICE = "CARRIER_REJECTED_CONTRACTED_PRICE",
  CARRIER_REJECTED_AUCTION = "CARRIER_REJECTED_AUCTION",
  CARRIER_REJECTED_CONTRACTED_TRANSPORT = "CARRIER_REJECTED_CONTRACTED_TRANSPORT",
  TRANSPORT_DETAILS_UPDATED = "TRANSPORT_DETAILS_UPDATED",
  RESPONSIBLE_PERSON_CHANGED = "RESPONSIBLE_PERSON_CHANGED",
  TRANSPORT_RESPONSIBLE_PERSON_CHANGED = "TRANSPORT_RESPONSIBLE_PERSON_CHANGED",
  CARRIER_UPLOADED_DOCUMENT = "CARRIER_UPLOADED_DOCUMENT",
  CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT = "CARRIER_UPLOADED_DOCUMENT_TO_TRANSPORT",
}

export interface NotificationsListParams {
  isRead?: boolean;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export enum OrderEnum {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

export enum OrderEnum1 {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

export enum OrderEnum2 {
  ValueCreatedAt = "-created_at",
  ValuePrice = "-price",
  CreatedAt = "created_at",
  Price = "price",
}

export enum OrderEnum3 {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

export enum OrderEnum4 {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

export enum OrderEnum5 {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

export interface PaginatedAddressList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: Address[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedAuctionListList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: AuctionList[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedBidByHashList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: BidByHash[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedCargoItemUnitList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: CargoItemUnit[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedCarrierBillingDetailsByHashInputList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: CarrierBillingDetailsByHashInput[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedCarrierGroupList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: CarrierGroup[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedCarrierList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: Carrier[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedDocumentList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: Document[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedMessageList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: Message[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedNotificationList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: Notification[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedTermsAndConditionsList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: TermsAndConditions[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedTransportListList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: TransportList[];
  /** @example 12 */
  totalPages?: number;
}

export interface PaginatedUserList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: User[];
}

export interface PaginatedUserMessageList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results: UserMessage[];
  /** @example 12 */
  totalPages?: number;
}

export interface PasswordChange {
  /** @maxLength 128 */
  new_password1: string;
  /** @maxLength 128 */
  new_password2: string;
}

/** Serializer for requesting a password reset e-mail. */
export interface PasswordReset {
  /** @format email */
  email: string;
}

/** Serializer for confirming a password reset attempt. */
export interface PasswordResetConfirm {
  /** @maxLength 128 */
  new_password1: string;
  /** @maxLength 128 */
  new_password2: string;
  token: string;
  uid: string;
}

export interface PatchedAddress {
  /** @maxLength 100 */
  city?: string;
  contacts?: AddressContact[];
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country?: CountryEnum;
  readonly createdBy?: UserGeneralInfo;
  readonly id?: number;
  /** @maxLength 50 */
  name?: string;
  /** @maxLength 20 */
  postCode?: string;
  /** @maxLength 100 */
  street?: string;
  readonly workspace?: WorkspaceGeneralInfo;
}

export interface PatchedAuctionUpdate {
  duration?: string;
  invitedParticipants?: number[];
}

export interface PatchedCarrier {
  address?: CompanyAddress | null;
  billingDetails?: CarrierBillingDetailsLimited;
  readonly billingDetailsLatest?: CarrierBillingDetails;
  readonly consentStatuses?: ConsentInfo[];
  contact?: Contact | null;
  groups?: CarrierGroup[];
  readonly id?: number;
  /** @maxLength 50 */
  internalId?: string;
  /**
   * Active
   * Designates whether this carrier should be treated as active. Unselect this instead of deleting carriers.
   */
  isActive?: boolean;
  readonly isDeleted?: boolean;
  /**
   * Title
   * @maxLength 100
   */
  name?: string;
  note?: string;
  /**
   * Language
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  preferredLanguage?: LanguageEnum;
  transportationModes?: TransportationModeEnum[];
  /**
   * @format uri
   * @maxLength 400
   */
  website?: string;
  readonly workspace?: WorkspaceGeneralInfo;
}

export interface PatchedCarrierByHash {
  readonly billingDetails?: CarrierByHashBillingDetails;
  readonly billingDetailsCandidate?: CarrierByHashBillingDetails;
  /** @maxLength 50 */
  internalId?: string;
  /**
   * Language
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  preferredLanguage?: LanguageEnum;
  readonly termsData?: CarrierByHashTermsData;
}

export interface PatchedCarrierGroup {
  readonly carriers?: CarrierGeneralInfo[];
  carriersIds?: number[];
  /** @format date-time */
  readonly createdAt?: string;
  readonly createdBy?: UserGeneralInfo;
  readonly id?: number;
  /** @maxLength 255 */
  name?: string;
  note?: string;
  /** @format date-time */
  readonly updatedAt?: string;
  readonly workspace?: WorkspaceGeneralInfo;
}

export interface PatchedCarrierUpdateIsActive {
  /**
   * Active
   * Designates whether this carrier should be treated as active. Unselect this instead of deleting carriers.
   */
  isActive?: boolean;
}

export interface PatchedDocumentUpdate {
  /**
   * * `ALL` - Shared with all
   * * `WINNER` - Shared with winner
   * * `PRIVATE` - Private (only shipper)
   */
  accessLevel?: DocumentAccessLevelEnum;
  title?: string;
}

export interface PatchedTransport {
  cargo?: Cargo;
  readonly carrier?: Carrier;
  /** @maxLength 500 */
  costCenter?: string;
  /** @format date-time */
  readonly createdAt?: string;
  readonly createdBy?: UserGeneralInfo;
  /** @format double */
  readonly distance?: number;
  readonly id?: number;
  note?: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  readonly price?: string;
  readonly priceCurrency?: string;
  /** @maxLength 150 */
  reference?: string;
  /** @format int32 */
  readonly requestId?: number;
  readonly responsibleUser?: UserGeneralInfo;
  responsibleUserId?: number | null;
  /**
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType?: RouteTypeEnum;
  /** @format uri */
  readonly staticMap?: string;
  /**
   * * `PROCESSING` - Processing
   * * `FINISHED` - Finished
   * * `CANCELED` - Canceled
   * * `CANCELED_BY_CARRIER` - Canceled by carrier
   */
  status?: TransportStatusEnum;
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode?: TransportationModeEnum;
  /**
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  readonly type?: TransportTypeEnum;
  waypoints?: Waypoint[];
  readonly winningBid?: Bid;
}

export interface PatchedTransportRequest {
  readonly auctions?: number[];
  availabilityRequired?: boolean;
  cargo?: Cargo;
  carriers?: number[];
  contractedPrice?: string | null;
  contractedPriceCurrency?: string | null;
  /** @maxLength 500 */
  costCenter?: string;
  /** @format date-time */
  readonly createdAt?: string;
  readonly createdBy?: UserGeneralInfo;
  /** @format double */
  readonly distance?: number;
  duration?: string;
  readonly id?: number;
  note?: string;
  /** @maxLength 150 */
  reference?: string;
  readonly responsibleUser?: UserGeneralInfo;
  responsibleUserId?: number | null;
  /**
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType?: RouteTypeEnum;
  /** @format uri */
  readonly staticMap?: string;
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode?: TransportationModeEnum;
  /**
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  type?: TransportTypeEnum;
  waypoints?: Waypoint[];
}

/** It's used for listing bids for user. Main difference is that shipper status could be updated. */
export interface PatchedUpdateBid {
  readonly carrier?: number;
  readonly carrierNote?: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  readonly carrierStatus?: BidStatusEnum;
  /** @format date-time */
  readonly carrierStatusChangedAt?: string;
  /** @format date-time */
  readonly createdAt?: string;
  readonly id?: number;
  readonly isConfirmedByShipper?: boolean;
  isInstantOrder?: boolean;
  readonly isRejected?: boolean;
  readonly isWinning?: boolean;
  metadata?: BidMetadata | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  offeredPrice?: string;
  readonly offeredPriceCurrency?: string;
  shipperNote?: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  shipperStatus?: BidStatusEnum;
  /** @format date-time */
  readonly shipperStatusChangedAt?: string;
  /** @format date-time */
  readonly winsAt?: string | null;
}

/** Separate serializer for carrier to update decision on bid. */
export interface PatchedUpdateBidByHash {
  readonly carrier?: number;
  carrierNote?: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  carrierStatus?: BidStatusEnum;
  /** You might already have a comparison value for a bid. It could be calculated in queryset. */
  readonly comparison?: BidComparisonEnum;
  /** @format date-time */
  readonly createdAt?: string;
  /** Hash key */
  readonly hash?: string;
  readonly id?: number;
  readonly isConfirmedByShipper?: boolean;
  readonly isRejected?: boolean;
  readonly isWinning?: boolean;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  offeredPrice?: string;
  readonly offeredPriceCurrency?: string;
  readonly shipperNote?: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  readonly shipperStatus?: BidStatusEnum;
}

export interface PatchedUser {
  readonly consentStatuses?: ConsentInfo[];
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 150 */
  firstName?: string;
  readonly id?: number;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  isActive?: boolean;
  /** @maxLength 150 */
  lastName?: string;
  readonly notificationSettings?: Record<string, Record<string, Record<string, boolean>>>;
  /** @maxLength 128 */
  phoneNumber?: string;
  readonly role?: number;
  roleId?: number;
  readonly workspace?: WorkspaceGeneralInfo;
}

/** User model w/o password */
export interface PatchedUserDetails {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  firstName?: string;
  readonly id?: number;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  isActive?: boolean;
  readonly isEmailVerified?: boolean;
  /** @maxLength 150 */
  lastName?: string;
  readonly members?: Member[];
  readonly notificationSettings?: Record<string, Record<string, Record<string, boolean>>>;
  readonly permissions?: string[];
  phoneNumber?: string;
  /**
   * Language
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  preferredLanguage?: LanguageEnum;
  readonly workspace?: Workspace;
  workspaceId?: number;
}

export interface PatchedWorkspaceUpdate {
  address?: CompanyAddress | null;
  billingDetails?: BillingDetailsLimited | null;
  contact?: Contact | null;
  /**
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency?: CurrencyEnum;
  /**
   * Email sender name
   * @maxLength 100
   */
  emailFromName?: string;
  /** Hash key */
  readonly hash?: string;
  readonly id?: number;
  /**
   * * `m` - m
   * * `cm` - cm
   * * `mm` - mm
   */
  lengthUnit?: LengthUnitEnum;
  name?: string;
  readonly packageData?: WorkspacePackageData | null;
  preferences?: WorkspacePreferences;
  /**
   * * `kg` - kg
   * * `t` - t
   */
  weightUnit?: WeightUnitEnum;
}

export interface PointField {
  /** @format double */
  lat: number;
  /** @format double */
  lng: number;
}

export interface PortalCompanyInfoResponse {
  banned: boolean;
  city: string;
  country: string;
  /** @format double */
  currentLimit: number;
  id: number;
  /** @format double */
  initialLimit: number;
  name: string;
  postcode: string;
  regId: string;
  status: string;
  street: string;
  taxId: string;
  type: string;
  vatId: string;
}

export interface PricerResult {
  readonly carrier: Carrier;
  expireAt: string;
  readonly expired: boolean;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  readonly price: string;
}

export type PrivacyConsentsCreatePayload = ConsentByTokenInput[];

export type PrivacyConsentsRequestResendCreateError = ConsentInfo;

export interface PrivacyConsentsRetrieveParams {
  consentTypes?: ConsentTypesEnum[];
  token: string;
}

export enum PrivacyConsentsRetrieveParams1ConsentTypesEnum {
  EMAIL = "EMAIL",
  PRICER_POPUP = "PRICER_POPUP",
  TEST_EVENT_CARRIER = "TEST_EVENT_CARRIER",
}

export type PrivacyConsentsSettingsCreatePayload = ConsentsSettings[];

export type PrivacyConsentsTermsCreatePayload = TermsConsentByTokenInput[];

export interface PrivacyTermsAndConditionsListParams {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
}

export interface RecaptchaVerifyResult {
  score: number;
  success: boolean;
}

export interface Reference {
  readonly displayId: string;
  readonly objectContentType: string;
  /**
   * @min 0
   * @max 2147483647
   */
  readonly objectId: number;
  readonly timelineId: string;
}

export interface Register {
  password1?: string;
  password2?: string;
  company: string;
  /** @format email */
  email: string;
  firstName: string;
  /**
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  language?: LanguageEnum;
  lastName?: string;
  marketingPurposes?: boolean;
  phoneNumber: string;
  url?: string;
  /**
   * @minLength 1
   * @maxLength 0
   */
  username?: string;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum RequestTypeEnum {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum RequestTypeEnum1 {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum RequestTypeEnum2 {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum RequestTypeEnum3 {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum RequestTypeEnum4 {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

export interface ResendConsentRequestInput {
  carrierId: number;
  /**
   * * `EMAIL` - Email
   * * `PRICER_POPUP` - Pricer popup
   * * `TEST_EVENT_CARRIER` - Test event carrier
   * @default "EMAIL"
   */
  consentType?: ResendConsentRequestInputConsentTypeEnum;
}

/**
 * * `EMAIL` - Email
 * * `PRICER_POPUP` - Pricer popup
 * * `TEST_EVENT_CARRIER` - Test event carrier
 * @default "EMAIL"
 */
export enum ResendConsentRequestInputConsentTypeEnum {
  EMAIL = "EMAIL",
  PRICER_POPUP = "PRICER_POPUP",
  TEST_EVENT_CARRIER = "TEST_EVENT_CARRIER",
}

export interface ResendEmailVerification {
  /** @format email */
  email: string;
}

export interface RestAuthDetail {
  readonly detail: string;
}

export interface Role {
  readonly description: string;
  readonly id: number;
  readonly isDefault: boolean;
  readonly title: string;
}

export interface RolesListParams {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
}

/**
 * Route type
 *
 * * `ONE_WAY` - One way
 * * `ROUND_TRIP` - Round trip
 */
export enum RouteTypeEnum {
  ONE_WAY = "ONE_WAY",
  ROUND_TRIP = "ROUND_TRIP",
}

export interface SchemaRetrieveParams {
  format?: FormatEnum;
  lang?: LangEnum;
}

export enum SchemaRetrieveParams1FormatEnum {
  Json = "json",
  Yaml = "yaml",
}

export enum SchemaRetrieveParams1LangEnum {
  Cs = "cs",
  De = "de",
  En = "en",
  Fr = "fr",
  It = "it",
  Sk = "sk",
}

export interface Stats {
  readonly thisMonth: number;
  readonly total: number;
}

export interface StatsTopCarriersRetrieveParams {
  count?: number;
  /** @format date */
  createdAtAfter?: string;
  /** @format date */
  createdAtBefore?: string;
}

export interface StatsTopCountriesListParams {
  count?: number;
  /** @format date */
  createdAtAfter?: string;
  /** @format date */
  createdAtBefore?: string;
  waypointStatsType?: WaypointStatsTypeEnum;
}

export enum StatsTopCountriesListParams1WaypointStatsTypeEnum {
  DELIVERY = "DELIVERY",
  PICKUP = "PICKUP",
  PICKUP_AND_DELIVERY = "PICKUP_AND_DELIVERY",
}

export interface Subscriber {
  /** @format email */
  email: string;
  /**
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  language?: LanguageEnum;
  marketingPurposes?: boolean;
  utmData?: UtmData;
}

export interface TermsAndConditions {
  /** @format uri */
  file: string;
  readonly id: number;
  provider: TermsAndConditionsProvider;
  /** @format date-time */
  publishedAt: string;
  /** @format date-time */
  requiredFrom: string;
  /**
   * @min 0
   * @max 32767
   * @default 1
   */
  versionNumber?: number;
}

export interface TermsAndConditionsConsent {
  readonly isAccepted: boolean;
  readonly isRejected: boolean;
}

export interface TermsAndConditionsProvider {
  /** 3rd party provider */
  is3rdParty?: boolean;
  readonly id: number;
  /**
   * Provider info
   * Additional information about provider
   */
  info?: string;
  /** @maxLength 255 */
  name: string;
}

export interface TermsByTokenOutput {
  consents: TermsOutput[];
  /** @format email */
  email: string;
}

export interface TermsConsentByTokenInput {
  accepted?: boolean;
  rejected?: boolean;
  termsId: number;
}

export interface TermsOutput {
  consent?: TermsAndConditionsConsent;
  terms: TermsAndConditions;
}

export interface TokenRefresh {
  readonly access: string;
  refresh: string;
}

export interface TokenVerify {
  token: string;
}

export interface TopCarriersStats {
  carriers: CarriersStats[];
  readonly totalCount: number;
  /** @format double */
  readonly totalDistance: number;
}

export interface TopCountriesStats {
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country: CountryEnum;
  readonly deliveryCount: number;
  readonly pickupCount: number;
}

export interface TranslateLocations {
  addresses: LocationAddress[];
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode: TransportationModeEnum;
  waypointTypes?: WaypointTypeEnum[];
}

export interface Transport {
  cargo: Cargo;
  readonly carrier: Carrier;
  /** @maxLength 500 */
  costCenter?: string;
  /** @format date-time */
  readonly createdAt: string;
  readonly createdBy: UserGeneralInfo;
  /** @format double */
  readonly distance: number;
  readonly id: number;
  note?: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  readonly price: string;
  readonly priceCurrency: string;
  /** @maxLength 150 */
  reference?: string;
  /** @format int32 */
  readonly requestId: number;
  readonly responsibleUser: UserGeneralInfo;
  responsibleUserId?: number | null;
  /**
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType: RouteTypeEnum;
  /** @format uri */
  readonly staticMap: string;
  /**
   * * `PROCESSING` - Processing
   * * `FINISHED` - Finished
   * * `CANCELED` - Canceled
   * * `CANCELED_BY_CARRIER` - Canceled by carrier
   */
  status?: TransportStatusEnum;
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode: TransportationModeEnum;
  /**
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  readonly type: TransportTypeEnum;
  waypoints: Waypoint[];
  readonly winningBid: Bid;
}

export interface TransportByHash {
  readonly cargo: Cargo;
  /** @format date-time */
  readonly deletedAt: string | null;
  readonly id: number;
  readonly locationData: LocationData;
  /** @maxLength 150 */
  readonly reference: string;
  readonly responsibleUser: UserGeneralInfo;
  /**
   * * `PROCESSING` - Processing
   * * `FINISHED` - Finished
   * * `CANCELED` - Canceled
   * * `CANCELED_BY_CARRIER` - Canceled by carrier
   */
  readonly status: TransportStatusEnum;
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  readonly transportationMode: TransportationModeEnum;
  readonly waypoints: Waypoint[];
}

export interface TransportDetail {
  /** @maxLength 255 */
  carrierNote?: string;
  shipperExtraInfo?: string;
  readonly transport: number;
  vehicles: TransportDetailVehicle[];
}

export interface TransportDetailByHash {
  /** @maxLength 255 */
  carrierNote?: string;
  readonly id: number;
  readonly shipperExtraInfo: string;
  vehicles: TransportDetailVehicleByHash[];
}

export interface TransportDetailVehicle {
  /** @maxLength 255 */
  driverName?: string;
  /** @maxLength 128 */
  driverPhone?: string;
  /** @maxLength 255 */
  plateNumber?: string;
  /** @maxLength 255 */
  trackingLink?: string;
}

export interface TransportDetailVehicleByHash {
  /** @maxLength 255 */
  driverName?: string;
  /** @maxLength 128 */
  driverPhone?: string;
  /** @maxLength 255 */
  plateNumber?: string;
  /** @maxLength 255 */
  trackingLink?: string;
}

export interface TransportDocumentValidation {
  /** @max 10485760 */
  fileSize: number;
  /** @maxLength 200 */
  title: string;
}

export interface TransportList {
  cargo: Cargo;
  carrier: Carrier;
  /** @format date-time */
  readonly createdAt: string;
  /** @format double */
  readonly distance: number;
  readonly id: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  price: string;
  readonly priceCurrency: string;
  /** @maxLength 150 */
  reference?: string;
  /** @format int32 */
  readonly requestId: number;
  readonly responsibleUser: UserGeneralInfo;
  /**
   * * `PROCESSING` - Processing
   * * `FINISHED` - Finished
   * * `CANCELED` - Canceled
   * * `CANCELED_BY_CARRIER` - Canceled by carrier
   */
  status?: TransportStatusEnum;
  transportDetail?: TransportDetail;
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode: TransportationModeEnum;
  /**
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  type: TransportTypeEnum;
  waypoints: Waypoint[];
}

export interface TransportOrTransportRequestFirstStep {
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode: TransportationModeEnum;
  waypoints: Waypoint[];
}

export interface TransportOrTransportRequestSecondStep {
  cargo: Cargo;
  /** @maxLength 500 */
  costCenter?: string;
  /** @maxLength 150 */
  reference?: string;
}

export interface TransportOverviewStats {
  /** @format double */
  averagePrice: number;
  /** @format double */
  averagePricePerKm: number | null;
  /** @format double */
  averageSavings: number;
  /** @format double */
  averageSavingsPercent: number;
  countByMonth: CountByMonthStats[];
  currencies: CurrencyEnum[];
  /**
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency: CurrencyEnum;
  /** @format double */
  totalPrice: number;
}

export interface TransportRequest {
  readonly auctions: number[];
  availabilityRequired?: boolean;
  cargo: Cargo;
  carriers: number[];
  contractedPrice?: string | null;
  contractedPriceCurrency?: string | null;
  /** @maxLength 500 */
  costCenter?: string;
  /** @format date-time */
  readonly createdAt: string;
  readonly createdBy: UserGeneralInfo;
  /** @format double */
  readonly distance: number;
  duration: string;
  readonly id: number;
  note?: string;
  /** @maxLength 150 */
  reference?: string;
  readonly responsibleUser: UserGeneralInfo;
  responsibleUserId?: number | null;
  /**
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType: RouteTypeEnum;
  /** @format uri */
  readonly staticMap: string;
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode: TransportationModeEnum;
  /**
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  type?: TransportTypeEnum;
  waypoints: Waypoint[];
}

export interface TransportRequestByHash {
  readonly cargo: Cargo;
  /** @format date-time */
  readonly deletedAt: string | null;
  readonly locationData: LocationData;
  readonly note: string;
  readonly reference: string | null;
  readonly responsibleUser: UserGeneralInfo;
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  readonly transportationMode: TransportationModeEnum;
  /**
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  readonly type: TransportTypeEnum;
  readonly waypoints: WaypointByHash[];
}

export interface TransportRequestList {
  readonly cargo: Cargo;
  readonly createdBy: UserGeneralInfo;
  readonly distance: number;
  readonly id: number;
  readonly reference: string;
  readonly responsibleUser: UserGeneralInfo;
  /**
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode: TransportationModeEnum;
  /**
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  type: TransportTypeEnum;
  waypoints: Waypoint[];
}

export interface TransportRequestThirdStep {
  carriers: number[];
  documents?: TransportDocumentValidation[];
  duration: string;
  note?: string;
  responsibleUser?: number | null;
}

export interface TransportStatsOverviewRetrieveParams {
  /** Auction ID */
  auctionId?: number;
  carrier?: string;
  costCenter?: string;
  /** @format date-time */
  createdAtAfter?: string;
  /** @format date-time */
  createdAtBefore?: string;
  createdBy?: number;
  /**
   * Currency
   *
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency?: CurrencyEnum;
  deliveryCity?: string;
  deliveryCountry?: string;
  /** @format date-time */
  deliveryDateAfter?: string;
  /** @format date-time */
  deliveryDateBefore?: string;
  deliveryName?: string;
  deliveryPostCode?: string;
  deliveryStreet?: string;
  driverName?: string;
  /**
   * Load type
   *
   * * `FTL` - Full Truck Load
   * * `LTL` - Less than Truck Load
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   * * `PARCEL` - Parcel
   * * `NOT_SPECIFIED` - Not specified
   */
  loadType?: CargoLoadTypeEnum[];
  maxWeightKg?: number;
  minWeightKg?: number;
  /**
   * Ordering
   *
   * * `created_at` - Created at
   * * `-created_at` - Created at (descending)
   */
  order?: OrderEnum4[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  pickupCity?: string;
  pickupCountry?: string;
  /** @format date-time */
  pickupDateAfter?: string;
  /** @format date-time */
  pickupDateBefore?: string;
  pickupName?: string;
  pickupPostCode?: string;
  pickupStreet?: string;
  plateNumber?: string;
  /** @format date-time */
  publishedAtAfter?: string;
  /** @format date-time */
  publishedAtBefore?: string;
  reference?: string;
  /**
   * Request type
   *
   * * `FROZEN_GOODS` - Frozen goods
   * * `OVERSIZED_LOAD` - Oversized load
   * * `BULK_MATERIAL` - Bulk material
   * * `LIQUIDS` - Liquids
   * * `VEHICLES` - Vehicles
   * * `ADR` - ADR
   * * `OTHER` - Other
   * * `NOT_SPECIFIED` - Not specified
   */
  requestType?: RequestTypeEnum3;
  responsibleUser?: number;
  /**
   * Route type
   *
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType?: RouteTypeEnum;
  /** A search term. */
  search?: string;
  /**
   * Transport status
   *
   * * `PROCESSING` - Processing
   * * `FINISHED` - Finished
   * * `CANCELED` - Canceled
   * * `CANCELED_BY_CARRIER` - Canceled by carrier
   */
  transportStatus?: TransportStatusEnum;
  /**
   * Transport type
   *
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  transportType?: TransportTypeEnum;
  /**
   * Transportation mode
   *
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode?: TransportationModeEnum;
}

export enum TransportStatsOverviewRetrieveParams1OrderEnum {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum TransportStatsOverviewRetrieveParams1RequestTypeEnum {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

/**
 * * `PROCESSING` - Processing
 * * `FINISHED` - Finished
 * * `CANCELED` - Canceled
 * * `CANCELED_BY_CARRIER` - Canceled by carrier
 */
export enum TransportStatusEnum {
  PROCESSING = "PROCESSING",
  FINISHED = "FINISHED",
  CANCELED = "CANCELED",
  CANCELED_BY_CARRIER = "CANCELED_BY_CARRIER",
}

export interface TransportThirdStep {
  note?: string;
  responsibleUser?: number | null;
}

/**
 * * `SPOT` - Spot transport
 * * `CONTRACTED` - Contracted transport
 */
export enum TransportTypeEnum {
  SPOT = "SPOT",
  CONTRACTED = "CONTRACTED",
}

export interface TransportUpdateStatus {
  /**
   * * `PROCESSING` - Processing
   * * `FINISHED` - Finished
   * * `CANCELED` - Canceled
   * * `CANCELED_BY_CARRIER` - Canceled by carrier
   */
  status: TransportStatusEnum;
}

/**
 * Transportation mode
 *
 * * `ROAD` - Road
 * * `AIR` - Air
 * * `SEA` - Sea
 * * `RAIL` - Rail
 * * `INTERMODAL` - Intermodal
 */
export enum TransportationModeEnum {
  AIR = "AIR",
  INTERMODAL = "INTERMODAL",
  RAIL = "RAIL",
  ROAD = "ROAD",
  SEA = "SEA",
}

export interface TransportsDocumentsListParams {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
  transportPk: number;
}

export interface TransportsListParams {
  /** Auction ID */
  auctionId?: number;
  carrier?: string;
  costCenter?: string;
  /** @format date-time */
  createdAtAfter?: string;
  /** @format date-time */
  createdAtBefore?: string;
  createdBy?: number;
  /**
   * Currency
   *
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency?: CurrencyEnum;
  deliveryCity?: string;
  deliveryCountry?: string;
  /** @format date-time */
  deliveryDateAfter?: string;
  /** @format date-time */
  deliveryDateBefore?: string;
  deliveryName?: string;
  deliveryPostCode?: string;
  deliveryStreet?: string;
  driverName?: string;
  /**
   * Load type
   *
   * * `FTL` - Full Truck Load
   * * `LTL` - Less than Truck Load
   * * `FCL` - Full Container Load
   * * `LCL` - Less than Container Load
   * * `PARCEL` - Parcel
   * * `NOT_SPECIFIED` - Not specified
   */
  loadType?: CargoLoadTypeEnum[];
  maxWeightKg?: number;
  minWeightKg?: number;
  /**
   * Ordering
   *
   * * `created_at` - Created at
   * * `-created_at` - Created at (descending)
   */
  order?: OrderEnum5[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  pickupCity?: string;
  pickupCountry?: string;
  /** @format date-time */
  pickupDateAfter?: string;
  /** @format date-time */
  pickupDateBefore?: string;
  pickupName?: string;
  pickupPostCode?: string;
  pickupStreet?: string;
  plateNumber?: string;
  /** @format date-time */
  publishedAtAfter?: string;
  /** @format date-time */
  publishedAtBefore?: string;
  reference?: string;
  /**
   * Request type
   *
   * * `FROZEN_GOODS` - Frozen goods
   * * `OVERSIZED_LOAD` - Oversized load
   * * `BULK_MATERIAL` - Bulk material
   * * `LIQUIDS` - Liquids
   * * `VEHICLES` - Vehicles
   * * `ADR` - ADR
   * * `OTHER` - Other
   * * `NOT_SPECIFIED` - Not specified
   */
  requestType?: RequestTypeEnum4;
  responsibleUser?: number;
  /**
   * Route type
   *
   * * `ONE_WAY` - One way
   * * `ROUND_TRIP` - Round trip
   */
  routeType?: RouteTypeEnum;
  /** A search term. */
  search?: string;
  /**
   * Transport status
   *
   * * `PROCESSING` - Processing
   * * `FINISHED` - Finished
   * * `CANCELED` - Canceled
   * * `CANCELED_BY_CARRIER` - Canceled by carrier
   */
  transportStatus?: TransportStatusEnum;
  /**
   * Transport type
   *
   * * `SPOT` - Spot transport
   * * `CONTRACTED` - Contracted transport
   */
  transportType?: TransportTypeEnum;
  /**
   * Transportation mode
   *
   * * `ROAD` - Road
   * * `AIR` - Air
   * * `SEA` - Sea
   * * `RAIL` - Rail
   * * `INTERMODAL` - Intermodal
   */
  transportationMode?: TransportationModeEnum;
}

export enum TransportsListParams1OrderEnum {
  ValueCreatedAt = "-created_at",
  CreatedAt = "created_at",
}

/**
 * Request type
 *
 * * `FROZEN_GOODS` - Frozen goods
 * * `OVERSIZED_LOAD` - Oversized load
 * * `BULK_MATERIAL` - Bulk material
 * * `LIQUIDS` - Liquids
 * * `VEHICLES` - Vehicles
 * * `ADR` - ADR
 * * `OTHER` - Other
 * * `NOT_SPECIFIED` - Not specified
 */
export enum TransportsListParams1RequestTypeEnum {
  ADR = "ADR",
  BULK_MATERIAL = "BULK_MATERIAL",
  FROZEN_GOODS = "FROZEN_GOODS",
  LIQUIDS = "LIQUIDS",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER = "OTHER",
  OVERSIZED_LOAD = "OVERSIZED_LOAD",
  VEHICLES = "VEHICLES",
}

export interface TransportsMessagesListParams {
  carrier?: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  /** A search term. */
  search?: string;
  transportPk: number;
}

/** It's used for listing bids for user. Main difference is that shipper status could be updated. */
export interface UpdateBid {
  readonly carrier: number;
  readonly carrierNote: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  readonly carrierStatus: BidStatusEnum;
  /** @format date-time */
  readonly carrierStatusChangedAt: string;
  /** @format date-time */
  readonly createdAt: string;
  readonly id: number;
  readonly isConfirmedByShipper: boolean;
  isInstantOrder?: boolean;
  readonly isRejected: boolean;
  readonly isWinning: boolean;
  metadata?: BidMetadata | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  offeredPrice?: string;
  readonly offeredPriceCurrency: string;
  shipperNote?: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  shipperStatus: BidStatusEnum;
  /** @format date-time */
  readonly shipperStatusChangedAt: string;
  /** @format date-time */
  readonly winsAt: string | null;
}

/** Separate serializer for carrier to update decision on bid. */
export interface UpdateBidByHash {
  readonly carrier: number;
  carrierNote?: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  carrierStatus: BidStatusEnum;
  /** You might already have a comparison value for a bid. It could be calculated in queryset. */
  readonly comparison: BidComparisonEnum;
  /** @format date-time */
  readonly createdAt: string;
  /** Hash key */
  readonly hash: string;
  readonly id: number;
  readonly isConfirmedByShipper: boolean;
  readonly isRejected: boolean;
  readonly isWinning: boolean;
  /**
   * @format decimal
   * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
   */
  offeredPrice?: string;
  readonly offeredPriceCurrency: string;
  readonly shipperNote: string;
  /**
   * * `CONFIRMED` - Confirmed
   * * `REJECTED` - Rejected
   * * `NOT_DECIDED` - Not decided
   */
  readonly shipperStatus: BidStatusEnum;
}

export interface User {
  readonly consentStatuses: ConsentInfo[];
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @maxLength 150 */
  firstName?: string;
  readonly id: number;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  isActive?: boolean;
  /** @maxLength 150 */
  lastName?: string;
  readonly notificationSettings: Record<string, Record<string, Record<string, boolean>>>;
  /** @maxLength 128 */
  phoneNumber?: string;
  readonly role: number;
  roleId?: number;
  readonly workspace: WorkspaceGeneralInfo;
}

/** User model w/o password */
export interface UserDetails {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string;
  firstName: string;
  readonly id: number;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  isActive?: boolean;
  readonly isEmailVerified: boolean;
  /** @maxLength 150 */
  lastName?: string;
  readonly members: Member[];
  readonly notificationSettings: Record<string, Record<string, Record<string, boolean>>>;
  readonly permissions: string[];
  phoneNumber: string;
  /**
   * Language
   * * `en` - English
   * * `sk` - Slovak
   * * `de` - German
   * * `fr` - French
   * * `it` - Italian
   * * `cs` - Czech
   */
  preferredLanguage?: LanguageEnum;
  readonly workspace: Workspace;
  workspaceId?: number;
}

export interface UserGeneralInfo {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @maxLength 150 */
  firstName?: string;
  readonly id: number;
  /** @maxLength 150 */
  lastName?: string;
  /** @maxLength 128 */
  phoneNumber?: string;
  readonly workspace: WorkspaceGeneralInfo;
}

export interface UserMessage {
  carrier: Carrier;
  content?: string;
  /** @format date-time */
  readonly createdAt: string;
  readonly data: MessageData;
  /**
   * * `NEW_MESSAGE` - New message
   * * `BIDDING_FINISHED` - Bidding finished
   * * `NEW_BID` - New bid
   * * `BID_SELECTED` - Bid selected
   * * `DOCUMENT_UPLOADED` - Document uploaded
   * * `TRANSPORT_CONFIRMED` - Transport confirmed
   * * `REQUEST_REJECTED` - Request rejected
   * * `TRANSPORT_REJECTED` - Transport rejected
   * * `REQUEST_DURATION_EXTENDED` - Request duration extended
   * * `EMAIL_RESENT` -  Email resent
   */
  readonly event: UserMessageEventEnum;
  readonly id: number;
  reference: Reference;
  /** @format date-time */
  readonly seenAt: string;
  readonly sender: GenericSender;
  /**
   * * `human` - Human
   * * `system` - System
   */
  readonly type: UserMessageTypeEnum;
  unreadCount: number;
}

/**
 * * `NEW_MESSAGE` - New message
 * * `BIDDING_FINISHED` - Bidding finished
 * * `NEW_BID` - New bid
 * * `BID_SELECTED` - Bid selected
 * * `DOCUMENT_UPLOADED` - Document uploaded
 * * `TRANSPORT_CONFIRMED` - Transport confirmed
 * * `REQUEST_REJECTED` - Request rejected
 * * `TRANSPORT_REJECTED` - Transport rejected
 * * `REQUEST_DURATION_EXTENDED` - Request duration extended
 * * `EMAIL_RESENT` -  Email resent
 */
export enum UserMessageEventEnum {
  NEW_MESSAGE = "NEW_MESSAGE",
  BIDDING_FINISHED = "BIDDING_FINISHED",
  NEW_BID = "NEW_BID",
  BID_SELECTED = "BID_SELECTED",
  DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
  TRANSPORT_CONFIRMED = "TRANSPORT_CONFIRMED",
  REQUEST_REJECTED = "REQUEST_REJECTED",
  TRANSPORT_REJECTED = "TRANSPORT_REJECTED",
  REQUEST_DURATION_EXTENDED = "REQUEST_DURATION_EXTENDED",
  EMAIL_RESENT = "EMAIL_RESENT",
}

/**
 * * `human` - Human
 * * `system` - System
 */
export enum UserMessageTypeEnum {
  Human = "human",
  System = "system",
}

export interface UserNotificationSettings {
  events: Record<string, NotificationEvent[]>;
}

export interface UserPermissions {
  permissions: string[];
}

export interface UserStats {
  readonly active: number;
  readonly pending: number;
  readonly total: number;
}

export interface UsersAllListParams {
  excludeRoles?: string[];
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
}

export interface UsersListParams {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A search term. */
  search?: string;
}

export interface UtmData {
  /** @maxLength 350 */
  url?: string;
  /** @maxLength 50 */
  utmCampaign?: string;
  /** @maxLength 50 */
  utmMedium?: string;
  /** @maxLength 50 */
  utmSource?: string;
}

export interface VerifyEmail {
  key: string;
}

export interface Waypoint {
  address?: number | null;
  /** @format date-time */
  availabilityEnd?: string | null;
  /** @format date-time */
  availabilityStart?: string | null;
  /** @maxLength 100 */
  city: string;
  /**
   * @format email
   * @maxLength 254
   */
  contactEmail?: string;
  /** @maxLength 50 */
  contactName?: string;
  /** @maxLength 128 */
  contactPhone?: string;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country: CountryEnum;
  id?: number | null;
  /**
   * @min 0
   * @max 32767
   */
  index?: number | null;
  /** @maxLength 50 */
  name?: string;
  note?: string;
  /** @maxLength 20 */
  postCode?: string;
  /** @maxLength 150 */
  reference?: string;
  saveToDirectory?: boolean;
  /** @maxLength 100 */
  street?: string;
  /**
   * * `PICKUP` - Pickup
   * * `DELIVERY` - Delivery
   */
  type: WaypointTypeEnum;
}

export interface WaypointAutocompleteRequest {
  field: string;
  /**
   * * `auction` - Auction
   * * `transport` - Transport
   */
  modelType?: WaypointAutocompleteRequestModelTypeEnum;
  term: string;
  /**
   * * `PICKUP` - Pickup
   * * `DELIVERY` - Delivery
   */
  waypointType: WaypointTypeEnum;
}

/**
 * * `auction` - Auction
 * * `transport` - Transport
 */
export enum WaypointAutocompleteRequestModelTypeEnum {
  Auction = "auction",
  Transport = "transport",
}

export interface WaypointByHash {
  readonly addressId: number | null;
  /** @format date-time */
  readonly availabilityEnd: string | null;
  /** @format date-time */
  readonly availabilityStart: string | null;
  /** @maxLength 100 */
  readonly city: string;
  readonly contactEmail: string | null;
  readonly contactName: string | null;
  readonly contactPhone: string | null;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  readonly country: CountryEnum;
  readonly id: number;
  /**
   * @min 0
   * @max 32767
   */
  readonly index: number | null;
  /** @maxLength 50 */
  readonly name: string;
  readonly note: string;
  /**
   * @min 0
   * @max 32767
   */
  readonly originalId: number | null;
  /** @maxLength 20 */
  readonly postCode: string;
  readonly reference: string | null;
  /** @maxLength 100 */
  readonly street: string;
  /**
   * * `PICKUP` - Pickup
   * * `DELIVERY` - Delivery
   */
  readonly type: WaypointTypeEnum;
}

export enum WaypointStatsTypeEnum {
  DELIVERY = "DELIVERY",
  PICKUP = "PICKUP",
  PICKUP_AND_DELIVERY = "PICKUP_AND_DELIVERY",
}

/**
 * * `PICKUP` - Pickup
 * * `DELIVERY` - Delivery
 */
export enum WaypointTypeEnum {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
}

export interface Workspace {
  address?: CompanyAddress | null;
  billingDetails?: BillingDetailsLimited | null;
  contact?: Contact | null;
  /**
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency?: CurrencyEnum;
  /**
   * Email sender name
   * @maxLength 100
   */
  emailFromName?: string;
  /** Hash key */
  readonly hash: string;
  readonly id: number;
  /**
   * * `m` - m
   * * `cm` - cm
   * * `mm` - mm
   */
  lengthUnit?: LengthUnitEnum;
  /**
   * Title
   * @maxLength 100
   */
  name: string;
  readonly packageData: WorkspacePackageData | null;
  preferences?: WorkspacePreferences;
  /**
   * * `kg` - kg
   * * `t` - t
   */
  weightUnit?: WeightUnitEnum;
}

export interface WorkspaceByHash {
  readonly address: CompanyAddress;
  readonly billingDetails: BillingDetails;
  readonly id: number;
  /**
   * Title
   * @maxLength 100
   */
  readonly name: string;
}

export interface WorkspaceGeneralInfo {
  readonly id: number;
  /**
   * Title
   * @maxLength 100
   */
  name: string;
}

export interface WorkspaceInvitation {
  /** @format date-time */
  sent?: string | null;
  readonly workspace: WorkspaceGeneralInfo;
}

export interface WorkspacePackageData {
  contractedTransportsLeft: number;
}

export interface WorkspacePreferences {
  /** Default note for carrier in the request */
  carrierNote?: string;
  isPricerPopupEnabled?: boolean;
}

export interface WorkspaceUpdate {
  address?: CompanyAddress | null;
  billingDetails?: BillingDetailsLimited | null;
  contact?: Contact | null;
  /**
   * * `EUR` - EUR
   * * `USD` - USD
   * * `CZK` - CZK
   * * `HUF` - HUF
   * * `PLN` - PLN
   * * `RON` - RON
   * * `GBP` - GBP
   * * `BGN` - BGN
   * * `NOK` - NOK
   * * `DKK` - DKK
   * * `SEK` - SEK
   * * `ISK` - ISK
   * * `CHF` - CHF
   */
  currency?: CurrencyEnum;
  /**
   * Email sender name
   * @maxLength 100
   */
  emailFromName?: string;
  /** Hash key */
  readonly hash: string;
  readonly id: number;
  /**
   * * `m` - m
   * * `cm` - cm
   * * `mm` - mm
   */
  lengthUnit?: LengthUnitEnum;
  name?: string;
  readonly packageData: WorkspacePackageData | null;
  preferences?: WorkspacePreferences;
  /**
   * * `kg` - kg
   * * `t` - t
   */
  weightUnit?: WeightUnitEnum;
}

export namespace Addresses {
  /**
   * No description
   * @tags addresses
   * @name AddressesList
   * @request GET:/api/v1/addresses/
   * @secure
   * @response `200` `PaginatedAddressList`
   */
  export namespace AddressesList {
    export type RequestParams = {};
    export type RequestQuery = {
      city?: string;
      /**
       * * `AF` - Afghanistan
       * * `AX` - Åland Islands
       * * `AL` - Albania
       * * `DZ` - Algeria
       * * `AS` - American Samoa
       * * `AD` - Andorra
       * * `AO` - Angola
       * * `AI` - Anguilla
       * * `AQ` - Antarctica
       * * `AG` - Antigua and Barbuda
       * * `AR` - Argentina
       * * `AM` - Armenia
       * * `AW` - Aruba
       * * `AU` - Australia
       * * `AT` - Austria
       * * `AZ` - Azerbaijan
       * * `BS` - Bahamas
       * * `BH` - Bahrain
       * * `BD` - Bangladesh
       * * `BB` - Barbados
       * * `BY` - Belarus
       * * `BE` - Belgium
       * * `BZ` - Belize
       * * `BJ` - Benin
       * * `BM` - Bermuda
       * * `BT` - Bhutan
       * * `BO` - Bolivia
       * * `BQ` - Bonaire, Sint Eustatius and Saba
       * * `BA` - Bosnia and Herzegovina
       * * `BW` - Botswana
       * * `BV` - Bouvet Island
       * * `BR` - Brazil
       * * `IO` - British Indian Ocean Territory
       * * `BN` - Brunei
       * * `BG` - Bulgaria
       * * `BF` - Burkina Faso
       * * `BI` - Burundi
       * * `CV` - Cabo Verde
       * * `KH` - Cambodia
       * * `CM` - Cameroon
       * * `CA` - Canada
       * * `KY` - Cayman Islands
       * * `CF` - Central African Republic
       * * `TD` - Chad
       * * `CL` - Chile
       * * `CN` - China
       * * `CX` - Christmas Island
       * * `CC` - Cocos (Keeling) Islands
       * * `CO` - Colombia
       * * `KM` - Comoros
       * * `CG` - Congo
       * * `CD` - Congo (the Democratic Republic of the)
       * * `CK` - Cook Islands
       * * `CR` - Costa Rica
       * * `CI` - Côte d'Ivoire
       * * `HR` - Croatia
       * * `CU` - Cuba
       * * `CW` - Curaçao
       * * `CY` - Cyprus
       * * `CZ` - Czechia
       * * `DK` - Denmark
       * * `DJ` - Djibouti
       * * `DM` - Dominica
       * * `DO` - Dominican Republic
       * * `EC` - Ecuador
       * * `EG` - Egypt
       * * `SV` - El Salvador
       * * `GQ` - Equatorial Guinea
       * * `ER` - Eritrea
       * * `EE` - Estonia
       * * `SZ` - Eswatini
       * * `ET` - Ethiopia
       * * `FK` - Falkland Islands (Malvinas)
       * * `FO` - Faroe Islands
       * * `FJ` - Fiji
       * * `FI` - Finland
       * * `FR` - France
       * * `GF` - French Guiana
       * * `PF` - French Polynesia
       * * `TF` - French Southern Territories
       * * `GA` - Gabon
       * * `GM` - Gambia
       * * `GE` - Georgia
       * * `DE` - Germany
       * * `GH` - Ghana
       * * `GI` - Gibraltar
       * * `GR` - Greece
       * * `GL` - Greenland
       * * `GD` - Grenada
       * * `GP` - Guadeloupe
       * * `GU` - Guam
       * * `GT` - Guatemala
       * * `GG` - Guernsey
       * * `GN` - Guinea
       * * `GW` - Guinea-Bissau
       * * `GY` - Guyana
       * * `HT` - Haiti
       * * `HM` - Heard Island and McDonald Islands
       * * `VA` - Holy See
       * * `HN` - Honduras
       * * `HK` - Hong Kong
       * * `HU` - Hungary
       * * `IS` - Iceland
       * * `IN` - India
       * * `ID` - Indonesia
       * * `IR` - Iran
       * * `IQ` - Iraq
       * * `IE` - Ireland
       * * `IM` - Isle of Man
       * * `IL` - Israel
       * * `IT` - Italy
       * * `JM` - Jamaica
       * * `JP` - Japan
       * * `JE` - Jersey
       * * `JO` - Jordan
       * * `KZ` - Kazakhstan
       * * `KE` - Kenya
       * * `KI` - Kiribati
       * * `KW` - Kuwait
       * * `KG` - Kyrgyzstan
       * * `LA` - Laos
       * * `LV` - Latvia
       * * `LB` - Lebanon
       * * `LS` - Lesotho
       * * `LR` - Liberia
       * * `LY` - Libya
       * * `LI` - Liechtenstein
       * * `LT` - Lithuania
       * * `LU` - Luxembourg
       * * `MO` - Macao
       * * `MG` - Madagascar
       * * `MW` - Malawi
       * * `MY` - Malaysia
       * * `MV` - Maldives
       * * `ML` - Mali
       * * `MT` - Malta
       * * `MH` - Marshall Islands
       * * `MQ` - Martinique
       * * `MR` - Mauritania
       * * `MU` - Mauritius
       * * `YT` - Mayotte
       * * `MX` - Mexico
       * * `FM` - Micronesia
       * * `MD` - Moldova
       * * `MC` - Monaco
       * * `MN` - Mongolia
       * * `ME` - Montenegro
       * * `MS` - Montserrat
       * * `MA` - Morocco
       * * `MZ` - Mozambique
       * * `MM` - Myanmar
       * * `NA` - Namibia
       * * `NR` - Nauru
       * * `NP` - Nepal
       * * `NL` - Netherlands
       * * `NC` - New Caledonia
       * * `NZ` - New Zealand
       * * `NI` - Nicaragua
       * * `NE` - Niger
       * * `NG` - Nigeria
       * * `NU` - Niue
       * * `NF` - Norfolk Island
       * * `KP` - North Korea
       * * `MK` - North Macedonia
       * * `MP` - Northern Mariana Islands
       * * `NO` - Norway
       * * `OM` - Oman
       * * `PK` - Pakistan
       * * `PW` - Palau
       * * `PS` - Palestine, State of
       * * `PA` - Panama
       * * `PG` - Papua New Guinea
       * * `PY` - Paraguay
       * * `PE` - Peru
       * * `PH` - Philippines
       * * `PN` - Pitcairn
       * * `PL` - Poland
       * * `PT` - Portugal
       * * `PR` - Puerto Rico
       * * `QA` - Qatar
       * * `RE` - Réunion
       * * `RO` - Romania
       * * `RU` - Russia
       * * `RW` - Rwanda
       * * `BL` - Saint Barthélemy
       * * `SH` - Saint Helena, Ascension and Tristan da Cunha
       * * `KN` - Saint Kitts and Nevis
       * * `LC` - Saint Lucia
       * * `MF` - Saint Martin (French part)
       * * `PM` - Saint Pierre and Miquelon
       * * `VC` - Saint Vincent and the Grenadines
       * * `WS` - Samoa
       * * `SM` - San Marino
       * * `ST` - Sao Tome and Principe
       * * `SA` - Saudi Arabia
       * * `SN` - Senegal
       * * `RS` - Serbia
       * * `SC` - Seychelles
       * * `SL` - Sierra Leone
       * * `SG` - Singapore
       * * `SX` - Sint Maarten (Dutch part)
       * * `SK` - Slovakia
       * * `SI` - Slovenia
       * * `SB` - Solomon Islands
       * * `SO` - Somalia
       * * `ZA` - South Africa
       * * `GS` - South Georgia and the South Sandwich Islands
       * * `KR` - South Korea
       * * `SS` - South Sudan
       * * `ES` - Spain
       * * `LK` - Sri Lanka
       * * `SD` - Sudan
       * * `SR` - Suriname
       * * `SJ` - Svalbard and Jan Mayen
       * * `SE` - Sweden
       * * `CH` - Switzerland
       * * `SY` - Syria
       * * `TW` - Taiwan
       * * `TJ` - Tajikistan
       * * `TZ` - Tanzania
       * * `TH` - Thailand
       * * `TL` - Timor-Leste
       * * `TG` - Togo
       * * `TK` - Tokelau
       * * `TO` - Tonga
       * * `TT` - Trinidad and Tobago
       * * `TN` - Tunisia
       * * `TR` - Türkiye
       * * `TM` - Turkmenistan
       * * `TC` - Turks and Caicos Islands
       * * `TV` - Tuvalu
       * * `UG` - Uganda
       * * `UA` - Ukraine
       * * `AE` - United Arab Emirates
       * * `GB` - United Kingdom
       * * `UM` - United States Minor Outlying Islands
       * * `US` - United States of America
       * * `UY` - Uruguay
       * * `UZ` - Uzbekistan
       * * `VU` - Vanuatu
       * * `VE` - Venezuela
       * * `VN` - Vietnam
       * * `VG` - Virgin Islands (British)
       * * `VI` - Virgin Islands (U.S.)
       * * `WF` - Wallis and Futuna
       * * `EH` - Western Sahara
       * * `YE` - Yemen
       * * `ZM` - Zambia
       * * `ZW` - Zimbabwe
       */
      country?: CountryEnum;
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      postCode?: string;
      /** A search term. */
      search?: string;
      street?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedAddressList;
  }

  /**
   * No description
   * @tags addresses
   * @name AddressesCreate
   * @request POST:/api/v1/addresses/
   * @secure
   * @response `201` `Address`
   */
  export namespace AddressesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Address;
    export type RequestHeaders = {};
    export type ResponseBody = Address;
  }

  /**
   * No description
   * @tags addresses
   * @name AddressesRetrieve
   * @request GET:/api/v1/addresses/{id}/
   * @secure
   * @response `200` `Address`
   */
  export namespace AddressesRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this Address. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Address;
  }

  /**
   * No description
   * @tags addresses
   * @name AddressesUpdate
   * @request PUT:/api/v1/addresses/{id}/
   * @secure
   * @response `200` `Address`
   */
  export namespace AddressesUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this Address. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = Address;
    export type RequestHeaders = {};
    export type ResponseBody = Address;
  }

  /**
   * No description
   * @tags addresses
   * @name AddressesPartialUpdate
   * @request PATCH:/api/v1/addresses/{id}/
   * @secure
   * @response `200` `Address`
   */
  export namespace AddressesPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this Address. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedAddress;
    export type RequestHeaders = {};
    export type ResponseBody = Address;
  }

  /**
   * No description
   * @tags addresses
   * @name AddressesDestroy
   * @request DELETE:/api/v1/addresses/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace AddressesDestroy {
    export type RequestParams = {
      /** A unique integer value identifying this Address. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace AuctionStats {
  /**
   * No description
   * @tags auction-stats
   * @name AuctionStatsOverviewRetrieve
   * @request GET:/api/v1/auction-stats/overview/
   * @secure
   * @response `200` `AuctionOverviewStats`
   */
  export namespace AuctionStatsOverviewRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Auction ID */
      auctionId?: number;
      /**
       * Auction status
       *
       * * `DRAFT` - Draft
       * * `ACTIVE` - Active
       * * `INSTANT_ORDER` - Instant order
       * * `BIDDING_FINISHED` - Bidding finished
       * * `CONFIRMED` - Confirmed
       * * `CANCELED` - Canceled
       * * `OTHER` - Other
       */
      auctionStatus?: AuctionStatusEnum;
      /** Winning carrier */
      carrierName?: string;
      costCenter?: string;
      /** @format date-time */
      createdAtAfter?: string;
      /** @format date-time */
      createdAtBefore?: string;
      createdBy?: number;
      /**
       * Currency
       *
       * * `EUR` - EUR
       * * `USD` - USD
       * * `CZK` - CZK
       * * `HUF` - HUF
       * * `PLN` - PLN
       * * `RON` - RON
       * * `GBP` - GBP
       * * `BGN` - BGN
       * * `NOK` - NOK
       * * `DKK` - DKK
       * * `SEK` - SEK
       * * `ISK` - ISK
       * * `CHF` - CHF
       */
      currency?: CurrencyEnum;
      deliveryCity?: string;
      deliveryCountry?: string;
      /** @format date-time */
      deliveryDateAfter?: string;
      /** @format date-time */
      deliveryDateBefore?: string;
      deliveryName?: string;
      deliveryPostCode?: string;
      deliveryStreet?: string;
      driverName?: string;
      /**
       * Load type
       *
       * * `FTL` - Full Truck Load
       * * `LTL` - Less than Truck Load
       * * `FCL` - Full Container Load
       * * `LCL` - Less than Container Load
       * * `PARCEL` - Parcel
       * * `NOT_SPECIFIED` - Not specified
       */
      loadType?: CargoLoadTypeEnum[];
      maxWeightKg?: number;
      minWeightKg?: number;
      /**
       * Ordering
       *
       * * `created_at` - Created at
       * * `-created_at` - Created at (descending)
       */
      order?: AuctionStatsOverviewRetrieveParams1OrderEnum[];
      /** Which field to use when ordering the results. */
      ordering?: string;
      pickupCity?: string;
      pickupCountry?: string;
      /** @format date-time */
      pickupDateAfter?: string;
      /** @format date-time */
      pickupDateBefore?: string;
      pickupName?: string;
      pickupPostCode?: string;
      pickupStreet?: string;
      plateNumber?: string;
      /** @format date-time */
      publishedAtAfter?: string;
      /** @format date-time */
      publishedAtBefore?: string;
      reference?: string;
      /**
       * Request type
       *
       * * `FROZEN_GOODS` - Frozen goods
       * * `OVERSIZED_LOAD` - Oversized load
       * * `BULK_MATERIAL` - Bulk material
       * * `LIQUIDS` - Liquids
       * * `VEHICLES` - Vehicles
       * * `ADR` - ADR
       * * `OTHER` - Other
       * * `NOT_SPECIFIED` - Not specified
       */
      requestType?: AuctionStatsOverviewRetrieveParams1RequestTypeEnum;
      responsibleUser?: number;
      /**
       * Route type
       *
       * * `ONE_WAY` - One way
       * * `ROUND_TRIP` - Round trip
       */
      routeType?: RouteTypeEnum;
      /** A search term. */
      search?: string;
      /**
       * Transport type
       *
       * * `SPOT` - Spot transport
       * * `CONTRACTED` - Contracted transport
       */
      transportType?: TransportTypeEnum;
      /**
       * Transportation mode
       *
       * * `ROAD` - Road
       * * `AIR` - Air
       * * `SEA` - Sea
       * * `RAIL` - Rail
       * * `INTERMODAL` - Intermodal
       */
      transportationMode?: TransportationModeEnum;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionOverviewStats;
  }
}

export namespace Auctions {
  /**
   * No description
   * @tags auctions
   * @name AuctionsList
   * @request GET:/api/v1/auctions/
   * @secure
   * @response `200` `PaginatedAuctionListList`
   */
  export namespace AuctionsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Auction ID */
      auctionId?: number;
      /**
       * Auction status
       *
       * * `DRAFT` - Draft
       * * `ACTIVE` - Active
       * * `INSTANT_ORDER` - Instant order
       * * `BIDDING_FINISHED` - Bidding finished
       * * `CONFIRMED` - Confirmed
       * * `CANCELED` - Canceled
       * * `OTHER` - Other
       */
      auctionStatus?: AuctionStatusEnum;
      /** Winning carrier */
      carrierName?: string;
      costCenter?: string;
      /** @format date-time */
      createdAtAfter?: string;
      /** @format date-time */
      createdAtBefore?: string;
      createdBy?: number;
      /**
       * Currency
       *
       * * `EUR` - EUR
       * * `USD` - USD
       * * `CZK` - CZK
       * * `HUF` - HUF
       * * `PLN` - PLN
       * * `RON` - RON
       * * `GBP` - GBP
       * * `BGN` - BGN
       * * `NOK` - NOK
       * * `DKK` - DKK
       * * `SEK` - SEK
       * * `ISK` - ISK
       * * `CHF` - CHF
       */
      currency?: CurrencyEnum;
      deliveryCity?: string;
      deliveryCountry?: string;
      /** @format date-time */
      deliveryDateAfter?: string;
      /** @format date-time */
      deliveryDateBefore?: string;
      deliveryName?: string;
      deliveryPostCode?: string;
      deliveryStreet?: string;
      driverName?: string;
      /**
       * Load type
       *
       * * `FTL` - Full Truck Load
       * * `LTL` - Less than Truck Load
       * * `FCL` - Full Container Load
       * * `LCL` - Less than Container Load
       * * `PARCEL` - Parcel
       * * `NOT_SPECIFIED` - Not specified
       */
      loadType?: CargoLoadTypeEnum[];
      maxWeightKg?: number;
      minWeightKg?: number;
      /**
       * Ordering
       *
       * * `created_at` - Created at
       * * `-created_at` - Created at (descending)
       */
      order?: AuctionsListParams1OrderEnum[];
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      pickupCity?: string;
      pickupCountry?: string;
      /** @format date-time */
      pickupDateAfter?: string;
      /** @format date-time */
      pickupDateBefore?: string;
      pickupName?: string;
      pickupPostCode?: string;
      pickupStreet?: string;
      plateNumber?: string;
      /** @format date-time */
      publishedAtAfter?: string;
      /** @format date-time */
      publishedAtBefore?: string;
      reference?: string;
      /**
       * Request type
       *
       * * `FROZEN_GOODS` - Frozen goods
       * * `OVERSIZED_LOAD` - Oversized load
       * * `BULK_MATERIAL` - Bulk material
       * * `LIQUIDS` - Liquids
       * * `VEHICLES` - Vehicles
       * * `ADR` - ADR
       * * `OTHER` - Other
       * * `NOT_SPECIFIED` - Not specified
       */
      requestType?: AuctionsListParams1RequestTypeEnum;
      responsibleUser?: number;
      /**
       * Route type
       *
       * * `ONE_WAY` - One way
       * * `ROUND_TRIP` - Round trip
       */
      routeType?: RouteTypeEnum;
      /** A search term. */
      search?: string;
      /**
       * Transport type
       *
       * * `SPOT` - Spot transport
       * * `CONTRACTED` - Contracted transport
       */
      transportType?: TransportTypeEnum;
      /**
       * Transportation mode
       *
       * * `ROAD` - Road
       * * `AIR` - Air
       * * `SEA` - Sea
       * * `RAIL` - Rail
       * * `INTERMODAL` - Intermodal
       */
      transportationMode?: TransportationModeEnum;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedAuctionListList;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsCreate
   * @request POST:/api/v1/auctions/
   * @secure
   * @response `201` `Auction`
   */
  export namespace AuctionsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Auction;
    export type RequestHeaders = {};
    export type ResponseBody = Auction;
  }

  /**
   * @description ViewSet for Bid model just for logged in user.
   * @tags bids
   * @name AuctionsBidsList
   * @request GET:/api/v1/auctions/{auction_pk}/bids/
   * @secure
   * @response `200` `(Bid)[]`
   */
  export namespace AuctionsBidsList {
    export type RequestParams = {
      auctionPk: number;
    };
    export type RequestQuery = {
      /**
       * Ordering
       *
       * * `created_at` - Created at
       * * `-created_at` - Created at (descending)
       * * `price` - Price
       * * `-price` - Price (descending)
       */
      order?: AuctionsBidsListParams1OrderEnum[];
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Bid[];
  }

  /**
   * @description ViewSet for Bid model just for logged in user.
   * @tags bids
   * @name AuctionsBidsRetrieve
   * @request GET:/api/v1/auctions/{auction_pk}/bids/{id}/
   * @secure
   * @response `200` `Bid`
   */
  export namespace AuctionsBidsRetrieve {
    export type RequestParams = {
      auctionPk: number;
      /** A unique integer value identifying this bid. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Bid;
  }

  /**
   * @description ViewSet for Bid model just for logged in user.
   * @tags bids
   * @name AuctionsBidsUpdate
   * @request PUT:/api/v1/auctions/{auction_pk}/bids/{id}/
   * @secure
   * @response `200` `UpdateBid`
   */
  export namespace AuctionsBidsUpdate {
    export type RequestParams = {
      auctionPk: number;
      /** A unique integer value identifying this bid. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBid;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateBid;
  }

  /**
   * @description ViewSet for Bid model just for logged in user.
   * @tags bids
   * @name AuctionsBidsPartialUpdate
   * @request PATCH:/api/v1/auctions/{auction_pk}/bids/{id}/
   * @secure
   * @response `200` `UpdateBid`
   */
  export namespace AuctionsBidsPartialUpdate {
    export type RequestParams = {
      auctionPk: number;
      /** A unique integer value identifying this bid. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedUpdateBid;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateBid;
  }

  /**
   * No description
   * @tags documents
   * @name AuctionsDocumentsList
   * @request GET:/api/v1/auctions/{auction_pk}/documents/
   * @secure
   * @response `200` `PaginatedDocumentList`
   */
  export namespace AuctionsDocumentsList {
    export type RequestParams = {
      auctionPk: number;
    };
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedDocumentList;
  }

  /**
   * No description
   * @tags messages
   * @name AuctionsMessagesList
   * @request GET:/api/v1/auctions/{auction_pk}/messages/
   * @secure
   * @response `200` `PaginatedMessageList`
   */
  export namespace AuctionsMessagesList {
    export type RequestParams = {
      auctionPk: number;
    };
    export type RequestQuery = {
      carrier?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedMessageList;
  }

  /**
   * No description
   * @tags messages
   * @name AuctionsMessagesCreate
   * @request POST:/api/v1/auctions/{auction_pk}/messages/
   * @secure
   * @response `201` `Message`
   */
  export namespace AuctionsMessagesCreate {
    export type RequestParams = {
      auctionPk: number;
    };
    export type RequestQuery = {};
    export type RequestBody = Message;
    export type RequestHeaders = {};
    export type ResponseBody = Message;
  }

  /**
   * No description
   * @tags messages
   * @name AuctionsMessagesMarkAsReadCreate
   * @request POST:/api/v1/auctions/{auction_pk}/messages/mark-as-read/
   * @secure
   * @response `200` `MarkAsReadMessage`
   */
  export namespace AuctionsMessagesMarkAsReadCreate {
    export type RequestParams = {
      auctionPk: number;
    };
    export type RequestQuery = {};
    export type RequestBody = MarkAsReadMessage;
    export type RequestHeaders = {};
    export type ResponseBody = MarkAsReadMessage;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsParticipantsList
   * @request GET:/api/v1/auctions/{auction_pk}/participants/
   * @secure
   * @response `200` `(AuctionParticipant)[]`
   */
  export namespace AuctionsParticipantsList {
    export type RequestParams = {
      auctionPk: number;
    };
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionParticipant[];
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsParticipantsRetrieve
   * @request GET:/api/v1/auctions/{auction_pk}/participants/{id}/
   * @secure
   * @response `200` `AuctionParticipant`
   */
  export namespace AuctionsParticipantsRetrieve {
    export type RequestParams = {
      auctionPk: number;
      /** A unique integer value identifying this auction participant. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionParticipant;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsParticipantsResendInvitationCreate
   * @request POST:/api/v1/auctions/{auction_pk}/participants/{id}/resend-invitation/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace AuctionsParticipantsResendInvitationCreate {
    export type RequestParams = {
      auctionPk: number;
      /** A unique integer value identifying this auction participant. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsParticipantsStatusCreate
   * @request POST:/api/v1/auctions/{auction_pk}/participants/{id}/status/
   * @secure
   * @response `200` `AuctionSetParticipantStatus`
   */
  export namespace AuctionsParticipantsStatusCreate {
    export type RequestParams = {
      auctionPk: number;
      /** A unique integer value identifying this auction participant. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = AuctionSetParticipantStatus;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionSetParticipantStatus;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsRetrieve
   * @request GET:/api/v1/auctions/{id}/
   * @secure
   * @response `200` `Auction`
   */
  export namespace AuctionsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this auction. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Auction;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsUpdate
   * @request PUT:/api/v1/auctions/{id}/
   * @secure
   * @response `200` `AuctionUpdate`
   */
  export namespace AuctionsUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this auction. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = AuctionUpdate;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionUpdate;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsPartialUpdate
   * @request PATCH:/api/v1/auctions/{id}/
   * @secure
   * @response `200` `AuctionUpdate`
   */
  export namespace AuctionsPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this auction. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedAuctionUpdate;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionUpdate;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsDestroy
   * @request DELETE:/api/v1/auctions/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace AuctionsDestroy {
    export type RequestParams = {
      /** A unique integer value identifying this auction. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsCancelCreate
   * @request POST:/api/v1/auctions/{id}/cancel/
   * @secure
   * @response `200` `Auction`
   */
  export namespace AuctionsCancelCreate {
    export type RequestParams = {
      /** A unique integer value identifying this auction. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = Auction;
    export type RequestHeaders = {};
    export type ResponseBody = Auction;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsChangePaymentMethodCreate
   * @request POST:/api/v1/auctions/{id}/change_payment_method/
   * @secure
   * @response `200` `AuctionChangePaymentMethod`
   */
  export namespace AuctionsChangePaymentMethodCreate {
    export type RequestParams = {
      /** A unique integer value identifying this auction. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = AuctionChangePaymentMethod;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionChangePaymentMethod;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsPublishCreate
   * @request POST:/api/v1/auctions/{id}/publish/
   * @secure
   * @response `200` `Auction`
   */
  export namespace AuctionsPublishCreate {
    export type RequestParams = {
      /** A unique integer value identifying this auction. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = Auction;
    export type RequestHeaders = {};
    export type ResponseBody = Auction;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsTakeInstantOrderCreate
   * @request POST:/api/v1/auctions/{id}/take_instant_order/
   * @secure
   * @response `200` `AuctionTakeInstantOrder`
   */
  export namespace AuctionsTakeInstantOrderCreate {
    export type RequestParams = {
      /** A unique integer value identifying this auction. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = AuctionTakeInstantOrder;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionTakeInstantOrder;
  }

  /**
   * No description
   * @tags auctions
   * @name AuctionsExportRetrieve
   * @request GET:/api/v1/auctions/export/
   * @secure
   * @response `200` `File`
   */
  export namespace AuctionsExportRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Auction ID */
      auctionId?: number;
      /**
       * Auction status
       *
       * * `DRAFT` - Draft
       * * `ACTIVE` - Active
       * * `INSTANT_ORDER` - Instant order
       * * `BIDDING_FINISHED` - Bidding finished
       * * `CONFIRMED` - Confirmed
       * * `CANCELED` - Canceled
       * * `OTHER` - Other
       */
      auctionStatus?: AuctionStatusEnum;
      /** Winning carrier */
      carrierName?: string;
      costCenter?: string;
      /** @format date-time */
      createdAtAfter?: string;
      /** @format date-time */
      createdAtBefore?: string;
      createdBy?: number;
      /**
       * Currency
       *
       * * `EUR` - EUR
       * * `USD` - USD
       * * `CZK` - CZK
       * * `HUF` - HUF
       * * `PLN` - PLN
       * * `RON` - RON
       * * `GBP` - GBP
       * * `BGN` - BGN
       * * `NOK` - NOK
       * * `DKK` - DKK
       * * `SEK` - SEK
       * * `ISK` - ISK
       * * `CHF` - CHF
       */
      currency?: CurrencyEnum;
      deliveryCity?: string;
      deliveryCountry?: string;
      /** @format date-time */
      deliveryDateAfter?: string;
      /** @format date-time */
      deliveryDateBefore?: string;
      deliveryName?: string;
      deliveryPostCode?: string;
      deliveryStreet?: string;
      driverName?: string;
      /**
       * Load type
       *
       * * `FTL` - Full Truck Load
       * * `LTL` - Less than Truck Load
       * * `FCL` - Full Container Load
       * * `LCL` - Less than Container Load
       * * `PARCEL` - Parcel
       * * `NOT_SPECIFIED` - Not specified
       */
      loadType?: CargoLoadTypeEnum[];
      maxWeightKg?: number;
      minWeightKg?: number;
      /**
       * Ordering
       *
       * * `created_at` - Created at
       * * `-created_at` - Created at (descending)
       */
      order?: AuctionsExportRetrieveParams1OrderEnum[];
      /** Which field to use when ordering the results. */
      ordering?: string;
      pickupCity?: string;
      pickupCountry?: string;
      /** @format date-time */
      pickupDateAfter?: string;
      /** @format date-time */
      pickupDateBefore?: string;
      pickupName?: string;
      pickupPostCode?: string;
      pickupStreet?: string;
      plateNumber?: string;
      /** @format date-time */
      publishedAtAfter?: string;
      /** @format date-time */
      publishedAtBefore?: string;
      reference?: string;
      /**
       * Request type
       *
       * * `FROZEN_GOODS` - Frozen goods
       * * `OVERSIZED_LOAD` - Oversized load
       * * `BULK_MATERIAL` - Bulk material
       * * `LIQUIDS` - Liquids
       * * `VEHICLES` - Vehicles
       * * `ADR` - ADR
       * * `OTHER` - Other
       * * `NOT_SPECIFIED` - Not specified
       */
      requestType?: AuctionsExportRetrieveParams1RequestTypeEnum;
      responsibleUser?: number;
      /**
       * Route type
       *
       * * `ONE_WAY` - One way
       * * `ROUND_TRIP` - Round trip
       */
      routeType?: RouteTypeEnum;
      /** A search term. */
      search?: string;
      /**
       * Transport type
       *
       * * `SPOT` - Spot transport
       * * `CONTRACTED` - Contracted transport
       */
      transportType?: TransportTypeEnum;
      /**
       * Transportation mode
       *
       * * `ROAD` - Road
       * * `AIR` - Air
       * * `SEA` - Sea
       * * `RAIL` - Rail
       * * `INTERMODAL` - Intermodal
       */
      transportationMode?: TransportationModeEnum;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
}

export namespace Auth {
  /**
   * @description Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
   * @tags auth
   * @name AuthLoginCreate
   * @request POST:/api/v1/auth/login/
   * @secure
   * @response `200` `JWT`
   */
  export namespace AuthLoginCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Login;
    export type RequestHeaders = {};
    export type ResponseBody = JWT;
  }

  /**
   * @description Calls Django logout method and delete the Token object assigned to the current User object. Accepts/Returns nothing.
   * @tags auth
   * @name AuthLogoutCreate
   * @request POST:/api/v1/auth/logout/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthLogoutCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }

  /**
   * @description Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
   * @tags auth
   * @name AuthPasswordChangeCreate
   * @request POST:/api/v1/auth/password/change/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthPasswordChangeCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PasswordChange;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }

  /**
   * @description Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
   * @tags auth
   * @name AuthPasswordResetCreate
   * @request POST:/api/v1/auth/password/reset/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthPasswordResetCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PasswordReset;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }

  /**
   * @description Password reset e-mail link is confirmed, therefore this resets the user's password. Accepts the following POST parameters: token, uid, new_password1, new_password2 Returns the success/fail message.
   * @tags auth
   * @name AuthPasswordResetConfirmCreate
   * @request POST:/api/v1/auth/password/reset/confirm/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthPasswordResetConfirmCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PasswordResetConfirm;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }

  /**
   * No description
   * @tags auth
   * @name AuthRegisterCreate
   * @request POST:/api/v1/auth/register/
   * @secure
   * @response `201` `JWT`
   */
  export namespace AuthRegisterCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Register;
    export type RequestHeaders = {};
    export type ResponseBody = JWT;
  }

  /**
   * No description
   * @tags auth
   * @name AuthRegisterResendEmailCreate
   * @request POST:/api/v1/auth/register/resend-email/
   * @secure
   * @response `201` `RestAuthDetail`
   */
  export namespace AuthRegisterResendEmailCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ResendEmailVerification;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }

  /**
   * No description
   * @tags auth
   * @name AuthRegisterVerifyEmailCreate
   * @request POST:/api/v1/auth/register/verify-email/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthRegisterVerifyEmailCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = VerifyEmail;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }

  /**
   * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
   * @tags auth
   * @name AuthTokenRefreshCreate
   * @request POST:/api/v1/auth/token/refresh/
   * @response `200` `TokenRefresh`
   */
  export namespace AuthTokenRefreshCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TokenRefresh;
    export type RequestHeaders = {};
    export type ResponseBody = TokenRefresh;
  }

  /**
   * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
   * @tags auth
   * @name AuthTokenVerifyCreate
   * @request POST:/api/v1/auth/token/verify/
   * @response `200` `TokenVerify`
   */
  export namespace AuthTokenVerifyCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TokenVerify;
    export type RequestHeaders = {};
    export type ResponseBody = TokenVerify;
  }

  /**
   * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
   * @tags auth
   * @name AuthUserRetrieve
   * @request GET:/api/v1/auth/user/
   * @secure
   * @response `200` `UserDetails`
   */
  export namespace AuthUserRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserDetails;
  }

  /**
   * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
   * @tags auth
   * @name AuthUserUpdate
   * @request PUT:/api/v1/auth/user/
   * @secure
   * @response `200` `UserDetails`
   */
  export namespace AuthUserUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UserDetails;
    export type RequestHeaders = {};
    export type ResponseBody = UserDetails;
  }

  /**
   * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
   * @tags auth
   * @name AuthUserPartialUpdate
   * @request PATCH:/api/v1/auth/user/
   * @secure
   * @response `200` `UserDetails`
   */
  export namespace AuthUserPartialUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PatchedUserDetails;
    export type RequestHeaders = {};
    export type ResponseBody = UserDetails;
  }
}

export namespace ByHash {
  /**
   * No description
   * @tags bids, by-hash
   * @name ByHashCarriersAuctionsBidsList
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/
   * @secure
   * @response `200` `PaginatedBidByHashList`
   */
  export namespace ByHashCarriersAuctionsBidsList {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
    };
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedBidByHashList;
  }

  /**
   * No description
   * @tags bids, by-hash
   * @name ByHashCarriersAuctionsBidsCreate
   * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/
   * @secure
   * @response `201` `BidByHash`
   */
  export namespace ByHashCarriersAuctionsBidsCreate {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = BidByHash;
    export type RequestHeaders = {};
    export type ResponseBody = BidByHash;
  }

  /**
   * No description
   * @tags bids, by-hash
   * @name ByHashCarriersAuctionsBidsRetrieve
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/{hash}/
   * @secure
   * @response `200` `BidByHash`
   */
  export namespace ByHashCarriersAuctionsBidsRetrieve {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
      /** Hash key */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BidByHash;
  }

  /**
   * No description
   * @tags bids, by-hash
   * @name ByHashCarriersAuctionsBidsUpdate
   * @request PUT:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/{hash}/
   * @secure
   * @response `200` `UpdateBidByHash`
   */
  export namespace ByHashCarriersAuctionsBidsUpdate {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
      /** Hash key */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBidByHash;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateBidByHash;
  }

  /**
   * No description
   * @tags bids, by-hash
   * @name ByHashCarriersAuctionsBidsPartialUpdate
   * @request PATCH:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/{hash}/
   * @secure
   * @response `200` `UpdateBidByHash`
   */
  export namespace ByHashCarriersAuctionsBidsPartialUpdate {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
      /** Hash key */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedUpdateBidByHash;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateBidByHash;
  }

  /**
   * No description
   * @tags documents, by-hash
   * @name ByHashCarriersAuctionsDocumentsList
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/documents/
   * @secure
   * @response `200` `PaginatedDocumentList`
   */
  export namespace ByHashCarriersAuctionsDocumentsList {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
    };
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedDocumentList;
  }

  /**
   * No description
   * @tags documents, by-hash
   * @name ByHashCarriersAuctionsDocumentsCreate
   * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/documents/
   * @secure
   * @response `201` `Document`
   */
  export namespace ByHashCarriersAuctionsDocumentsCreate {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CarrierDocumentCreate;
    export type RequestHeaders = {};
    export type ResponseBody = Document;
  }

  /**
   * No description
   * @tags documents, by-hash
   * @name ByHashCarriersAuctionsDocumentsRetrieve
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/documents/{id}/
   * @secure
   * @response `200` `Document`
   */
  export namespace ByHashCarriersAuctionsDocumentsRetrieve {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
      /** A unique integer value identifying this document. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Document;
  }

  /**
   * No description
   * @tags messages, by-hash
   * @name ByHashCarriersAuctionsMessagesList
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/messages/
   * @secure
   * @response `200` `PaginatedMessageList`
   */
  export namespace ByHashCarriersAuctionsMessagesList {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
    };
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedMessageList;
  }

  /**
   * No description
   * @tags messages, by-hash
   * @name ByHashCarriersAuctionsMessagesCreate
   * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/messages/
   * @secure
   * @response `201` `Message`
   */
  export namespace ByHashCarriersAuctionsMessagesCreate {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = Message;
    export type RequestHeaders = {};
    export type ResponseBody = Message;
  }

  /**
   * No description
   * @tags messages, by-hash
   * @name ByHashCarriersAuctionsMessagesMarkAsReadCreate
   * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/messages/mark_as_read/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace ByHashCarriersAuctionsMessagesMarkAsReadCreate {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags messages, by-hash
   * @name ByHashCarriersAuctionsMessagesUnreadCountRetrieve
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/messages/unread-count/
   * @secure
   * @response `200` `number`
   */
  export namespace ByHashCarriersAuctionsMessagesUnreadCountRetrieve {
    export type RequestParams = {
      auctionHash: string;
      carrierHash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = number;
  }

  /**
   * No description
   * @tags auctions, by-hash
   * @name ByHashCarriersAuctionsRetrieve
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{hash}/
   * @secure
   * @response `200` `AuctionByHash`
   */
  export namespace ByHashCarriersAuctionsRetrieve {
    export type RequestParams = {
      carrierHash: string;
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionByHash;
  }

  /**
   * No description
   * @tags auctions, by-hash
   * @name ByHashCarriersAuctionsStatusCreate
   * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{hash}/status/
   * @secure
   * @response `200` `AuctionSetParticipantStatus`
   */
  export namespace ByHashCarriersAuctionsStatusCreate {
    export type RequestParams = {
      carrierHash: string;
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = AuctionSetParticipantStatus;
    export type RequestHeaders = {};
    export type ResponseBody = AuctionSetParticipantStatus;
  }

  /**
   * No description
   * @tags transport-details
   * @name ByHashCarriersAuctionsTransportDetailsRetrieve
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{hash}/transport-details/
   * @secure
   * @response `200` `TransportDetailByHash`
   */
  export namespace ByHashCarriersAuctionsTransportDetailsRetrieve {
    export type RequestParams = {
      carrierHash: string;
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TransportDetailByHash;
  }

  /**
   * No description
   * @tags transport-details
   * @name ByHashCarriersAuctionsTransportDetailsCreate
   * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{hash}/transport-details/
   * @secure
   * @response `200` `TransportDetailByHash`
   */
  export namespace ByHashCarriersAuctionsTransportDetailsCreate {
    export type RequestParams = {
      carrierHash: string;
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = TransportDetailByHash;
    export type RequestHeaders = {};
    export type ResponseBody = TransportDetailByHash;
  }

  /**
   * @description This must be a viewset, because i want to use it in carrier by hash router
   * @tags billing, by-hash
   * @name ByHashCarriersBillingDetailsList
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/billing-details/
   * @secure
   * @response `200` `PaginatedCarrierBillingDetailsByHashInputList`
   */
  export namespace ByHashCarriersBillingDetailsList {
    export type RequestParams = {
      carrierHash: string;
    };
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedCarrierBillingDetailsByHashInputList;
  }

  /**
   * @description This must be a viewset, because i want to use it in carrier by hash router
   * @tags billing, by-hash
   * @name ByHashCarriersBillingDetailsCreate
   * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/billing-details/
   * @secure
   * @response `201` `CarrierBillingDetailsByHashOutput`
   */
  export namespace ByHashCarriersBillingDetailsCreate {
    export type RequestParams = {
      carrierHash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CarrierBillingDetailsByHashInput;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierBillingDetailsByHashOutput;
  }

  /**
   * @description This must be a viewset, because i want to use it in carrier by hash router
   * @tags billing, by-hash
   * @name ByHashCarriersBillingDetailsRetrieve
   * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/billing-details/{id}/
   * @secure
   * @response `200` `CarrierBillingDetailsByHashInput`
   */
  export namespace ByHashCarriersBillingDetailsRetrieve {
    export type RequestParams = {
      carrierHash: string;
      /** A unique integer value identifying this Carrier Billing Details. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierBillingDetailsByHashInput;
  }

  /**
   * @description This must be a viewset, because i want to use it in carrier by hash router
   * @tags billing, by-hash
   * @name ByHashCarriersBillingDetailsApproveCreate
   * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/billing-details/{id}/approve/
   * @secure
   * @response `200` `CarrierBillingDetailsByHashOutput`
   */
  export namespace ByHashCarriersBillingDetailsApproveCreate {
    export type RequestParams = {
      carrierHash: string;
      /** A unique integer value identifying this Carrier Billing Details. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierBillingDetailsByHashOutput;
  }

  /**
   * No description
   * @tags carriers, by-hash
   * @name ByHashCarriersRetrieve
   * @request GET:/api/v1/by-hash/carriers/{hash}/
   * @secure
   * @response `200` `CarrierByHash`
   */
  export namespace ByHashCarriersRetrieve {
    export type RequestParams = {
      /** Hash key */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierByHash;
  }

  /**
   * No description
   * @tags carriers, by-hash
   * @name ByHashCarriersUpdate
   * @request PUT:/api/v1/by-hash/carriers/{hash}/
   * @secure
   * @response `200` `CarrierByHash`
   */
  export namespace ByHashCarriersUpdate {
    export type RequestParams = {
      /** Hash key */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CarrierByHash;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierByHash;
  }

  /**
   * No description
   * @tags carriers, by-hash
   * @name ByHashCarriersPartialUpdate
   * @request PATCH:/api/v1/by-hash/carriers/{hash}/
   * @secure
   * @response `200` `CarrierByHash`
   */
  export namespace ByHashCarriersPartialUpdate {
    export type RequestParams = {
      /** Hash key */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedCarrierByHash;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierByHash;
  }
}

export namespace Captcha {
  /**
   * No description
   * @tags core
   * @name CaptchaVerifyCreate
   * @request POST:/api/v1/captcha/verify/
   * @secure
   * @response `200` `RecaptchaVerifyResult`
   */
  export namespace CaptchaVerifyCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CaptchaVerifyCreatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = RecaptchaVerifyResult;
  }
}

export namespace CargoItemUnits {
  /**
   * No description
   * @tags cargo-item-units
   * @name CargoItemUnitsList
   * @request GET:/api/v1/cargo-item-units/
   * @secure
   * @response `200` `PaginatedCargoItemUnitList`
   */
  export namespace CargoItemUnitsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedCargoItemUnitList;
  }

  /**
   * No description
   * @tags cargo-item-units
   * @name CargoItemUnitsRetrieve
   * @request GET:/api/v1/cargo-item-units/{id}/
   * @secure
   * @response `200` `CargoItemUnit`
   */
  export namespace CargoItemUnitsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this cargo item unit. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CargoItemUnit;
  }
}

export namespace CarrierGroups {
  /**
   * No description
   * @tags carriers
   * @name CarrierGroupsList
   * @request GET:/api/v1/carrier-groups/
   * @secure
   * @response `200` `PaginatedCarrierGroupList`
   */
  export namespace CarrierGroupsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedCarrierGroupList;
  }

  /**
   * No description
   * @tags carriers
   * @name CarrierGroupsCreate
   * @request POST:/api/v1/carrier-groups/
   * @secure
   * @response `201` `CarrierGroup`
   */
  export namespace CarrierGroupsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CarrierGroup;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierGroup;
  }

  /**
   * No description
   * @tags carriers
   * @name CarrierGroupsRetrieve
   * @request GET:/api/v1/carrier-groups/{id}/
   * @secure
   * @response `200` `CarrierGroup`
   */
  export namespace CarrierGroupsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this carrier group. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierGroup;
  }

  /**
   * No description
   * @tags carriers
   * @name CarrierGroupsUpdate
   * @request PUT:/api/v1/carrier-groups/{id}/
   * @secure
   * @response `200` `CarrierGroup`
   */
  export namespace CarrierGroupsUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this carrier group. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = CarrierGroup;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierGroup;
  }

  /**
   * No description
   * @tags carriers
   * @name CarrierGroupsPartialUpdate
   * @request PATCH:/api/v1/carrier-groups/{id}/
   * @secure
   * @response `200` `CarrierGroup`
   */
  export namespace CarrierGroupsPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this carrier group. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedCarrierGroup;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierGroup;
  }

  /**
   * No description
   * @tags carriers
   * @name CarrierGroupsDestroy
   * @request DELETE:/api/v1/carrier-groups/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace CarrierGroupsDestroy {
    export type RequestParams = {
      /** A unique integer value identifying this carrier group. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags carriers
   * @name CarrierGroupsAllList
   * @request GET:/api/v1/carrier-groups/all/
   * @secure
   * @response `200` `(CarrierGroupGeneralInfo)[]`
   */
  export namespace CarrierGroupsAllList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierGroupGeneralInfo[];
  }
}

export namespace Carriers {
  /**
   * No description
   * @tags carriers
   * @name CarriersList
   * @request GET:/api/v1/carriers/
   * @secure
   * @response `200` `PaginatedCarrierList`
   */
  export namespace CarriersList {
    export type RequestParams = {};
    export type RequestQuery = {
      city?: string;
      contactEmail?: string;
      country?: string;
      includeDeleted?: boolean;
      includeGlobal?: boolean;
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      postCode?: string;
      /** A search term. */
      search?: string;
      street?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedCarrierList;
  }

  /**
   * No description
   * @tags carriers
   * @name CarriersCreate
   * @request POST:/api/v1/carriers/
   * @secure
   * @response `201` `Carrier`
   */
  export namespace CarriersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Carrier;
    export type RequestHeaders = {};
    export type ResponseBody = Carrier;
  }

  /**
   * No description
   * @tags carriers
   * @name CarriersRetrieve
   * @request GET:/api/v1/carriers/{id}/
   * @secure
   * @response `200` `Carrier`
   */
  export namespace CarriersRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this carrier. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Carrier;
  }

  /**
   * No description
   * @tags carriers
   * @name CarriersUpdate
   * @request PUT:/api/v1/carriers/{id}/
   * @secure
   * @response `200` `Carrier`
   */
  export namespace CarriersUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this carrier. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = Carrier;
    export type RequestHeaders = {};
    export type ResponseBody = Carrier;
  }

  /**
   * No description
   * @tags carriers
   * @name CarriersPartialUpdate
   * @request PATCH:/api/v1/carriers/{id}/
   * @secure
   * @response `200` `Carrier`
   */
  export namespace CarriersPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this carrier. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedCarrier;
    export type RequestHeaders = {};
    export type ResponseBody = Carrier;
  }

  /**
   * No description
   * @tags carriers
   * @name CarriersDestroy
   * @request DELETE:/api/v1/carriers/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace CarriersDestroy {
    export type RequestParams = {
      /** A unique integer value identifying this carrier. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags carriers
   * @name CarriersIsActivePartialUpdate
   * @request PATCH:/api/v1/carriers/{id}/is_active/
   * @secure
   * @response `200` `Carrier`
   */
  export namespace CarriersIsActivePartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this carrier. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedCarrierUpdateIsActive;
    export type RequestHeaders = {};
    export type ResponseBody = Carrier;
  }

  /**
   * No description
   * @tags carriers
   * @name CarriersAllList
   * @request GET:/api/v1/carriers/all/
   * @secure
   * @response `200` `(CarrierGeneralInfo)[]`
   */
  export namespace CarriersAllList {
    export type RequestParams = {};
    export type RequestQuery = {
      city?: string;
      contactEmail?: string;
      country?: string;
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      postCode?: string;
      /** A search term. */
      search?: string;
      street?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CarrierGeneralInfo[];
  }

  /**
   * No description
   * @tags carriers
   * @name CarriersAutocompleteCreate
   * @request POST:/api/v1/carriers/autocomplete/
   * @secure
   * @response `200` `AutocompleteResponse`
   */
  export namespace CarriersAutocompleteCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AutocompleteRequest;
    export type RequestHeaders = {};
    export type ResponseBody = AutocompleteResponse;
  }
}

export namespace Customers {
  /**
   * No description
   * @tags customers
   * @name CustomersCreate
   * @request POST:/api/v1/customers/
   * @secure
   * @response `201` `Subscriber`
   */
  export namespace CustomersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Subscriber;
    export type RequestHeaders = {};
    export type ResponseBody = Subscriber;
  }
}

export namespace Documents {
  /**
   * No description
   * @tags documents
   * @name DocumentsList
   * @request GET:/api/v1/documents/
   * @secure
   * @response `200` `PaginatedDocumentList`
   */
  export namespace DocumentsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedDocumentList;
  }

  /**
   * No description
   * @tags documents
   * @name DocumentsCreate
   * @request POST:/api/v1/documents/
   * @secure
   * @response `201` `Document`
   */
  export namespace DocumentsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DocumentCreate;
    export type RequestHeaders = {};
    export type ResponseBody = Document;
  }

  /**
   * No description
   * @tags documents
   * @name DocumentsRetrieve
   * @request GET:/api/v1/documents/{id}/
   * @secure
   * @response `200` `Document`
   */
  export namespace DocumentsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this document. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Document;
  }

  /**
   * No description
   * @tags documents
   * @name DocumentsUpdate
   * @request PUT:/api/v1/documents/{id}/
   * @secure
   * @response `200` `Document`
   */
  export namespace DocumentsUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this document. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = DocumentUpdate;
    export type RequestHeaders = {};
    export type ResponseBody = Document;
  }

  /**
   * No description
   * @tags documents
   * @name DocumentsPartialUpdate
   * @request PATCH:/api/v1/documents/{id}/
   * @secure
   * @response `200` `DocumentUpdate`
   */
  export namespace DocumentsPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this document. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedDocumentUpdate;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentUpdate;
  }

  /**
   * No description
   * @tags documents
   * @name DocumentsDestroy
   * @request DELETE:/api/v1/documents/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace DocumentsDestroy {
    export type RequestParams = {
      /** A unique integer value identifying this document. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace FeatureFlags {
  /**
   * No description
   * @tags core
   * @name FeatureFlagsStatusRetrieve
   * @request GET:/api/v1/feature-flags/status
   * @secure
   * @response `200` `FeatureFlagsStatus`
   */
  export namespace FeatureFlagsStatusRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FeatureFlagsStatus;
  }
}

export namespace Info {
  /**
   * No description
   * @tags core
   * @name InfoRetrieve
   * @request GET:/api/v1/info/
   * @secure
   * @response `200` `AppInfo`
   */
  export namespace InfoRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AppInfo;
  }
}

export namespace Invitations {
  /**
   * No description
   * @tags invitations
   * @name InvitationsAcceptCreate
   * @request POST:/api/v1/invitations/accept/
   * @secure
   * @response `200` `JWT`
   */
  export namespace InvitationsAcceptCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptInvitation;
    export type RequestHeaders = {};
    export type ResponseBody = JWT;
  }

  /**
   * No description
   * @tags invitations
   * @name InvitationsAcceptByUserCreate
   * @request POST:/api/v1/invitations/accept-by-user/
   * @secure
   * @response `200` `boolean`
   */
  export namespace InvitationsAcceptByUserCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ModifyInvitationByUser;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }

  /**
   * No description
   * @tags invitations
   * @name InvitationsBulkInviteCreate
   * @request POST:/api/v1/invitations/bulk-invite/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace InvitationsBulkInviteCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BulkInvitation;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags invitations
   * @name InvitationsPendingList
   * @request GET:/api/v1/invitations/pending/
   * @secure
   * @response `200` `(Invitation)[]`
   */
  export namespace InvitationsPendingList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Invitation[];
  }

  /**
   * No description
   * @tags invitations
   * @name InvitationsPendingByUserList
   * @request GET:/api/v1/invitations/pending-by-user/
   * @secure
   * @response `200` `(WorkspaceInvitation)[]`
   */
  export namespace InvitationsPendingByUserList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = WorkspaceInvitation[];
  }

  /**
   * No description
   * @tags invitations
   * @name InvitationsRejectByUserCreate
   * @request POST:/api/v1/invitations/reject-by-user/
   * @secure
   * @response `200` `boolean`
   */
  export namespace InvitationsRejectByUserCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ModifyInvitationByUser;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }

  /**
   * No description
   * @tags invitations
   * @name InvitationsRemoveCreate
   * @request POST:/api/v1/invitations/remove/
   * @secure
   * @response `200` `boolean`
   */
  export namespace InvitationsRemoveCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = InvitationsRemoveCreatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }
}

export namespace Locations {
  /**
   * No description
   * @tags locations
   * @name LocationsStaticMapCreate
   * @request POST:/api/v1/locations/static-map/
   * @secure
   * @response `200` `LocationData`
   */
  export namespace LocationsStaticMapCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TranslateLocations;
    export type RequestHeaders = {};
    export type ResponseBody = LocationData;
  }

  /**
   * No description
   * @tags locations
   * @name LocationsTranslateCreate
   * @request POST:/api/v1/locations/translate/
   * @secure
   * @response `200` `LocationData`
   */
  export namespace LocationsTranslateCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TranslateLocations;
    export type RequestHeaders = {};
    export type ResponseBody = LocationData;
  }
}

export namespace Messages {
  /**
   * No description
   * @tags messages
   * @name MessagesList
   * @request GET:/api/v1/messages/
   * @secure
   * @response `200` `PaginatedUserMessageList`
   */
  export namespace MessagesList {
    export type RequestParams = {};
    export type RequestQuery = {
      carrier?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedUserMessageList;
  }

  /**
   * No description
   * @tags messages
   * @name MessagesMarkAsReadCreate
   * @request POST:/api/v1/messages/{id}/mark_as_read/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace MessagesMarkAsReadCreate {
    export type RequestParams = {
      /** A unique integer value identifying this message. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags messages
   * @name MessagesMarkAllAsReadCreate
   * @request POST:/api/v1/messages/mark-all-as-read/
   * @secure
   * @response `200` `string`
   */
  export namespace MessagesMarkAllAsReadCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }

  /**
   * No description
   * @tags messages
   * @name MessagesUnreadCountRetrieve
   * @request GET:/api/v1/messages/unread-count/
   * @secure
   * @response `200` `number`
   */
  export namespace MessagesUnreadCountRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = number;
  }
}

export namespace Notifications {
  /**
   * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
   * @tags notifications
   * @name NotificationsList
   * @request GET:/api/v1/notifications/
   * @secure
   * @response `200` `PaginatedNotificationList`
   */
  export namespace NotificationsList {
    export type RequestParams = {};
    export type RequestQuery = {
      isRead?: boolean;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedNotificationList;
  }

  /**
   * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
   * @tags notifications
   * @name NotificationsRetrieve
   * @request GET:/api/v1/notifications/{id}/
   * @secure
   * @response `200` `Notification`
   */
  export namespace NotificationsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this notification. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Notification;
  }

  /**
   * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
   * @tags notifications
   * @name NotificationsMarkAsReadCreate
   * @request POST:/api/v1/notifications/{id}/mark-as-read/
   * @secure
   * @response `200` `string`
   */
  export namespace NotificationsMarkAsReadCreate {
    export type RequestParams = {
      /** A unique integer value identifying this notification. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }

  /**
   * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
   * @tags notifications
   * @name NotificationsMarkAllAsReadCreate
   * @request POST:/api/v1/notifications/mark-all-as-read/
   * @secure
   * @response `200` `string`
   */
  export namespace NotificationsMarkAllAsReadCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }

  /**
   * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
   * @tags notifications
   * @name NotificationsUserSettingsRetrieve
   * @request GET:/api/v1/notifications/user-settings/
   * @secure
   * @response `200` `UserNotificationSettings`
   */
  export namespace NotificationsUserSettingsRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserNotificationSettings;
  }

  /**
   * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
   * @tags notifications
   * @name NotificationsUserSettingsCreate
   * @request POST:/api/v1/notifications/user-settings/
   * @secure
   * @response `200` `UserNotificationSettings`
   */
  export namespace NotificationsUserSettingsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UserNotificationSettings;
    export type RequestHeaders = {};
    export type ResponseBody = UserNotificationSettings;
  }
}

export namespace Options {
  /**
   * No description
   * @tags options
   * @name OptionsCargoLoadTypesRetrieve
   * @request GET:/api/v1/options/cargo-load-types/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsCargoLoadTypesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsCargoTypesRetrieve
   * @request GET:/api/v1/options/cargo-types/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsCargoTypesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsCountriesRetrieve
   * @request GET:/api/v1/options/countries/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsCountriesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsCurrenciesRetrieve
   * @request GET:/api/v1/options/currencies/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsCurrenciesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsFeatureSwitchTypesList
   * @request GET:/api/v1/options/feature-switch-types/
   * @secure
   * @response `200` `(FeatureSwitch)[]`
   */
  export namespace OptionsFeatureSwitchTypesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FeatureSwitch[];
  }

  /**
   * No description
   * @tags options
   * @name OptionsLengthUnitRetrieve
   * @request GET:/api/v1/options/length-unit/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsLengthUnitRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsMessageEventsRetrieve
   * @request GET:/api/v1/options/message-events/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsMessageEventsRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsRouteTypesRetrieve
   * @request GET:/api/v1/options/route-types/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsRouteTypesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsSpecialRequirementsRetrieve
   * @request GET:/api/v1/options/special-requirements/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsSpecialRequirementsRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsStackCountRetrieve
   * @request GET:/api/v1/options/stack-count/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsStackCountRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsTransportTypesRetrieve
   * @request GET:/api/v1/options/transport-types/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsTransportTypesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsTransportationModesRetrieve
   * @request GET:/api/v1/options/transportation-modes/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsTransportationModesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }

  /**
   * No description
   * @tags options
   * @name OptionsWeightUnitRetrieve
   * @request GET:/api/v1/options/weight-unit/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsWeightUnitRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

export namespace Payments {
  /**
   * No description
   * @tags payments
   * @name PaymentsCheckoutSessionCreate
   * @request POST:/api/v1/payments/checkout/session/
   * @secure
   * @response `200` `CheckoutResponseData`
   */
  export namespace PaymentsCheckoutSessionCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CheckoutRequestData;
    export type RequestHeaders = {};
    export type ResponseBody = CheckoutResponseData;
  }
}

export namespace Portal {
  /**
   * @description Get company details based on VAT ID and country code. For getting also insurance status, use `insurance_status_check=true. If Portal has not cached the status for the company, it makes an additional API call to the Coface API (which can be paid and slow).
   * @tags core
   * @name PortalCompanyInfoCreate
   * @request POST:/api/v1/portal/company-info/
   * @secure
   * @response `200` `PortalCompanyInfoResponse`
   */
  export namespace PortalCompanyInfoCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CompanyInfoPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PortalCompanyInfoResponse;
  }

  /**
   * @description Get company details based on VAT ID or company name and country code from Coface.
   * @tags core
   * @name PortalExternalCompanyInfoCreate
   * @request POST:/api/v1/portal/external-company-info/
   * @secure
   * @response `200` `(CofaceCompanyInfoResponse)[]`
   */
  export namespace PortalExternalCompanyInfoCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CompanyInfoPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CofaceCompanyInfoResponse[];
  }
}

export namespace Privacy {
  /**
   * No description
   * @tags privacy
   * @name PrivacyConsentsRetrieve
   * @request GET:/api/v1/privacy/consents/{token}/
   * @secure
   * @response `200` `ConsentByTokenSetOutput`
   */
  export namespace PrivacyConsentsRetrieve {
    export type RequestParams = {
      token: string;
    };
    export type RequestQuery = {
      consentTypes?: PrivacyConsentsRetrieveParams1ConsentTypesEnum[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ConsentByTokenSetOutput;
  }

  /**
   * No description
   * @tags privacy
   * @name PrivacyConsentsCreate
   * @request POST:/api/v1/privacy/consents/{token}/
   * @secure
   * @response `200` `ConsentByTokenSetOutput`
   */
  export namespace PrivacyConsentsCreate {
    export type RequestParams = {
      token: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PrivacyConsentsCreatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = ConsentByTokenSetOutput;
  }

  /**
   * No description
   * @tags privacy
   * @name PrivacyConsentsSettingsList
   * @request GET:/api/v1/privacy/consents/{token}/settings/
   * @secure
   * @response `200` `(ConsentsSettings)[]`
   */
  export namespace PrivacyConsentsSettingsList {
    export type RequestParams = {
      token: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ConsentsSettings[];
  }

  /**
   * No description
   * @tags privacy
   * @name PrivacyConsentsSettingsCreate
   * @request POST:/api/v1/privacy/consents/{token}/settings/
   * @secure
   * @response `200` `(ConsentsSettings)[]`
   */
  export namespace PrivacyConsentsSettingsCreate {
    export type RequestParams = {
      token: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PrivacyConsentsSettingsCreatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = ConsentsSettings[];
  }

  /**
   * No description
   * @tags privacy
   * @name PrivacyConsentsTermsRetrieve
   * @request GET:/api/v1/privacy/consents/{token}/terms/
   * @secure
   * @response `200` `TermsByTokenOutput`
   */
  export namespace PrivacyConsentsTermsRetrieve {
    export type RequestParams = {
      token: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TermsByTokenOutput;
  }

  /**
   * No description
   * @tags privacy
   * @name PrivacyConsentsTermsCreate
   * @request POST:/api/v1/privacy/consents/{token}/terms/
   * @secure
   * @response `200` `TermsByTokenOutput`
   */
  export namespace PrivacyConsentsTermsCreate {
    export type RequestParams = {
      token: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PrivacyConsentsTermsCreatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = TermsByTokenOutput;
  }

  /**
   * No description
   * @tags privacy
   * @name PrivacyConsentsRequestResendCreate
   * @request POST:/api/v1/privacy/consents/request/resend/
   * @secure
   * @response `204` `any`
   * @response `400` `ConsentInfo`
   */
  export namespace PrivacyConsentsRequestResendCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ResendConsentRequestInput;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }

  /**
   * No description
   * @tags privacy
   * @name PrivacyTermsAndConditionsList
   * @request GET:/api/v1/privacy/terms-and-conditions/
   * @secure
   * @response `200` `PaginatedTermsAndConditionsList`
   */
  export namespace PrivacyTermsAndConditionsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedTermsAndConditionsList;
  }

  /**
   * No description
   * @tags privacy
   * @name PrivacyTermsAndConditionsRetrieve
   * @request GET:/api/v1/privacy/terms-and-conditions/{id}/
   * @secure
   * @response `200` `TermsAndConditions`
   */
  export namespace PrivacyTermsAndConditionsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this terms and conditions. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TermsAndConditions;
  }
}

export namespace Push {
  /**
   * No description
   * @tags push
   * @name PushAuthorizeCreate
   * @request POST:/api/v1/push/authorize/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace PushAuthorizeCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags push
   * @name PushAuthorizeByHashCreate
   * @request POST:/api/v1/push/authorize-by-hash/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace PushAuthorizeByHashCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Roles {
  /**
   * No description
   * @tags roles
   * @name RolesList
   * @request GET:/api/v1/roles/
   * @secure
   * @response `200` `(Role)[]`
   */
  export namespace RolesList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Role[];
  }

  /**
   * No description
   * @tags roles
   * @name RolesRetrieve
   * @request GET:/api/v1/roles/{id}/
   * @secure
   * @response `200` `Role`
   */
  export namespace RolesRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this role. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Role;
  }
}

export namespace Schema {
  /**
   * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   * @tags schema
   * @name SchemaRetrieve
   * @request GET:/api/v1/schema/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace SchemaRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      format?: SchemaRetrieveParams1FormatEnum;
      lang?: SchemaRetrieveParams1LangEnum;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

export namespace Stats {
  /**
   * No description
   * @tags workspace
   * @name StatsCompanyRetrieve
   * @request GET:/api/v1/stats/company/
   * @secure
   * @response `200` `CompanyStats`
   */
  export namespace StatsCompanyRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CompanyStats;
  }

  /**
   * No description
   * @tags workspace
   * @name StatsTopCarriersRetrieve
   * @request GET:/api/v1/stats/top-carriers/
   * @secure
   * @response `200` `TopCarriersStats`
   */
  export namespace StatsTopCarriersRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      count?: number;
      /** @format date */
      createdAtAfter?: string;
      /** @format date */
      createdAtBefore?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TopCarriersStats;
  }

  /**
   * No description
   * @tags workspace
   * @name StatsTopCountriesList
   * @request GET:/api/v1/stats/top-countries/
   * @secure
   * @response `200` `(TopCountriesStats)[]`
   */
  export namespace StatsTopCountriesList {
    export type RequestParams = {};
    export type RequestQuery = {
      count?: number;
      /** @format date */
      createdAtAfter?: string;
      /** @format date */
      createdAtBefore?: string;
      waypointStatsType?: StatsTopCountriesListParams1WaypointStatsTypeEnum;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TopCountriesStats[];
  }
}

export namespace TransportRequests {
  /**
   * No description
   * @tags transport-requests
   * @name TransportRequestsCreate
   * @request POST:/api/v1/transport-requests/
   * @secure
   * @response `201` `TransportRequest`
   */
  export namespace TransportRequestsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TransportRequest;
    export type RequestHeaders = {};
    export type ResponseBody = TransportRequest;
  }

  /**
   * No description
   * @tags transport-requests
   * @name TransportRequestsUpdate
   * @request PUT:/api/v1/transport-requests/{id}/
   * @secure
   * @response `200` `TransportRequest`
   */
  export namespace TransportRequestsUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this transport request. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = TransportRequest;
    export type RequestHeaders = {};
    export type ResponseBody = TransportRequest;
  }

  /**
   * No description
   * @tags transport-requests
   * @name TransportRequestsPartialUpdate
   * @request PATCH:/api/v1/transport-requests/{id}/
   * @secure
   * @response `200` `TransportRequest`
   */
  export namespace TransportRequestsPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this transport request. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedTransportRequest;
    export type RequestHeaders = {};
    export type ResponseBody = TransportRequest;
  }

  /**
   * No description
   * @tags transport-requests
   * @name TransportRequestsUpdateActiveCreate
   * @request POST:/api/v1/transport-requests/{id}/update_active/
   * @secure
   * @response `200` `TransportRequest`
   */
  export namespace TransportRequestsUpdateActiveCreate {
    export type RequestParams = {
      /** A unique integer value identifying this transport request. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedTransportRequest;
    export type RequestHeaders = {};
    export type ResponseBody = TransportRequest;
  }

  /**
   * No description
   * @tags transport-requests
   * @name TransportRequestsGetPriceFromPricerCreate
   * @request POST:/api/v1/transport-requests/get-price-from-pricer/
   * @secure
   * @response `200` `PricerResult`
   */
  export namespace TransportRequestsGetPriceFromPricerCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TransportRequest;
    export type RequestHeaders = {};
    export type ResponseBody = PricerResult;
  }

  /**
   * @description Validates the first step of a multi-step process. Parameters: request: The incoming request object. *args: Additional positional arguments. **kwargs: Additional keyword arguments. Returns: Response: A Response object containing serializer errors merged with custom one or serialized data.
   * @tags transport-requests
   * @name TransportRequestsValidateFirstStepCreate
   * @request POST:/api/v1/transport-requests/validate-first-step/
   * @secure
   * @response `200` `TransportOrTransportRequestFirstStep`
   */
  export namespace TransportRequestsValidateFirstStepCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TransportOrTransportRequestFirstStep;
    export type RequestHeaders = {};
    export type ResponseBody = TransportOrTransportRequestFirstStep;
  }

  /**
   * No description
   * @tags transport-requests
   * @name TransportRequestsValidateSecondStepCreate
   * @request POST:/api/v1/transport-requests/validate-second-step/
   * @secure
   * @response `200` `TransportOrTransportRequestSecondStep`
   */
  export namespace TransportRequestsValidateSecondStepCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TransportOrTransportRequestSecondStep;
    export type RequestHeaders = {};
    export type ResponseBody = TransportOrTransportRequestSecondStep;
  }

  /**
   * No description
   * @tags transport-requests
   * @name TransportRequestsValidateThirdStepCreate
   * @request POST:/api/v1/transport-requests/validate-third-step/
   * @secure
   * @response `200` `TransportRequestThirdStep`
   */
  export namespace TransportRequestsValidateThirdStepCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TransportRequestThirdStep;
    export type RequestHeaders = {};
    export type ResponseBody = TransportRequestThirdStep;
  }
}

export namespace TransportStats {
  /**
   * No description
   * @tags transport-stats
   * @name TransportStatsOverviewRetrieve
   * @request GET:/api/v1/transport-stats/overview/
   * @secure
   * @response `200` `TransportOverviewStats`
   */
  export namespace TransportStatsOverviewRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Auction ID */
      auctionId?: number;
      carrier?: string;
      costCenter?: string;
      /** @format date-time */
      createdAtAfter?: string;
      /** @format date-time */
      createdAtBefore?: string;
      createdBy?: number;
      /**
       * Currency
       *
       * * `EUR` - EUR
       * * `USD` - USD
       * * `CZK` - CZK
       * * `HUF` - HUF
       * * `PLN` - PLN
       * * `RON` - RON
       * * `GBP` - GBP
       * * `BGN` - BGN
       * * `NOK` - NOK
       * * `DKK` - DKK
       * * `SEK` - SEK
       * * `ISK` - ISK
       * * `CHF` - CHF
       */
      currency?: CurrencyEnum;
      deliveryCity?: string;
      deliveryCountry?: string;
      /** @format date-time */
      deliveryDateAfter?: string;
      /** @format date-time */
      deliveryDateBefore?: string;
      deliveryName?: string;
      deliveryPostCode?: string;
      deliveryStreet?: string;
      driverName?: string;
      /**
       * Load type
       *
       * * `FTL` - Full Truck Load
       * * `LTL` - Less than Truck Load
       * * `FCL` - Full Container Load
       * * `LCL` - Less than Container Load
       * * `PARCEL` - Parcel
       * * `NOT_SPECIFIED` - Not specified
       */
      loadType?: CargoLoadTypeEnum[];
      maxWeightKg?: number;
      minWeightKg?: number;
      /**
       * Ordering
       *
       * * `created_at` - Created at
       * * `-created_at` - Created at (descending)
       */
      order?: TransportStatsOverviewRetrieveParams1OrderEnum[];
      /** Which field to use when ordering the results. */
      ordering?: string;
      pickupCity?: string;
      pickupCountry?: string;
      /** @format date-time */
      pickupDateAfter?: string;
      /** @format date-time */
      pickupDateBefore?: string;
      pickupName?: string;
      pickupPostCode?: string;
      pickupStreet?: string;
      plateNumber?: string;
      /** @format date-time */
      publishedAtAfter?: string;
      /** @format date-time */
      publishedAtBefore?: string;
      reference?: string;
      /**
       * Request type
       *
       * * `FROZEN_GOODS` - Frozen goods
       * * `OVERSIZED_LOAD` - Oversized load
       * * `BULK_MATERIAL` - Bulk material
       * * `LIQUIDS` - Liquids
       * * `VEHICLES` - Vehicles
       * * `ADR` - ADR
       * * `OTHER` - Other
       * * `NOT_SPECIFIED` - Not specified
       */
      requestType?: TransportStatsOverviewRetrieveParams1RequestTypeEnum;
      responsibleUser?: number;
      /**
       * Route type
       *
       * * `ONE_WAY` - One way
       * * `ROUND_TRIP` - Round trip
       */
      routeType?: RouteTypeEnum;
      /** A search term. */
      search?: string;
      /**
       * Transport status
       *
       * * `PROCESSING` - Processing
       * * `FINISHED` - Finished
       * * `CANCELED` - Canceled
       * * `CANCELED_BY_CARRIER` - Canceled by carrier
       */
      transportStatus?: TransportStatusEnum;
      /**
       * Transport type
       *
       * * `SPOT` - Spot transport
       * * `CONTRACTED` - Contracted transport
       */
      transportType?: TransportTypeEnum;
      /**
       * Transportation mode
       *
       * * `ROAD` - Road
       * * `AIR` - Air
       * * `SEA` - Sea
       * * `RAIL` - Rail
       * * `INTERMODAL` - Intermodal
       */
      transportationMode?: TransportationModeEnum;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TransportOverviewStats;
  }
}

export namespace Transports {
  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsList
   * @request GET:/api/v1/transports/
   * @secure
   * @response `200` `PaginatedTransportListList`
   */
  export namespace TransportsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Auction ID */
      auctionId?: number;
      carrier?: string;
      costCenter?: string;
      /** @format date-time */
      createdAtAfter?: string;
      /** @format date-time */
      createdAtBefore?: string;
      createdBy?: number;
      /**
       * Currency
       *
       * * `EUR` - EUR
       * * `USD` - USD
       * * `CZK` - CZK
       * * `HUF` - HUF
       * * `PLN` - PLN
       * * `RON` - RON
       * * `GBP` - GBP
       * * `BGN` - BGN
       * * `NOK` - NOK
       * * `DKK` - DKK
       * * `SEK` - SEK
       * * `ISK` - ISK
       * * `CHF` - CHF
       */
      currency?: CurrencyEnum;
      deliveryCity?: string;
      deliveryCountry?: string;
      /** @format date-time */
      deliveryDateAfter?: string;
      /** @format date-time */
      deliveryDateBefore?: string;
      deliveryName?: string;
      deliveryPostCode?: string;
      deliveryStreet?: string;
      driverName?: string;
      /**
       * Load type
       *
       * * `FTL` - Full Truck Load
       * * `LTL` - Less than Truck Load
       * * `FCL` - Full Container Load
       * * `LCL` - Less than Container Load
       * * `PARCEL` - Parcel
       * * `NOT_SPECIFIED` - Not specified
       */
      loadType?: CargoLoadTypeEnum[];
      maxWeightKg?: number;
      minWeightKg?: number;
      /**
       * Ordering
       *
       * * `created_at` - Created at
       * * `-created_at` - Created at (descending)
       */
      order?: TransportsListParams1OrderEnum[];
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      pickupCity?: string;
      pickupCountry?: string;
      /** @format date-time */
      pickupDateAfter?: string;
      /** @format date-time */
      pickupDateBefore?: string;
      pickupName?: string;
      pickupPostCode?: string;
      pickupStreet?: string;
      plateNumber?: string;
      /** @format date-time */
      publishedAtAfter?: string;
      /** @format date-time */
      publishedAtBefore?: string;
      reference?: string;
      /**
       * Request type
       *
       * * `FROZEN_GOODS` - Frozen goods
       * * `OVERSIZED_LOAD` - Oversized load
       * * `BULK_MATERIAL` - Bulk material
       * * `LIQUIDS` - Liquids
       * * `VEHICLES` - Vehicles
       * * `ADR` - ADR
       * * `OTHER` - Other
       * * `NOT_SPECIFIED` - Not specified
       */
      requestType?: TransportsListParams1RequestTypeEnum;
      responsibleUser?: number;
      /**
       * Route type
       *
       * * `ONE_WAY` - One way
       * * `ROUND_TRIP` - Round trip
       */
      routeType?: RouteTypeEnum;
      /** A search term. */
      search?: string;
      /**
       * Transport status
       *
       * * `PROCESSING` - Processing
       * * `FINISHED` - Finished
       * * `CANCELED` - Canceled
       * * `CANCELED_BY_CARRIER` - Canceled by carrier
       */
      transportStatus?: TransportStatusEnum;
      /**
       * Transport type
       *
       * * `SPOT` - Spot transport
       * * `CONTRACTED` - Contracted transport
       */
      transportType?: TransportTypeEnum;
      /**
       * Transportation mode
       *
       * * `ROAD` - Road
       * * `AIR` - Air
       * * `SEA` - Sea
       * * `RAIL` - Rail
       * * `INTERMODAL` - Intermodal
       */
      transportationMode?: TransportationModeEnum;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedTransportListList;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsRetrieve
   * @request GET:/api/v1/transports/{id}/
   * @secure
   * @response `200` `Transport`
   */
  export namespace TransportsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this transport. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Transport;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsUpdate
   * @request PUT:/api/v1/transports/{id}/
   * @secure
   * @response `200` `Transport`
   */
  export namespace TransportsUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this transport. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = Transport;
    export type RequestHeaders = {};
    export type ResponseBody = Transport;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsPartialUpdate
   * @request PATCH:/api/v1/transports/{id}/
   * @secure
   * @response `200` `Transport`
   */
  export namespace TransportsPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this transport. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedTransport;
    export type RequestHeaders = {};
    export type ResponseBody = Transport;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsDestroy
   * @request DELETE:/api/v1/transports/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace TransportsDestroy {
    export type RequestParams = {
      /** A unique integer value identifying this transport. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsStatusCreate
   * @request POST:/api/v1/transports/{id}/status/
   * @secure
   * @response `200` `TransportUpdateStatus`
   */
  export namespace TransportsStatusCreate {
    export type RequestParams = {
      /** A unique integer value identifying this transport. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = TransportUpdateStatus;
    export type RequestHeaders = {};
    export type ResponseBody = TransportUpdateStatus;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transport-details
   * @name TransportsTransportDetailsRetrieve
   * @request GET:/api/v1/transports/{id}/transport-details/
   * @secure
   * @response `200` `TransportDetail`
   */
  export namespace TransportsTransportDetailsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this transport. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TransportDetail;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transport-details
   * @name TransportsTransportDetailsCreate
   * @request POST:/api/v1/transports/{id}/transport-details/
   * @secure
   * @response `200` `TransportDetail`
   */
  export namespace TransportsTransportDetailsCreate {
    export type RequestParams = {
      /** A unique integer value identifying this transport. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = TransportDetail;
    export type RequestHeaders = {};
    export type ResponseBody = TransportDetail;
  }

  /**
   * No description
   * @tags documents
   * @name TransportsDocumentsList
   * @request GET:/api/v1/transports/{transport_pk}/documents/
   * @secure
   * @response `200` `PaginatedDocumentList`
   */
  export namespace TransportsDocumentsList {
    export type RequestParams = {
      transportPk: number;
    };
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedDocumentList;
  }

  /**
   * No description
   * @tags messages
   * @name TransportsMessagesList
   * @request GET:/api/v1/transports/{transport_pk}/messages/
   * @secure
   * @response `200` `PaginatedMessageList`
   */
  export namespace TransportsMessagesList {
    export type RequestParams = {
      transportPk: number;
    };
    export type RequestQuery = {
      carrier?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedMessageList;
  }

  /**
   * No description
   * @tags messages
   * @name TransportsMessagesCreate
   * @request POST:/api/v1/transports/{transport_pk}/messages/
   * @secure
   * @response `201` `Message`
   */
  export namespace TransportsMessagesCreate {
    export type RequestParams = {
      transportPk: number;
    };
    export type RequestQuery = {};
    export type RequestBody = Message;
    export type RequestHeaders = {};
    export type ResponseBody = Message;
  }

  /**
   * No description
   * @tags messages
   * @name TransportsMessagesMarkAsReadCreate
   * @request POST:/api/v1/transports/{transport_pk}/messages/mark-as-read/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace TransportsMessagesMarkAsReadCreate {
    export type RequestParams = {
      transportPk: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags messages
   * @name TransportsMessagesUnreadCountRetrieve
   * @request GET:/api/v1/transports/{transport_pk}/messages/unread-count/
   * @secure
   * @response `200` `number`
   */
  export namespace TransportsMessagesUnreadCountRetrieve {
    export type RequestParams = {
      transportPk: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = number;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsValidateFirstStepCreate
   * @request POST:/api/v1/transports/validate-first-step/
   * @secure
   * @response `200` `TransportOrTransportRequestFirstStep`
   */
  export namespace TransportsValidateFirstStepCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TransportOrTransportRequestFirstStep;
    export type RequestHeaders = {};
    export type ResponseBody = TransportOrTransportRequestFirstStep;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsValidateSecondStepCreate
   * @request POST:/api/v1/transports/validate-second-step/
   * @secure
   * @response `200` `TransportOrTransportRequestSecondStep`
   */
  export namespace TransportsValidateSecondStepCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TransportOrTransportRequestSecondStep;
    export type RequestHeaders = {};
    export type ResponseBody = TransportOrTransportRequestSecondStep;
  }

  /**
   * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
   * @tags transports
   * @name TransportsValidateThirdStepCreate
   * @request POST:/api/v1/transports/validate-third-step/
   * @secure
   * @response `200` `TransportThirdStep`
   */
  export namespace TransportsValidateThirdStepCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TransportThirdStep;
    export type RequestHeaders = {};
    export type ResponseBody = TransportThirdStep;
  }
}

export namespace Users {
  /**
   * No description
   * @tags users
   * @name UsersList
   * @request GET:/api/v1/users/
   * @secure
   * @response `200` `PaginatedUserList`
   */
  export namespace UsersList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Number of results to return per page. */
      limit?: number;
      /** The initial index from which to return the results. */
      offset?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedUserList;
  }

  /**
   * No description
   * @tags users
   * @name UsersRetrieve
   * @request GET:/api/v1/users/{id}/
   * @secure
   * @response `200` `User`
   */
  export namespace UsersRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this user. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }

  /**
   * No description
   * @tags users
   * @name UsersUpdate
   * @request PUT:/api/v1/users/{id}/
   * @secure
   * @response `200` `User`
   */
  export namespace UsersUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this user. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = User;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }

  /**
   * No description
   * @tags users
   * @name UsersPartialUpdate
   * @request PATCH:/api/v1/users/{id}/
   * @secure
   * @response `200` `User`
   */
  export namespace UsersPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this user. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedUser;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }

  /**
   * No description
   * @tags users
   * @name UsersDestroy
   * @request DELETE:/api/v1/users/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace UsersDestroy {
    export type RequestParams = {
      /** A unique integer value identifying this user. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags users
   * @name UsersPermissionsRetrieve
   * @request GET:/api/v1/users/{id}/permissions/
   * @secure
   * @response `200` `UserPermissions`
   */
  export namespace UsersPermissionsRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this user. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserPermissions;
  }

  /**
   * No description
   * @tags users
   * @name UsersAllList
   * @request GET:/api/v1/users/all/
   * @secure
   * @response `200` `(UserGeneralInfo)[]`
   */
  export namespace UsersAllList {
    export type RequestParams = {};
    export type RequestQuery = {
      excludeRoles?: string[];
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** A search term. */
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserGeneralInfo[];
  }
}

export namespace Waypoints {
  /**
   * No description
   * @tags waypoints
   * @name WaypointsAutocompleteCreate
   * @request POST:/api/v1/waypoints/autocomplete/
   * @secure
   * @response `200` `AutocompleteResponse`
   */
  export namespace WaypointsAutocompleteCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = WaypointAutocompleteRequest;
    export type RequestHeaders = {};
    export type ResponseBody = AutocompleteResponse;
  }
}

export namespace Webhooks {
  /**
   * @description Handle POST request from Brevo webhook
   * @tags webhooks
   * @name WebhooksBrevoCreate
   * @request POST:/api/v1/webhooks/brevo/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace WebhooksBrevoCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * @description Handle POST request from Mailgun webhook
   * @tags webhooks
   * @name WebhooksMailgunCreate
   * @request POST:/api/v1/webhooks/mailgun/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace WebhooksMailgunCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags webhooks
   * @name WebhooksStripeCreate
   * @request POST:/api/v1/webhooks/stripe/
   * @secure
   * @response `200` `void` No response body
   */
  export namespace WebhooksStripeCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Workspace {
  /**
   * No description
   * @tags workspace
   * @name WorkspaceCreate
   * @request POST:/api/v1/workspace/
   * @secure
   * @response `201` `Workspace`
   */
  export namespace WorkspaceCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Workspace;
    export type RequestHeaders = {};
    export type ResponseBody = Workspace;
  }

  /**
   * No description
   * @tags workspace
   * @name WorkspaceRetrieve
   * @request GET:/api/v1/workspace/{id}/
   * @secure
   * @response `200` `Workspace`
   */
  export namespace WorkspaceRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this workspace. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Workspace;
  }

  /**
   * No description
   * @tags workspace
   * @name WorkspaceUpdate
   * @request PUT:/api/v1/workspace/{id}/
   * @secure
   * @response `200` `Workspace`
   */
  export namespace WorkspaceUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this workspace. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = WorkspaceUpdate;
    export type RequestHeaders = {};
    export type ResponseBody = Workspace;
  }

  /**
   * No description
   * @tags workspace
   * @name WorkspacePartialUpdate
   * @request PATCH:/api/v1/workspace/{id}/
   * @secure
   * @response `200` `Workspace`
   */
  export namespace WorkspacePartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this workspace. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedWorkspaceUpdate;
    export type RequestHeaders = {};
    export type ResponseBody = Workspace;
  }

  /**
   * No description
   * @tags workspace
   * @name WorkspaceAssignTrialPackageCreate
   * @request POST:/api/v1/workspace/{id}/assign-trial-package/
   * @secure
   * @response `200` `Workspace`
   */
  export namespace WorkspaceAssignTrialPackageCreate {
    export type RequestParams = {
      /** A unique integer value identifying this workspace. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Workspace;
  }
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Transportly Bidding API
 * @version 1.0.0 (v1)
 *
 * Documentation of API endpoints of Transportly Bidding
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  addresses = {
    /**
     * No description
     *
     * @tags addresses
     * @name AddressesList
     * @request GET:/api/v1/addresses/
     * @secure
     * @response `200` `PaginatedAddressList`
     */
    addressesList: (query: AddressesListParams, params: RequestParams = {}) =>
      this.request<PaginatedAddressList, any>({
        path: `/api/v1/addresses/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags addresses
     * @name AddressesCreate
     * @request POST:/api/v1/addresses/
     * @secure
     * @response `201` `Address`
     */
    addressesCreate: (data: Address, params: RequestParams = {}) =>
      this.request<Address, any>({
        path: `/api/v1/addresses/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags addresses
     * @name AddressesRetrieve
     * @request GET:/api/v1/addresses/{id}/
     * @secure
     * @response `200` `Address`
     */
    addressesRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Address, any>({
        path: `/api/v1/addresses/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags addresses
     * @name AddressesUpdate
     * @request PUT:/api/v1/addresses/{id}/
     * @secure
     * @response `200` `Address`
     */
    addressesUpdate: (id: number, data: Address, params: RequestParams = {}) =>
      this.request<Address, any>({
        path: `/api/v1/addresses/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags addresses
     * @name AddressesPartialUpdate
     * @request PATCH:/api/v1/addresses/{id}/
     * @secure
     * @response `200` `Address`
     */
    addressesPartialUpdate: (id: number, data: PatchedAddress, params: RequestParams = {}) =>
      this.request<Address, any>({
        path: `/api/v1/addresses/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags addresses
     * @name AddressesDestroy
     * @request DELETE:/api/v1/addresses/{id}/
     * @secure
     * @response `204` `void` No response body
     */
    addressesDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/addresses/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  auctionStats = {
    /**
     * No description
     *
     * @tags auction-stats
     * @name AuctionStatsOverviewRetrieve
     * @request GET:/api/v1/auction-stats/overview/
     * @secure
     * @response `200` `AuctionOverviewStats`
     */
    auctionStatsOverviewRetrieve: (query: AuctionStatsOverviewRetrieveParams, params: RequestParams = {}) =>
      this.request<AuctionOverviewStats, any>({
        path: `/api/v1/auction-stats/overview/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  auctions = {
    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsList
     * @request GET:/api/v1/auctions/
     * @secure
     * @response `200` `PaginatedAuctionListList`
     */
    auctionsList: (query: AuctionsListParams, params: RequestParams = {}) =>
      this.request<PaginatedAuctionListList, any>({
        path: `/api/v1/auctions/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsCreate
     * @request POST:/api/v1/auctions/
     * @secure
     * @response `201` `Auction`
     */
    auctionsCreate: (data: Auction, params: RequestParams = {}) =>
      this.request<Auction, any>({
        path: `/api/v1/auctions/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for Bid model just for logged in user.
     *
     * @tags bids
     * @name AuctionsBidsList
     * @request GET:/api/v1/auctions/{auction_pk}/bids/
     * @secure
     * @response `200` `(Bid)[]`
     */
    auctionsBidsList: ({ auctionPk, ...query }: AuctionsBidsListParams, params: RequestParams = {}) =>
      this.request<Bid[], any>({
        path: `/api/v1/auctions/${auctionPk}/bids/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for Bid model just for logged in user.
     *
     * @tags bids
     * @name AuctionsBidsRetrieve
     * @request GET:/api/v1/auctions/{auction_pk}/bids/{id}/
     * @secure
     * @response `200` `Bid`
     */
    auctionsBidsRetrieve: (auctionPk: number, id: number, params: RequestParams = {}) =>
      this.request<Bid, any>({
        path: `/api/v1/auctions/${auctionPk}/bids/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for Bid model just for logged in user.
     *
     * @tags bids
     * @name AuctionsBidsUpdate
     * @request PUT:/api/v1/auctions/{auction_pk}/bids/{id}/
     * @secure
     * @response `200` `UpdateBid`
     */
    auctionsBidsUpdate: (auctionPk: number, id: number, data: UpdateBid, params: RequestParams = {}) =>
      this.request<UpdateBid, any>({
        path: `/api/v1/auctions/${auctionPk}/bids/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for Bid model just for logged in user.
     *
     * @tags bids
     * @name AuctionsBidsPartialUpdate
     * @request PATCH:/api/v1/auctions/{auction_pk}/bids/{id}/
     * @secure
     * @response `200` `UpdateBid`
     */
    auctionsBidsPartialUpdate: (auctionPk: number, id: number, data: PatchedUpdateBid, params: RequestParams = {}) =>
      this.request<UpdateBid, any>({
        path: `/api/v1/auctions/${auctionPk}/bids/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name AuctionsDocumentsList
     * @request GET:/api/v1/auctions/{auction_pk}/documents/
     * @secure
     * @response `200` `PaginatedDocumentList`
     */
    auctionsDocumentsList: ({ auctionPk, ...query }: AuctionsDocumentsListParams, params: RequestParams = {}) =>
      this.request<PaginatedDocumentList, any>({
        path: `/api/v1/auctions/${auctionPk}/documents/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name AuctionsMessagesList
     * @request GET:/api/v1/auctions/{auction_pk}/messages/
     * @secure
     * @response `200` `PaginatedMessageList`
     */
    auctionsMessagesList: ({ auctionPk, ...query }: AuctionsMessagesListParams, params: RequestParams = {}) =>
      this.request<PaginatedMessageList, any>({
        path: `/api/v1/auctions/${auctionPk}/messages/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name AuctionsMessagesCreate
     * @request POST:/api/v1/auctions/{auction_pk}/messages/
     * @secure
     * @response `201` `Message`
     */
    auctionsMessagesCreate: (auctionPk: number, data: Message, params: RequestParams = {}) =>
      this.request<Message, any>({
        path: `/api/v1/auctions/${auctionPk}/messages/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name AuctionsMessagesMarkAsReadCreate
     * @request POST:/api/v1/auctions/{auction_pk}/messages/mark-as-read/
     * @secure
     * @response `200` `MarkAsReadMessage`
     */
    auctionsMessagesMarkAsReadCreate: (auctionPk: number, data: MarkAsReadMessage, params: RequestParams = {}) =>
      this.request<MarkAsReadMessage, any>({
        path: `/api/v1/auctions/${auctionPk}/messages/mark-as-read/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsParticipantsList
     * @request GET:/api/v1/auctions/{auction_pk}/participants/
     * @secure
     * @response `200` `(AuctionParticipant)[]`
     */
    auctionsParticipantsList: ({ auctionPk, ...query }: AuctionsParticipantsListParams, params: RequestParams = {}) =>
      this.request<AuctionParticipant[], any>({
        path: `/api/v1/auctions/${auctionPk}/participants/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsParticipantsRetrieve
     * @request GET:/api/v1/auctions/{auction_pk}/participants/{id}/
     * @secure
     * @response `200` `AuctionParticipant`
     */
    auctionsParticipantsRetrieve: (auctionPk: number, id: number, params: RequestParams = {}) =>
      this.request<AuctionParticipant, any>({
        path: `/api/v1/auctions/${auctionPk}/participants/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsParticipantsResendInvitationCreate
     * @request POST:/api/v1/auctions/{auction_pk}/participants/{id}/resend-invitation/
     * @secure
     * @response `200` `void` No response body
     */
    auctionsParticipantsResendInvitationCreate: (auctionPk: number, id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/auctions/${auctionPk}/participants/${id}/resend-invitation/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsParticipantsStatusCreate
     * @request POST:/api/v1/auctions/{auction_pk}/participants/{id}/status/
     * @secure
     * @response `200` `AuctionSetParticipantStatus`
     */
    auctionsParticipantsStatusCreate: (
      auctionPk: number,
      id: number,
      data: AuctionSetParticipantStatus,
      params: RequestParams = {},
    ) =>
      this.request<AuctionSetParticipantStatus, any>({
        path: `/api/v1/auctions/${auctionPk}/participants/${id}/status/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsRetrieve
     * @request GET:/api/v1/auctions/{id}/
     * @secure
     * @response `200` `Auction`
     */
    auctionsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Auction, any>({
        path: `/api/v1/auctions/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsUpdate
     * @request PUT:/api/v1/auctions/{id}/
     * @secure
     * @response `200` `AuctionUpdate`
     */
    auctionsUpdate: (id: number, data: AuctionUpdate, params: RequestParams = {}) =>
      this.request<AuctionUpdate, any>({
        path: `/api/v1/auctions/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsPartialUpdate
     * @request PATCH:/api/v1/auctions/{id}/
     * @secure
     * @response `200` `AuctionUpdate`
     */
    auctionsPartialUpdate: (id: number, data: PatchedAuctionUpdate, params: RequestParams = {}) =>
      this.request<AuctionUpdate, any>({
        path: `/api/v1/auctions/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsDestroy
     * @request DELETE:/api/v1/auctions/{id}/
     * @secure
     * @response `204` `void` No response body
     */
    auctionsDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/auctions/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsCancelCreate
     * @request POST:/api/v1/auctions/{id}/cancel/
     * @secure
     * @response `200` `Auction`
     */
    auctionsCancelCreate: (id: number, data: Auction, params: RequestParams = {}) =>
      this.request<Auction, any>({
        path: `/api/v1/auctions/${id}/cancel/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsChangePaymentMethodCreate
     * @request POST:/api/v1/auctions/{id}/change_payment_method/
     * @secure
     * @response `200` `AuctionChangePaymentMethod`
     */
    auctionsChangePaymentMethodCreate: (id: number, data: AuctionChangePaymentMethod, params: RequestParams = {}) =>
      this.request<AuctionChangePaymentMethod, any>({
        path: `/api/v1/auctions/${id}/change_payment_method/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsPublishCreate
     * @request POST:/api/v1/auctions/{id}/publish/
     * @secure
     * @response `200` `Auction`
     */
    auctionsPublishCreate: (id: number, data: Auction, params: RequestParams = {}) =>
      this.request<Auction, any>({
        path: `/api/v1/auctions/${id}/publish/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsTakeInstantOrderCreate
     * @request POST:/api/v1/auctions/{id}/take_instant_order/
     * @secure
     * @response `200` `AuctionTakeInstantOrder`
     */
    auctionsTakeInstantOrderCreate: (id: number, data: AuctionTakeInstantOrder, params: RequestParams = {}) =>
      this.request<AuctionTakeInstantOrder, any>({
        path: `/api/v1/auctions/${id}/take_instant_order/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions
     * @name AuctionsExportRetrieve
     * @request GET:/api/v1/auctions/export/
     * @secure
     * @response `200` `File`
     */
    auctionsExportRetrieve: (query: AuctionsExportRetrieveParams, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/v1/auctions/export/`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  auth = {
    /**
     * @description Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
     *
     * @tags auth
     * @name AuthLoginCreate
     * @request POST:/api/v1/auth/login/
     * @secure
     * @response `200` `JWT`
     */
    authLoginCreate: (data: Login, params: RequestParams = {}) =>
      this.request<JWT, any>({
        path: `/api/v1/auth/login/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Calls Django logout method and delete the Token object assigned to the current User object. Accepts/Returns nothing.
     *
     * @tags auth
     * @name AuthLogoutCreate
     * @request POST:/api/v1/auth/logout/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authLogoutCreate: (params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/v1/auth/logout/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     *
     * @tags auth
     * @name AuthPasswordChangeCreate
     * @request POST:/api/v1/auth/password/change/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authPasswordChangeCreate: (data: PasswordChange, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/v1/auth/password/change/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
     *
     * @tags auth
     * @name AuthPasswordResetCreate
     * @request POST:/api/v1/auth/password/reset/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authPasswordResetCreate: (data: PasswordReset, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/v1/auth/password/reset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Password reset e-mail link is confirmed, therefore this resets the user's password. Accepts the following POST parameters: token, uid, new_password1, new_password2 Returns the success/fail message.
     *
     * @tags auth
     * @name AuthPasswordResetConfirmCreate
     * @request POST:/api/v1/auth/password/reset/confirm/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authPasswordResetConfirmCreate: (data: PasswordResetConfirm, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/v1/auth/password/reset/confirm/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthRegisterCreate
     * @request POST:/api/v1/auth/register/
     * @secure
     * @response `201` `JWT`
     */
    authRegisterCreate: (data: Register, params: RequestParams = {}) =>
      this.request<JWT, any>({
        path: `/api/v1/auth/register/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthRegisterResendEmailCreate
     * @request POST:/api/v1/auth/register/resend-email/
     * @secure
     * @response `201` `RestAuthDetail`
     */
    authRegisterResendEmailCreate: (data: ResendEmailVerification, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/v1/auth/register/resend-email/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthRegisterVerifyEmailCreate
     * @request POST:/api/v1/auth/register/verify-email/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authRegisterVerifyEmailCreate: (data: VerifyEmail, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/v1/auth/register/verify-email/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags auth
     * @name AuthTokenRefreshCreate
     * @request POST:/api/v1/auth/token/refresh/
     * @response `200` `TokenRefresh`
     */
    authTokenRefreshCreate: (data: TokenRefresh, params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/api/v1/auth/token/refresh/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
     *
     * @tags auth
     * @name AuthTokenVerifyCreate
     * @request POST:/api/v1/auth/token/verify/
     * @response `200` `TokenVerify`
     */
    authTokenVerifyCreate: (data: TokenVerify, params: RequestParams = {}) =>
      this.request<TokenVerify, any>({
        path: `/api/v1/auth/token/verify/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags auth
     * @name AuthUserRetrieve
     * @request GET:/api/v1/auth/user/
     * @secure
     * @response `200` `UserDetails`
     */
    authUserRetrieve: (params: RequestParams = {}) =>
      this.request<UserDetails, any>({
        path: `/api/v1/auth/user/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags auth
     * @name AuthUserUpdate
     * @request PUT:/api/v1/auth/user/
     * @secure
     * @response `200` `UserDetails`
     */
    authUserUpdate: (data: UserDetails, params: RequestParams = {}) =>
      this.request<UserDetails, any>({
        path: `/api/v1/auth/user/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags auth
     * @name AuthUserPartialUpdate
     * @request PATCH:/api/v1/auth/user/
     * @secure
     * @response `200` `UserDetails`
     */
    authUserPartialUpdate: (data: PatchedUserDetails, params: RequestParams = {}) =>
      this.request<UserDetails, any>({
        path: `/api/v1/auth/user/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  byHash = {
    /**
     * No description
     *
     * @tags bids, by-hash
     * @name ByHashCarriersAuctionsBidsList
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/
     * @secure
     * @response `200` `PaginatedBidByHashList`
     */
    byHashCarriersAuctionsBidsList: (
      { auctionHash, carrierHash, ...query }: ByHashCarriersAuctionsBidsListParams,
      params: RequestParams = {},
    ) =>
      this.request<PaginatedBidByHashList, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/bids/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bids, by-hash
     * @name ByHashCarriersAuctionsBidsCreate
     * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/
     * @secure
     * @response `201` `BidByHash`
     */
    byHashCarriersAuctionsBidsCreate: (
      auctionHash: string,
      carrierHash: string,
      data: BidByHash,
      params: RequestParams = {},
    ) =>
      this.request<BidByHash, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/bids/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bids, by-hash
     * @name ByHashCarriersAuctionsBidsRetrieve
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/{hash}/
     * @secure
     * @response `200` `BidByHash`
     */
    byHashCarriersAuctionsBidsRetrieve: (
      auctionHash: string,
      carrierHash: string,
      hash: string,
      params: RequestParams = {},
    ) =>
      this.request<BidByHash, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/bids/${hash}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bids, by-hash
     * @name ByHashCarriersAuctionsBidsUpdate
     * @request PUT:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/{hash}/
     * @secure
     * @response `200` `UpdateBidByHash`
     */
    byHashCarriersAuctionsBidsUpdate: (
      auctionHash: string,
      carrierHash: string,
      hash: string,
      data: UpdateBidByHash,
      params: RequestParams = {},
    ) =>
      this.request<UpdateBidByHash, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/bids/${hash}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bids, by-hash
     * @name ByHashCarriersAuctionsBidsPartialUpdate
     * @request PATCH:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/bids/{hash}/
     * @secure
     * @response `200` `UpdateBidByHash`
     */
    byHashCarriersAuctionsBidsPartialUpdate: (
      auctionHash: string,
      carrierHash: string,
      hash: string,
      data: PatchedUpdateBidByHash,
      params: RequestParams = {},
    ) =>
      this.request<UpdateBidByHash, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/bids/${hash}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents, by-hash
     * @name ByHashCarriersAuctionsDocumentsList
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/documents/
     * @secure
     * @response `200` `PaginatedDocumentList`
     */
    byHashCarriersAuctionsDocumentsList: (
      { auctionHash, carrierHash, ...query }: ByHashCarriersAuctionsDocumentsListParams,
      params: RequestParams = {},
    ) =>
      this.request<PaginatedDocumentList, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/documents/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents, by-hash
     * @name ByHashCarriersAuctionsDocumentsCreate
     * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/documents/
     * @secure
     * @response `201` `Document`
     */
    byHashCarriersAuctionsDocumentsCreate: (
      auctionHash: string,
      carrierHash: string,
      data: CarrierDocumentCreate,
      params: RequestParams = {},
    ) =>
      this.request<Document, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/documents/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents, by-hash
     * @name ByHashCarriersAuctionsDocumentsRetrieve
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/documents/{id}/
     * @secure
     * @response `200` `Document`
     */
    byHashCarriersAuctionsDocumentsRetrieve: (
      auctionHash: string,
      carrierHash: string,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<Document, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/documents/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages, by-hash
     * @name ByHashCarriersAuctionsMessagesList
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/messages/
     * @secure
     * @response `200` `PaginatedMessageList`
     */
    byHashCarriersAuctionsMessagesList: (
      { auctionHash, carrierHash, ...query }: ByHashCarriersAuctionsMessagesListParams,
      params: RequestParams = {},
    ) =>
      this.request<PaginatedMessageList, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/messages/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages, by-hash
     * @name ByHashCarriersAuctionsMessagesCreate
     * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/messages/
     * @secure
     * @response `201` `Message`
     */
    byHashCarriersAuctionsMessagesCreate: (
      auctionHash: string,
      carrierHash: string,
      data: Message,
      params: RequestParams = {},
    ) =>
      this.request<Message, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/messages/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages, by-hash
     * @name ByHashCarriersAuctionsMessagesMarkAsReadCreate
     * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/messages/mark_as_read/
     * @secure
     * @response `200` `void` No response body
     */
    byHashCarriersAuctionsMessagesMarkAsReadCreate: (
      auctionHash: string,
      carrierHash: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/messages/mark_as_read/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages, by-hash
     * @name ByHashCarriersAuctionsMessagesUnreadCountRetrieve
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{auction_hash}/messages/unread-count/
     * @secure
     * @response `200` `number`
     */
    byHashCarriersAuctionsMessagesUnreadCountRetrieve: (
      auctionHash: string,
      carrierHash: string,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${auctionHash}/messages/unread-count/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions, by-hash
     * @name ByHashCarriersAuctionsRetrieve
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{hash}/
     * @secure
     * @response `200` `AuctionByHash`
     */
    byHashCarriersAuctionsRetrieve: (carrierHash: string, hash: string, params: RequestParams = {}) =>
      this.request<AuctionByHash, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${hash}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auctions, by-hash
     * @name ByHashCarriersAuctionsStatusCreate
     * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{hash}/status/
     * @secure
     * @response `200` `AuctionSetParticipantStatus`
     */
    byHashCarriersAuctionsStatusCreate: (
      carrierHash: string,
      hash: string,
      data: AuctionSetParticipantStatus,
      params: RequestParams = {},
    ) =>
      this.request<AuctionSetParticipantStatus, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${hash}/status/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transport-details
     * @name ByHashCarriersAuctionsTransportDetailsRetrieve
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{hash}/transport-details/
     * @secure
     * @response `200` `TransportDetailByHash`
     */
    byHashCarriersAuctionsTransportDetailsRetrieve: (carrierHash: string, hash: string, params: RequestParams = {}) =>
      this.request<TransportDetailByHash, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${hash}/transport-details/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transport-details
     * @name ByHashCarriersAuctionsTransportDetailsCreate
     * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/auctions/{hash}/transport-details/
     * @secure
     * @response `200` `TransportDetailByHash`
     */
    byHashCarriersAuctionsTransportDetailsCreate: (
      carrierHash: string,
      hash: string,
      data: TransportDetailByHash,
      params: RequestParams = {},
    ) =>
      this.request<TransportDetailByHash, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/auctions/${hash}/transport-details/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This must be a viewset, because i want to use it in carrier by hash router
     *
     * @tags billing, by-hash
     * @name ByHashCarriersBillingDetailsList
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/billing-details/
     * @secure
     * @response `200` `PaginatedCarrierBillingDetailsByHashInputList`
     */
    byHashCarriersBillingDetailsList: (
      { carrierHash, ...query }: ByHashCarriersBillingDetailsListParams,
      params: RequestParams = {},
    ) =>
      this.request<PaginatedCarrierBillingDetailsByHashInputList, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/billing-details/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This must be a viewset, because i want to use it in carrier by hash router
     *
     * @tags billing, by-hash
     * @name ByHashCarriersBillingDetailsCreate
     * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/billing-details/
     * @secure
     * @response `201` `CarrierBillingDetailsByHashOutput`
     */
    byHashCarriersBillingDetailsCreate: (
      carrierHash: string,
      data: CarrierBillingDetailsByHashInput,
      params: RequestParams = {},
    ) =>
      this.request<CarrierBillingDetailsByHashOutput, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/billing-details/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This must be a viewset, because i want to use it in carrier by hash router
     *
     * @tags billing, by-hash
     * @name ByHashCarriersBillingDetailsRetrieve
     * @request GET:/api/v1/by-hash/carriers/{carrier_hash}/billing-details/{id}/
     * @secure
     * @response `200` `CarrierBillingDetailsByHashInput`
     */
    byHashCarriersBillingDetailsRetrieve: (carrierHash: string, id: number, params: RequestParams = {}) =>
      this.request<CarrierBillingDetailsByHashInput, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/billing-details/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This must be a viewset, because i want to use it in carrier by hash router
     *
     * @tags billing, by-hash
     * @name ByHashCarriersBillingDetailsApproveCreate
     * @request POST:/api/v1/by-hash/carriers/{carrier_hash}/billing-details/{id}/approve/
     * @secure
     * @response `200` `CarrierBillingDetailsByHashOutput`
     */
    byHashCarriersBillingDetailsApproveCreate: (carrierHash: string, id: number, params: RequestParams = {}) =>
      this.request<CarrierBillingDetailsByHashOutput, any>({
        path: `/api/v1/by-hash/carriers/${carrierHash}/billing-details/${id}/approve/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers, by-hash
     * @name ByHashCarriersRetrieve
     * @request GET:/api/v1/by-hash/carriers/{hash}/
     * @secure
     * @response `200` `CarrierByHash`
     */
    byHashCarriersRetrieve: (hash: string, params: RequestParams = {}) =>
      this.request<CarrierByHash, any>({
        path: `/api/v1/by-hash/carriers/${hash}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers, by-hash
     * @name ByHashCarriersUpdate
     * @request PUT:/api/v1/by-hash/carriers/{hash}/
     * @secure
     * @response `200` `CarrierByHash`
     */
    byHashCarriersUpdate: (hash: string, data: CarrierByHash, params: RequestParams = {}) =>
      this.request<CarrierByHash, any>({
        path: `/api/v1/by-hash/carriers/${hash}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers, by-hash
     * @name ByHashCarriersPartialUpdate
     * @request PATCH:/api/v1/by-hash/carriers/{hash}/
     * @secure
     * @response `200` `CarrierByHash`
     */
    byHashCarriersPartialUpdate: (hash: string, data: PatchedCarrierByHash, params: RequestParams = {}) =>
      this.request<CarrierByHash, any>({
        path: `/api/v1/by-hash/carriers/${hash}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  captcha = {
    /**
     * No description
     *
     * @tags core
     * @name CaptchaVerifyCreate
     * @request POST:/api/v1/captcha/verify/
     * @secure
     * @response `200` `RecaptchaVerifyResult`
     */
    captchaVerifyCreate: (data: CaptchaVerifyCreatePayload, params: RequestParams = {}) =>
      this.request<RecaptchaVerifyResult, any>({
        path: `/api/v1/captcha/verify/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  cargoItemUnits = {
    /**
     * No description
     *
     * @tags cargo-item-units
     * @name CargoItemUnitsList
     * @request GET:/api/v1/cargo-item-units/
     * @secure
     * @response `200` `PaginatedCargoItemUnitList`
     */
    cargoItemUnitsList: (query: CargoItemUnitsListParams, params: RequestParams = {}) =>
      this.request<PaginatedCargoItemUnitList, any>({
        path: `/api/v1/cargo-item-units/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags cargo-item-units
     * @name CargoItemUnitsRetrieve
     * @request GET:/api/v1/cargo-item-units/{id}/
     * @secure
     * @response `200` `CargoItemUnit`
     */
    cargoItemUnitsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<CargoItemUnit, any>({
        path: `/api/v1/cargo-item-units/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  carrierGroups = {
    /**
     * No description
     *
     * @tags carriers
     * @name CarrierGroupsList
     * @request GET:/api/v1/carrier-groups/
     * @secure
     * @response `200` `PaginatedCarrierGroupList`
     */
    carrierGroupsList: (query: CarrierGroupsListParams, params: RequestParams = {}) =>
      this.request<PaginatedCarrierGroupList, any>({
        path: `/api/v1/carrier-groups/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarrierGroupsCreate
     * @request POST:/api/v1/carrier-groups/
     * @secure
     * @response `201` `CarrierGroup`
     */
    carrierGroupsCreate: (data: CarrierGroup, params: RequestParams = {}) =>
      this.request<CarrierGroup, any>({
        path: `/api/v1/carrier-groups/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarrierGroupsRetrieve
     * @request GET:/api/v1/carrier-groups/{id}/
     * @secure
     * @response `200` `CarrierGroup`
     */
    carrierGroupsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<CarrierGroup, any>({
        path: `/api/v1/carrier-groups/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarrierGroupsUpdate
     * @request PUT:/api/v1/carrier-groups/{id}/
     * @secure
     * @response `200` `CarrierGroup`
     */
    carrierGroupsUpdate: (id: number, data: CarrierGroup, params: RequestParams = {}) =>
      this.request<CarrierGroup, any>({
        path: `/api/v1/carrier-groups/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarrierGroupsPartialUpdate
     * @request PATCH:/api/v1/carrier-groups/{id}/
     * @secure
     * @response `200` `CarrierGroup`
     */
    carrierGroupsPartialUpdate: (id: number, data: PatchedCarrierGroup, params: RequestParams = {}) =>
      this.request<CarrierGroup, any>({
        path: `/api/v1/carrier-groups/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarrierGroupsDestroy
     * @request DELETE:/api/v1/carrier-groups/{id}/
     * @secure
     * @response `204` `void` No response body
     */
    carrierGroupsDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/carrier-groups/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarrierGroupsAllList
     * @request GET:/api/v1/carrier-groups/all/
     * @secure
     * @response `200` `(CarrierGroupGeneralInfo)[]`
     */
    carrierGroupsAllList: (query: CarrierGroupsAllListParams, params: RequestParams = {}) =>
      this.request<CarrierGroupGeneralInfo[], any>({
        path: `/api/v1/carrier-groups/all/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  carriers = {
    /**
     * No description
     *
     * @tags carriers
     * @name CarriersList
     * @request GET:/api/v1/carriers/
     * @secure
     * @response `200` `PaginatedCarrierList`
     */
    carriersList: (query: CarriersListParams, params: RequestParams = {}) =>
      this.request<PaginatedCarrierList, any>({
        path: `/api/v1/carriers/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarriersCreate
     * @request POST:/api/v1/carriers/
     * @secure
     * @response `201` `Carrier`
     */
    carriersCreate: (data: Carrier, params: RequestParams = {}) =>
      this.request<Carrier, any>({
        path: `/api/v1/carriers/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarriersRetrieve
     * @request GET:/api/v1/carriers/{id}/
     * @secure
     * @response `200` `Carrier`
     */
    carriersRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Carrier, any>({
        path: `/api/v1/carriers/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarriersUpdate
     * @request PUT:/api/v1/carriers/{id}/
     * @secure
     * @response `200` `Carrier`
     */
    carriersUpdate: (id: number, data: Carrier, params: RequestParams = {}) =>
      this.request<Carrier, any>({
        path: `/api/v1/carriers/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarriersPartialUpdate
     * @request PATCH:/api/v1/carriers/{id}/
     * @secure
     * @response `200` `Carrier`
     */
    carriersPartialUpdate: (id: number, data: PatchedCarrier, params: RequestParams = {}) =>
      this.request<Carrier, any>({
        path: `/api/v1/carriers/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarriersDestroy
     * @request DELETE:/api/v1/carriers/{id}/
     * @secure
     * @response `204` `void` No response body
     */
    carriersDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/carriers/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarriersIsActivePartialUpdate
     * @request PATCH:/api/v1/carriers/{id}/is_active/
     * @secure
     * @response `200` `Carrier`
     */
    carriersIsActivePartialUpdate: (id: number, data: PatchedCarrierUpdateIsActive, params: RequestParams = {}) =>
      this.request<Carrier, any>({
        path: `/api/v1/carriers/${id}/is_active/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarriersAllList
     * @request GET:/api/v1/carriers/all/
     * @secure
     * @response `200` `(CarrierGeneralInfo)[]`
     */
    carriersAllList: (query: CarriersAllListParams, params: RequestParams = {}) =>
      this.request<CarrierGeneralInfo[], any>({
        path: `/api/v1/carriers/all/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carriers
     * @name CarriersAutocompleteCreate
     * @request POST:/api/v1/carriers/autocomplete/
     * @secure
     * @response `200` `AutocompleteResponse`
     */
    carriersAutocompleteCreate: (data: AutocompleteRequest, params: RequestParams = {}) =>
      this.request<AutocompleteResponse, any>({
        path: `/api/v1/carriers/autocomplete/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  customers = {
    /**
     * No description
     *
     * @tags customers
     * @name CustomersCreate
     * @request POST:/api/v1/customers/
     * @secure
     * @response `201` `Subscriber`
     */
    customersCreate: (data: Subscriber, params: RequestParams = {}) =>
      this.request<Subscriber, any>({
        path: `/api/v1/customers/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  documents = {
    /**
     * No description
     *
     * @tags documents
     * @name DocumentsList
     * @request GET:/api/v1/documents/
     * @secure
     * @response `200` `PaginatedDocumentList`
     */
    documentsList: (query: DocumentsListParams, params: RequestParams = {}) =>
      this.request<PaginatedDocumentList, any>({
        path: `/api/v1/documents/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name DocumentsCreate
     * @request POST:/api/v1/documents/
     * @secure
     * @response `201` `Document`
     */
    documentsCreate: (data: DocumentCreate, params: RequestParams = {}) =>
      this.request<Document, any>({
        path: `/api/v1/documents/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name DocumentsRetrieve
     * @request GET:/api/v1/documents/{id}/
     * @secure
     * @response `200` `Document`
     */
    documentsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Document, any>({
        path: `/api/v1/documents/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name DocumentsUpdate
     * @request PUT:/api/v1/documents/{id}/
     * @secure
     * @response `200` `Document`
     */
    documentsUpdate: (id: number, data: DocumentUpdate, params: RequestParams = {}) =>
      this.request<Document, any>({
        path: `/api/v1/documents/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name DocumentsPartialUpdate
     * @request PATCH:/api/v1/documents/{id}/
     * @secure
     * @response `200` `DocumentUpdate`
     */
    documentsPartialUpdate: (id: number, data: PatchedDocumentUpdate, params: RequestParams = {}) =>
      this.request<DocumentUpdate, any>({
        path: `/api/v1/documents/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name DocumentsDestroy
     * @request DELETE:/api/v1/documents/{id}/
     * @secure
     * @response `204` `void` No response body
     */
    documentsDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/documents/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  featureFlags = {
    /**
     * No description
     *
     * @tags core
     * @name FeatureFlagsStatusRetrieve
     * @request GET:/api/v1/feature-flags/status
     * @secure
     * @response `200` `FeatureFlagsStatus`
     */
    featureFlagsStatusRetrieve: (params: RequestParams = {}) =>
      this.request<FeatureFlagsStatus, any>({
        path: `/api/v1/feature-flags/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  info = {
    /**
     * No description
     *
     * @tags core
     * @name InfoRetrieve
     * @request GET:/api/v1/info/
     * @secure
     * @response `200` `AppInfo`
     */
    infoRetrieve: (params: RequestParams = {}) =>
      this.request<AppInfo, any>({
        path: `/api/v1/info/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  invitations = {
    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsAcceptCreate
     * @request POST:/api/v1/invitations/accept/
     * @secure
     * @response `200` `JWT`
     */
    invitationsAcceptCreate: (data: AcceptInvitation, params: RequestParams = {}) =>
      this.request<JWT, any>({
        path: `/api/v1/invitations/accept/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsAcceptByUserCreate
     * @request POST:/api/v1/invitations/accept-by-user/
     * @secure
     * @response `200` `boolean`
     */
    invitationsAcceptByUserCreate: (data: ModifyInvitationByUser, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/v1/invitations/accept-by-user/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsBulkInviteCreate
     * @request POST:/api/v1/invitations/bulk-invite/
     * @secure
     * @response `200` `Record<string,any>`
     */
    invitationsBulkInviteCreate: (data: BulkInvitation, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/invitations/bulk-invite/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsPendingList
     * @request GET:/api/v1/invitations/pending/
     * @secure
     * @response `200` `(Invitation)[]`
     */
    invitationsPendingList: (params: RequestParams = {}) =>
      this.request<Invitation[], any>({
        path: `/api/v1/invitations/pending/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsPendingByUserList
     * @request GET:/api/v1/invitations/pending-by-user/
     * @secure
     * @response `200` `(WorkspaceInvitation)[]`
     */
    invitationsPendingByUserList: (params: RequestParams = {}) =>
      this.request<WorkspaceInvitation[], any>({
        path: `/api/v1/invitations/pending-by-user/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsRejectByUserCreate
     * @request POST:/api/v1/invitations/reject-by-user/
     * @secure
     * @response `200` `boolean`
     */
    invitationsRejectByUserCreate: (data: ModifyInvitationByUser, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/v1/invitations/reject-by-user/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsRemoveCreate
     * @request POST:/api/v1/invitations/remove/
     * @secure
     * @response `200` `boolean`
     */
    invitationsRemoveCreate: (data: InvitationsRemoveCreatePayload, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/v1/invitations/remove/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  locations = {
    /**
     * No description
     *
     * @tags locations
     * @name LocationsStaticMapCreate
     * @request POST:/api/v1/locations/static-map/
     * @secure
     * @response `200` `LocationData`
     */
    locationsStaticMapCreate: (data: TranslateLocations, params: RequestParams = {}) =>
      this.request<LocationData, any>({
        path: `/api/v1/locations/static-map/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags locations
     * @name LocationsTranslateCreate
     * @request POST:/api/v1/locations/translate/
     * @secure
     * @response `200` `LocationData`
     */
    locationsTranslateCreate: (data: TranslateLocations, params: RequestParams = {}) =>
      this.request<LocationData, any>({
        path: `/api/v1/locations/translate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  messages = {
    /**
     * No description
     *
     * @tags messages
     * @name MessagesList
     * @request GET:/api/v1/messages/
     * @secure
     * @response `200` `PaginatedUserMessageList`
     */
    messagesList: (query: MessagesListParams, params: RequestParams = {}) =>
      this.request<PaginatedUserMessageList, any>({
        path: `/api/v1/messages/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name MessagesMarkAsReadCreate
     * @request POST:/api/v1/messages/{id}/mark_as_read/
     * @secure
     * @response `200` `void` No response body
     */
    messagesMarkAsReadCreate: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/messages/${id}/mark_as_read/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name MessagesMarkAllAsReadCreate
     * @request POST:/api/v1/messages/mark-all-as-read/
     * @secure
     * @response `200` `string`
     */
    messagesMarkAllAsReadCreate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/messages/mark-all-as-read/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name MessagesUnreadCountRetrieve
     * @request GET:/api/v1/messages/unread-count/
     * @secure
     * @response `200` `number`
     */
    messagesUnreadCountRetrieve: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/v1/messages/unread-count/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  notifications = {
    /**
     * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
     *
     * @tags notifications
     * @name NotificationsList
     * @request GET:/api/v1/notifications/
     * @secure
     * @response `200` `PaginatedNotificationList`
     */
    notificationsList: (query: NotificationsListParams, params: RequestParams = {}) =>
      this.request<PaginatedNotificationList, any>({
        path: `/api/v1/notifications/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
     *
     * @tags notifications
     * @name NotificationsRetrieve
     * @request GET:/api/v1/notifications/{id}/
     * @secure
     * @response `200` `Notification`
     */
    notificationsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Notification, any>({
        path: `/api/v1/notifications/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
     *
     * @tags notifications
     * @name NotificationsMarkAsReadCreate
     * @request POST:/api/v1/notifications/{id}/mark-as-read/
     * @secure
     * @response `200` `string`
     */
    notificationsMarkAsReadCreate: (id: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/notifications/${id}/mark-as-read/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
     *
     * @tags notifications
     * @name NotificationsMarkAllAsReadCreate
     * @request POST:/api/v1/notifications/mark-all-as-read/
     * @secure
     * @response `200` `string`
     */
    notificationsMarkAllAsReadCreate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/notifications/mark-all-as-read/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
     *
     * @tags notifications
     * @name NotificationsUserSettingsRetrieve
     * @request GET:/api/v1/notifications/user-settings/
     * @secure
     * @response `200` `UserNotificationSettings`
     */
    notificationsUserSettingsRetrieve: (params: RequestParams = {}) =>
      this.request<UserNotificationSettings, any>({
        path: `/api/v1/notifications/user-settings/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for listing and marking notifications as read. Returns all notifications by default. If yoy want to get read/unread notifications, use the filter `is_read`.
     *
     * @tags notifications
     * @name NotificationsUserSettingsCreate
     * @request POST:/api/v1/notifications/user-settings/
     * @secure
     * @response `200` `UserNotificationSettings`
     */
    notificationsUserSettingsCreate: (data: UserNotificationSettings, params: RequestParams = {}) =>
      this.request<UserNotificationSettings, any>({
        path: `/api/v1/notifications/user-settings/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  options = {
    /**
     * No description
     *
     * @tags options
     * @name OptionsCargoLoadTypesRetrieve
     * @request GET:/api/v1/options/cargo-load-types/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsCargoLoadTypesRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/cargo-load-types/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsCargoTypesRetrieve
     * @request GET:/api/v1/options/cargo-types/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsCargoTypesRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/cargo-types/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsCountriesRetrieve
     * @request GET:/api/v1/options/countries/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsCountriesRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/countries/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsCurrenciesRetrieve
     * @request GET:/api/v1/options/currencies/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsCurrenciesRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/currencies/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsFeatureSwitchTypesList
     * @request GET:/api/v1/options/feature-switch-types/
     * @secure
     * @response `200` `(FeatureSwitch)[]`
     */
    optionsFeatureSwitchTypesList: (params: RequestParams = {}) =>
      this.request<FeatureSwitch[], any>({
        path: `/api/v1/options/feature-switch-types/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsLengthUnitRetrieve
     * @request GET:/api/v1/options/length-unit/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsLengthUnitRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/length-unit/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsMessageEventsRetrieve
     * @request GET:/api/v1/options/message-events/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsMessageEventsRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/message-events/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsRouteTypesRetrieve
     * @request GET:/api/v1/options/route-types/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsRouteTypesRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/route-types/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsSpecialRequirementsRetrieve
     * @request GET:/api/v1/options/special-requirements/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsSpecialRequirementsRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/special-requirements/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsStackCountRetrieve
     * @request GET:/api/v1/options/stack-count/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsStackCountRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/stack-count/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsTransportTypesRetrieve
     * @request GET:/api/v1/options/transport-types/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsTransportTypesRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/transport-types/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsTransportationModesRetrieve
     * @request GET:/api/v1/options/transportation-modes/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsTransportationModesRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/transportation-modes/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags options
     * @name OptionsWeightUnitRetrieve
     * @request GET:/api/v1/options/weight-unit/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsWeightUnitRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/options/weight-unit/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  payments = {
    /**
     * No description
     *
     * @tags payments
     * @name PaymentsCheckoutSessionCreate
     * @request POST:/api/v1/payments/checkout/session/
     * @secure
     * @response `200` `CheckoutResponseData`
     */
    paymentsCheckoutSessionCreate: (data: CheckoutRequestData, params: RequestParams = {}) =>
      this.request<CheckoutResponseData, any>({
        path: `/api/v1/payments/checkout/session/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  portal = {
    /**
     * @description Get company details based on VAT ID and country code. For getting also insurance status, use `insurance_status_check=true. If Portal has not cached the status for the company, it makes an additional API call to the Coface API (which can be paid and slow).
     *
     * @tags core
     * @name PortalCompanyInfoCreate
     * @request POST:/api/v1/portal/company-info/
     * @secure
     * @response `200` `PortalCompanyInfoResponse`
     */
    portalCompanyInfoCreate: (data: CompanyInfoPayload, params: RequestParams = {}) =>
      this.request<PortalCompanyInfoResponse, any>({
        path: `/api/v1/portal/company-info/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get company details based on VAT ID or company name and country code from Coface.
     *
     * @tags core
     * @name PortalExternalCompanyInfoCreate
     * @request POST:/api/v1/portal/external-company-info/
     * @secure
     * @response `200` `(CofaceCompanyInfoResponse)[]`
     */
    portalExternalCompanyInfoCreate: (data: CompanyInfoPayload, params: RequestParams = {}) =>
      this.request<CofaceCompanyInfoResponse[], any>({
        path: `/api/v1/portal/external-company-info/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  privacy = {
    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyConsentsRetrieve
     * @request GET:/api/v1/privacy/consents/{token}/
     * @secure
     * @response `200` `ConsentByTokenSetOutput`
     */
    privacyConsentsRetrieve: ({ token, ...query }: PrivacyConsentsRetrieveParams, params: RequestParams = {}) =>
      this.request<ConsentByTokenSetOutput, any>({
        path: `/api/v1/privacy/consents/${token}/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyConsentsCreate
     * @request POST:/api/v1/privacy/consents/{token}/
     * @secure
     * @response `200` `ConsentByTokenSetOutput`
     */
    privacyConsentsCreate: (token: string, data: PrivacyConsentsCreatePayload, params: RequestParams = {}) =>
      this.request<ConsentByTokenSetOutput, any>({
        path: `/api/v1/privacy/consents/${token}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyConsentsSettingsList
     * @request GET:/api/v1/privacy/consents/{token}/settings/
     * @secure
     * @response `200` `(ConsentsSettings)[]`
     */
    privacyConsentsSettingsList: (token: string, params: RequestParams = {}) =>
      this.request<ConsentsSettings[], any>({
        path: `/api/v1/privacy/consents/${token}/settings/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyConsentsSettingsCreate
     * @request POST:/api/v1/privacy/consents/{token}/settings/
     * @secure
     * @response `200` `(ConsentsSettings)[]`
     */
    privacyConsentsSettingsCreate: (
      token: string,
      data: PrivacyConsentsSettingsCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<ConsentsSettings[], any>({
        path: `/api/v1/privacy/consents/${token}/settings/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyConsentsTermsRetrieve
     * @request GET:/api/v1/privacy/consents/{token}/terms/
     * @secure
     * @response `200` `TermsByTokenOutput`
     */
    privacyConsentsTermsRetrieve: (token: string, params: RequestParams = {}) =>
      this.request<TermsByTokenOutput, any>({
        path: `/api/v1/privacy/consents/${token}/terms/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyConsentsTermsCreate
     * @request POST:/api/v1/privacy/consents/{token}/terms/
     * @secure
     * @response `200` `TermsByTokenOutput`
     */
    privacyConsentsTermsCreate: (token: string, data: PrivacyConsentsTermsCreatePayload, params: RequestParams = {}) =>
      this.request<TermsByTokenOutput, any>({
        path: `/api/v1/privacy/consents/${token}/terms/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyConsentsRequestResendCreate
     * @request POST:/api/v1/privacy/consents/request/resend/
     * @secure
     * @response `204` `any`
     * @response `400` `ConsentInfo`
     */
    privacyConsentsRequestResendCreate: (data: ResendConsentRequestInput, params: RequestParams = {}) =>
      this.request<any, PrivacyConsentsRequestResendCreateError>({
        path: `/api/v1/privacy/consents/request/resend/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyTermsAndConditionsList
     * @request GET:/api/v1/privacy/terms-and-conditions/
     * @secure
     * @response `200` `PaginatedTermsAndConditionsList`
     */
    privacyTermsAndConditionsList: (query: PrivacyTermsAndConditionsListParams, params: RequestParams = {}) =>
      this.request<PaginatedTermsAndConditionsList, any>({
        path: `/api/v1/privacy/terms-and-conditions/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags privacy
     * @name PrivacyTermsAndConditionsRetrieve
     * @request GET:/api/v1/privacy/terms-and-conditions/{id}/
     * @secure
     * @response `200` `TermsAndConditions`
     */
    privacyTermsAndConditionsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<TermsAndConditions, any>({
        path: `/api/v1/privacy/terms-and-conditions/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  push = {
    /**
     * No description
     *
     * @tags push
     * @name PushAuthorizeCreate
     * @request POST:/api/v1/push/authorize/
     * @secure
     * @response `200` `void` No response body
     */
    pushAuthorizeCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/push/authorize/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags push
     * @name PushAuthorizeByHashCreate
     * @request POST:/api/v1/push/authorize-by-hash/
     * @secure
     * @response `200` `void` No response body
     */
    pushAuthorizeByHashCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/push/authorize-by-hash/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  roles = {
    /**
     * No description
     *
     * @tags roles
     * @name RolesList
     * @request GET:/api/v1/roles/
     * @secure
     * @response `200` `(Role)[]`
     */
    rolesList: (query: RolesListParams, params: RequestParams = {}) =>
      this.request<Role[], any>({
        path: `/api/v1/roles/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags roles
     * @name RolesRetrieve
     * @request GET:/api/v1/roles/{id}/
     * @secure
     * @response `200` `Role`
     */
    rolesRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Role, any>({
        path: `/api/v1/roles/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  schema = {
    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags schema
     * @name SchemaRetrieve
     * @request GET:/api/v1/schema/
     * @secure
     * @response `200` `Record<string,any>`
     */
    schemaRetrieve: (query: SchemaRetrieveParams, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/v1/schema/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  stats = {
    /**
     * No description
     *
     * @tags workspace
     * @name StatsCompanyRetrieve
     * @request GET:/api/v1/stats/company/
     * @secure
     * @response `200` `CompanyStats`
     */
    statsCompanyRetrieve: (params: RequestParams = {}) =>
      this.request<CompanyStats, any>({
        path: `/api/v1/stats/company/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name StatsTopCarriersRetrieve
     * @request GET:/api/v1/stats/top-carriers/
     * @secure
     * @response `200` `TopCarriersStats`
     */
    statsTopCarriersRetrieve: (query: StatsTopCarriersRetrieveParams, params: RequestParams = {}) =>
      this.request<TopCarriersStats, any>({
        path: `/api/v1/stats/top-carriers/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name StatsTopCountriesList
     * @request GET:/api/v1/stats/top-countries/
     * @secure
     * @response `200` `(TopCountriesStats)[]`
     */
    statsTopCountriesList: (query: StatsTopCountriesListParams, params: RequestParams = {}) =>
      this.request<TopCountriesStats[], any>({
        path: `/api/v1/stats/top-countries/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  transportRequests = {
    /**
     * No description
     *
     * @tags transport-requests
     * @name TransportRequestsCreate
     * @request POST:/api/v1/transport-requests/
     * @secure
     * @response `201` `TransportRequest`
     */
    transportRequestsCreate: (data: TransportRequest, params: RequestParams = {}) =>
      this.request<TransportRequest, any>({
        path: `/api/v1/transport-requests/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transport-requests
     * @name TransportRequestsUpdate
     * @request PUT:/api/v1/transport-requests/{id}/
     * @secure
     * @response `200` `TransportRequest`
     */
    transportRequestsUpdate: (id: number, data: TransportRequest, params: RequestParams = {}) =>
      this.request<TransportRequest, any>({
        path: `/api/v1/transport-requests/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transport-requests
     * @name TransportRequestsPartialUpdate
     * @request PATCH:/api/v1/transport-requests/{id}/
     * @secure
     * @response `200` `TransportRequest`
     */
    transportRequestsPartialUpdate: (id: number, data: PatchedTransportRequest, params: RequestParams = {}) =>
      this.request<TransportRequest, any>({
        path: `/api/v1/transport-requests/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transport-requests
     * @name TransportRequestsUpdateActiveCreate
     * @request POST:/api/v1/transport-requests/{id}/update_active/
     * @secure
     * @response `200` `TransportRequest`
     */
    transportRequestsUpdateActiveCreate: (id: number, data: PatchedTransportRequest, params: RequestParams = {}) =>
      this.request<TransportRequest, any>({
        path: `/api/v1/transport-requests/${id}/update_active/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transport-requests
     * @name TransportRequestsGetPriceFromPricerCreate
     * @request POST:/api/v1/transport-requests/get-price-from-pricer/
     * @secure
     * @response `200` `PricerResult`
     */
    transportRequestsGetPriceFromPricerCreate: (data: TransportRequest, params: RequestParams = {}) =>
      this.request<PricerResult, any>({
        path: `/api/v1/transport-requests/get-price-from-pricer/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Validates the first step of a multi-step process. Parameters: request: The incoming request object. *args: Additional positional arguments. **kwargs: Additional keyword arguments. Returns: Response: A Response object containing serializer errors merged with custom one or serialized data.
     *
     * @tags transport-requests
     * @name TransportRequestsValidateFirstStepCreate
     * @request POST:/api/v1/transport-requests/validate-first-step/
     * @secure
     * @response `200` `TransportOrTransportRequestFirstStep`
     */
    transportRequestsValidateFirstStepCreate: (
      data: TransportOrTransportRequestFirstStep,
      params: RequestParams = {},
    ) =>
      this.request<TransportOrTransportRequestFirstStep, any>({
        path: `/api/v1/transport-requests/validate-first-step/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transport-requests
     * @name TransportRequestsValidateSecondStepCreate
     * @request POST:/api/v1/transport-requests/validate-second-step/
     * @secure
     * @response `200` `TransportOrTransportRequestSecondStep`
     */
    transportRequestsValidateSecondStepCreate: (
      data: TransportOrTransportRequestSecondStep,
      params: RequestParams = {},
    ) =>
      this.request<TransportOrTransportRequestSecondStep, any>({
        path: `/api/v1/transport-requests/validate-second-step/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transport-requests
     * @name TransportRequestsValidateThirdStepCreate
     * @request POST:/api/v1/transport-requests/validate-third-step/
     * @secure
     * @response `200` `TransportRequestThirdStep`
     */
    transportRequestsValidateThirdStepCreate: (data: TransportRequestThirdStep, params: RequestParams = {}) =>
      this.request<TransportRequestThirdStep, any>({
        path: `/api/v1/transport-requests/validate-third-step/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  transportStats = {
    /**
     * No description
     *
     * @tags transport-stats
     * @name TransportStatsOverviewRetrieve
     * @request GET:/api/v1/transport-stats/overview/
     * @secure
     * @response `200` `TransportOverviewStats`
     */
    transportStatsOverviewRetrieve: (query: TransportStatsOverviewRetrieveParams, params: RequestParams = {}) =>
      this.request<TransportOverviewStats, any>({
        path: `/api/v1/transport-stats/overview/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  transports = {
    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsList
     * @request GET:/api/v1/transports/
     * @secure
     * @response `200` `PaginatedTransportListList`
     */
    transportsList: (query: TransportsListParams, params: RequestParams = {}) =>
      this.request<PaginatedTransportListList, any>({
        path: `/api/v1/transports/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsRetrieve
     * @request GET:/api/v1/transports/{id}/
     * @secure
     * @response `200` `Transport`
     */
    transportsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Transport, any>({
        path: `/api/v1/transports/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsUpdate
     * @request PUT:/api/v1/transports/{id}/
     * @secure
     * @response `200` `Transport`
     */
    transportsUpdate: (id: number, data: Transport, params: RequestParams = {}) =>
      this.request<Transport, any>({
        path: `/api/v1/transports/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsPartialUpdate
     * @request PATCH:/api/v1/transports/{id}/
     * @secure
     * @response `200` `Transport`
     */
    transportsPartialUpdate: (id: number, data: PatchedTransport, params: RequestParams = {}) =>
      this.request<Transport, any>({
        path: `/api/v1/transports/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsDestroy
     * @request DELETE:/api/v1/transports/{id}/
     * @secure
     * @response `204` `void` No response body
     */
    transportsDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/transports/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsStatusCreate
     * @request POST:/api/v1/transports/{id}/status/
     * @secure
     * @response `200` `TransportUpdateStatus`
     */
    transportsStatusCreate: (id: number, data: TransportUpdateStatus, params: RequestParams = {}) =>
      this.request<TransportUpdateStatus, any>({
        path: `/api/v1/transports/${id}/status/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transport-details
     * @name TransportsTransportDetailsRetrieve
     * @request GET:/api/v1/transports/{id}/transport-details/
     * @secure
     * @response `200` `TransportDetail`
     */
    transportsTransportDetailsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<TransportDetail, any>({
        path: `/api/v1/transports/${id}/transport-details/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transport-details
     * @name TransportsTransportDetailsCreate
     * @request POST:/api/v1/transports/{id}/transport-details/
     * @secure
     * @response `200` `TransportDetail`
     */
    transportsTransportDetailsCreate: (id: number, data: TransportDetail, params: RequestParams = {}) =>
      this.request<TransportDetail, any>({
        path: `/api/v1/transports/${id}/transport-details/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name TransportsDocumentsList
     * @request GET:/api/v1/transports/{transport_pk}/documents/
     * @secure
     * @response `200` `PaginatedDocumentList`
     */
    transportsDocumentsList: ({ transportPk, ...query }: TransportsDocumentsListParams, params: RequestParams = {}) =>
      this.request<PaginatedDocumentList, any>({
        path: `/api/v1/transports/${transportPk}/documents/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name TransportsMessagesList
     * @request GET:/api/v1/transports/{transport_pk}/messages/
     * @secure
     * @response `200` `PaginatedMessageList`
     */
    transportsMessagesList: ({ transportPk, ...query }: TransportsMessagesListParams, params: RequestParams = {}) =>
      this.request<PaginatedMessageList, any>({
        path: `/api/v1/transports/${transportPk}/messages/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name TransportsMessagesCreate
     * @request POST:/api/v1/transports/{transport_pk}/messages/
     * @secure
     * @response `201` `Message`
     */
    transportsMessagesCreate: (transportPk: number, data: Message, params: RequestParams = {}) =>
      this.request<Message, any>({
        path: `/api/v1/transports/${transportPk}/messages/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name TransportsMessagesMarkAsReadCreate
     * @request POST:/api/v1/transports/{transport_pk}/messages/mark-as-read/
     * @secure
     * @response `200` `void` No response body
     */
    transportsMessagesMarkAsReadCreate: (transportPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/transports/${transportPk}/messages/mark-as-read/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name TransportsMessagesUnreadCountRetrieve
     * @request GET:/api/v1/transports/{transport_pk}/messages/unread-count/
     * @secure
     * @response `200` `number`
     */
    transportsMessagesUnreadCountRetrieve: (transportPk: number, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/v1/transports/${transportPk}/messages/unread-count/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsValidateFirstStepCreate
     * @request POST:/api/v1/transports/validate-first-step/
     * @secure
     * @response `200` `TransportOrTransportRequestFirstStep`
     */
    transportsValidateFirstStepCreate: (data: TransportOrTransportRequestFirstStep, params: RequestParams = {}) =>
      this.request<TransportOrTransportRequestFirstStep, any>({
        path: `/api/v1/transports/validate-first-step/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsValidateSecondStepCreate
     * @request POST:/api/v1/transports/validate-second-step/
     * @secure
     * @response `200` `TransportOrTransportRequestSecondStep`
     */
    transportsValidateSecondStepCreate: (data: TransportOrTransportRequestSecondStep, params: RequestParams = {}) =>
      this.request<TransportOrTransportRequestSecondStep, any>({
        path: `/api/v1/transports/validate-second-step/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description ViewSet for retrieving a list of transports and retrieving a single transport. Transports are auctions which have winner - already stored in separate model.
     *
     * @tags transports
     * @name TransportsValidateThirdStepCreate
     * @request POST:/api/v1/transports/validate-third-step/
     * @secure
     * @response `200` `TransportThirdStep`
     */
    transportsValidateThirdStepCreate: (data: TransportThirdStep, params: RequestParams = {}) =>
      this.request<TransportThirdStep, any>({
        path: `/api/v1/transports/validate-third-step/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags users
     * @name UsersList
     * @request GET:/api/v1/users/
     * @secure
     * @response `200` `PaginatedUserList`
     */
    usersList: (query: UsersListParams, params: RequestParams = {}) =>
      this.request<PaginatedUserList, any>({
        path: `/api/v1/users/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersRetrieve
     * @request GET:/api/v1/users/{id}/
     * @secure
     * @response `200` `User`
     */
    usersRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/users/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersUpdate
     * @request PUT:/api/v1/users/{id}/
     * @secure
     * @response `200` `User`
     */
    usersUpdate: (id: number, data: User, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/users/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersPartialUpdate
     * @request PATCH:/api/v1/users/{id}/
     * @secure
     * @response `200` `User`
     */
    usersPartialUpdate: (id: number, data: PatchedUser, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/users/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersDestroy
     * @request DELETE:/api/v1/users/{id}/
     * @secure
     * @response `204` `void` No response body
     */
    usersDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersPermissionsRetrieve
     * @request GET:/api/v1/users/{id}/permissions/
     * @secure
     * @response `200` `UserPermissions`
     */
    usersPermissionsRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<UserPermissions, any>({
        path: `/api/v1/users/${id}/permissions/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersAllList
     * @request GET:/api/v1/users/all/
     * @secure
     * @response `200` `(UserGeneralInfo)[]`
     */
    usersAllList: (query: UsersAllListParams, params: RequestParams = {}) =>
      this.request<UserGeneralInfo[], any>({
        path: `/api/v1/users/all/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  waypoints = {
    /**
     * No description
     *
     * @tags waypoints
     * @name WaypointsAutocompleteCreate
     * @request POST:/api/v1/waypoints/autocomplete/
     * @secure
     * @response `200` `AutocompleteResponse`
     */
    waypointsAutocompleteCreate: (data: WaypointAutocompleteRequest, params: RequestParams = {}) =>
      this.request<AutocompleteResponse, any>({
        path: `/api/v1/waypoints/autocomplete/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  webhooks = {
    /**
     * @description Handle POST request from Brevo webhook
     *
     * @tags webhooks
     * @name WebhooksBrevoCreate
     * @request POST:/api/v1/webhooks/brevo/
     * @secure
     * @response `200` `void` No response body
     */
    webhooksBrevoCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/webhooks/brevo/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Handle POST request from Mailgun webhook
     *
     * @tags webhooks
     * @name WebhooksMailgunCreate
     * @request POST:/api/v1/webhooks/mailgun/
     * @secure
     * @response `200` `void` No response body
     */
    webhooksMailgunCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/webhooks/mailgun/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhooks
     * @name WebhooksStripeCreate
     * @request POST:/api/v1/webhooks/stripe/
     * @secure
     * @response `200` `void` No response body
     */
    webhooksStripeCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/webhooks/stripe/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  workspace = {
    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceCreate
     * @request POST:/api/v1/workspace/
     * @secure
     * @response `201` `Workspace`
     */
    workspaceCreate: (data: Workspace, params: RequestParams = {}) =>
      this.request<Workspace, any>({
        path: `/api/v1/workspace/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceRetrieve
     * @request GET:/api/v1/workspace/{id}/
     * @secure
     * @response `200` `Workspace`
     */
    workspaceRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<Workspace, any>({
        path: `/api/v1/workspace/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceUpdate
     * @request PUT:/api/v1/workspace/{id}/
     * @secure
     * @response `200` `Workspace`
     */
    workspaceUpdate: (id: number, data: WorkspaceUpdate, params: RequestParams = {}) =>
      this.request<Workspace, any>({
        path: `/api/v1/workspace/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspacePartialUpdate
     * @request PATCH:/api/v1/workspace/{id}/
     * @secure
     * @response `200` `Workspace`
     */
    workspacePartialUpdate: (id: number, data: PatchedWorkspaceUpdate, params: RequestParams = {}) =>
      this.request<Workspace, any>({
        path: `/api/v1/workspace/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceAssignTrialPackageCreate
     * @request POST:/api/v1/workspace/{id}/assign-trial-package/
     * @secure
     * @response `200` `Workspace`
     */
    workspaceAssignTrialPackageCreate: (id: number, params: RequestParams = {}) =>
      this.request<Workspace, any>({
        path: `/api/v1/workspace/${id}/assign-trial-package/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
