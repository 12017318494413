import { acceptHMRUpdate, defineStore } from 'pinia'
import api from '@api'
import type {
  LanguageEnum,
  PasswordChange,
  PasswordReset,
  PasswordResetConfirm, ResendEmailVerification,
  TokenVerify,
  UserDetails,
  VerifyEmail,
  Workspace,
  PatchedWorkspaceUpdate,
} from '@apiTypes'
import { mapWorkspaceOnMembers } from '@/utils/user.utils'
import { supportedLanguages } from '@/plugins/i18n'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isNewRegistration: false,
    isNewCompany: false,
    currentUser: null as UserDetails | null,
    currentWorkspace: null as Workspace | null,
  }),
  getters: {
    getCurrentUserFullName: state => {
      return [state.currentUser?.firstName, state.currentUser?.lastName].filter(v => !!v).join(' ')
    },
    getCurrentLanguage: state => {
      return state.currentUser?.preferredLanguage || supportedLanguages[0]
    },
    getDisplayName: state => {
      return [state.currentUser?.firstName, state.currentUser?.lastName].filter(v => !!v).join(' ') || state.currentUser?.email
    },
    getDisplayShortName: state => {
      return state.currentUser?.firstName || state.currentUser?.lastName || state.currentUser?.email
    },
    getWorkspaceDisplayName: state => {
      return state.currentWorkspace?.name || ''
    },
    getWorkspaceCurrency: state => {
      return state.currentWorkspace?.currency || ''
    },
  },
  actions: {
    clear() {
      this.currentUser = null
      this.currentWorkspace = null
    },
    setIsNewRegistration(isNewCompany = true) {
      this.isNewRegistration = true
      this.isNewCompany = isNewCompany
    },
    clearIsNewRegistration() {
      this.isNewRegistration = false
      this.isNewCompany = false
    },
    setCurrentUser(user: UserDetails, updateWorkspace: boolean = true) {
      this.currentUser = user
      if (updateWorkspace)
        this.currentWorkspace = user.workspace
    },
    setCurrentWorkspace(workspace: Workspace) {
      this.currentUser = this.currentUser && {
        ...this.currentUser,
        members: mapWorkspaceOnMembers(workspace, this.currentUser.members),
      } // we are mapping current workspace member to prevent unnecessary request on the server
      this.currentWorkspace = workspace
    },
    setPassword(data: PasswordChange) {
      return api.auth.authPasswordChangeCreate(data)
    },
    resetPassword(data: PasswordReset) {
      return api.auth.authPasswordResetCreate(data, { skipAuthToken: true })
    },
    verifyToken(data: TokenVerify) {
      return api.auth.authTokenVerifyCreate(data, { skipAuthToken: true, skipAuthRefresh: true, skipLogout: true })
    },
    confirmResetPassword(data: PasswordResetConfirm) {
      return api.auth.authPasswordResetConfirmCreate(data, { skipAuthToken: true })
    },
    confirmEmail(data: VerifyEmail) {
      return api.auth.authRegisterVerifyEmailCreate(data, { skipAuthToken: true })
    },
    resendConfirmationEmail(data: ResendEmailVerification) {
      return api.auth.authRegisterResendEmailCreate(data)
    },
    fetchCurrentUser(store: boolean = true) {
      return api.auth.authUserRetrieve({ skipLogout: true, skipAuthRefresh: true }).then(response => {
        if (store)
          this.setCurrentUser(response.data)

        return response
      })
    },
    updateWorkspace(data: PatchedWorkspaceUpdate) {
      return api.workspace.workspacePartialUpdate(this.currentWorkspace?.id || 0, data).then(response => {
        this.setCurrentWorkspace(response.data)

        return response
      })
    },
    assignTrialPackage() {
      return api.workspace.workspaceAssignTrialPackageCreate(this.currentWorkspace?.id || 0).then(response => {
        this.setCurrentWorkspace(response.data)

        return response
      })
    },
    createWorkspace(data: Workspace) {
      return api.workspace.workspaceCreate(data)
    },
    updateUser(data: UserDetails) {
      return api.auth.authUserUpdate(data).then(response => {
        this.setCurrentUser(response.data)

        return response
      })
    },
    updateLanguage(preferredLanguage: LanguageEnum) {
      return api.auth.authUserPartialUpdate({ preferredLanguage }).then(response => {
        this.setCurrentUser(response.data)

        return response
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
